// libraries
import React, { useCallback, useRef, useState } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedRef,
  useAnimatedStyle,
  withTiming,
} from 'react-native-reanimated';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

// components
import {
  CustomIcon,
  CustomText,
  DivColumn,
  DivRow,
  SpacerColumn,
  SpacerRow,
  Badge,
} from '@components/atoms';

// misc
import { genericStyles } from '@styles/genericStyles';
import ConsentNotReceivedIcon from '@assets/icons/consent-not-received.svg';
import ConsentReceivedIcon from '@assets/icons/consent-received.svg';
import ConsentWithdrawnIcon from '@assets/icons/consent-withdrawn.svg';
import DontHasBikeIcon from '@assets/icons/cycle-cross.svg';
import MedicalIssuesIcon from '@assets/icons/medical-issues.svg';
import NoPhotographyIcon from '@assets/icons/no-photography.svg';
import NoteFromTeacherIcon from '@assets/icons/note-from-teacher.svg';
import NotPresentIcon from '@assets/icons/not-present.svg';
import SendNotesIcon from '@assets/icons/send-notes.svg';
import { DeliveryDatumRider } from '@screens/dashboard/types';
import { ColorPalette } from '@styles/types';
import { useAppSelector } from '@redux/store';
import { selectInstructorNotesById } from '@screens/dashboard/selectors';
import { useGetDeliveryCourseAndRiders } from '@hooks/useGetDeliveryCourseAndRiders';
import { DashboardStackParamList } from '@utils/navigation';

// types
export interface RiderTrainingItemProps extends DeliveryDatumRider {
  onPressTraining?(): void;
  onPressInstructorFeedback?(): void;
  onPressBikeAndKitCheck?(): void;
  onPressRidersDetails?(): void;
  onPressRiderWithdrawn?(): void;
  courseShortName: string;
}

const ICON_SIZE = 30;

type ChildButtonProps = {
  title: string;
  color: ColorPalette;
  onPress?(): void;
  badgeCount?: number;
};

export const RiderTrainingItem: React.FC<RiderTrainingItemProps> = ({
  id,
  name,
  yearGroup,
  consentStatus,
  hasBike,
  sendNotes,
  medicalNotes,
  photographyAllowed,
  presenceStatus,
  instructorNotes,
  onPressTraining,
  onPressInstructorFeedback,
  courseShortName,
  onPressBikeAndKitCheck,
  onPressRidersDetails,
  onPressRiderWithdrawn,
}) => {
  // variables
  const { delivery, course } = useGetDeliveryCourseAndRiders();
  const riderInstructorNotes = useAppSelector(state =>
    selectInstructorNotesById(state, delivery.id, id),
  );
  const { navigate } = useNavigation<NativeStackNavigationProp<DashboardStackParamList>>();

  const [isExpanded, setIsExpanded] = useState(false);
  const aref = useAnimatedRef<View>();
  const heightRef = useRef<number>(0);
  const style = useAnimatedStyle(
    () => ({
      height: isExpanded ? withTiming(heightRef.current) : 0,
      opacity: isExpanded ? withTiming(1) : withTiming(0),
    }),
    [isExpanded],
  );

  const rotateStyle = useAnimatedStyle(() => {
    const rotate = interpolate(isExpanded ? 1 : 0, [0, 1], [0, 180], Extrapolate.CLAMP);

    return {
      transform: [{ rotate: `${rotate}deg` }],
    };
  }, [isExpanded]);

  // functions
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  // renders
  const ConsentIcon = useCallback(() => {
    switch (consentStatus) {
      case 0:
        return <ConsentWithdrawnIcon width={ICON_SIZE} />;

      case 1:
        return <ConsentReceivedIcon width={ICON_SIZE} />;

      default:
        return <ConsentNotReceivedIcon width={ICON_SIZE} />;
    }
  }, []);

  const ChildButton = useCallback(({ title, color, onPress, badgeCount }: ChildButtonProps) => {
    return (
      <ColorButton color={color} onPress={onPress}>
        <CustomText font="bodyBold" size={16}>
          {title}
        </CustomText>

        <CustomIcon name="chevron-right" />
        {!!badgeCount && <Badge count={badgeCount} />}
      </ColorButton>
    );
  }, []);

  return (
    <View style={[genericStyles.w100, genericStyles.fill]}>
      <View style={[genericStyles.fill, genericStyles.row]}>
        <DetailContainer onPress={toggleExpansion}>
          <View style={[genericStyles.rowWithCenter]}>
            <NameContainer>
              <CustomText font="bodyBold" size={16}>
                {name}({yearGroup})
              </CustomText>
            </NameContainer>
          </View>

          <DivColumn>
            <DivRow>
              {sendNotes && (
                <IconContainer>
                  <SendNotesIcon width={10} />
                </IconContainer>
              )}
              {medicalNotes && (
                <IconContainer>
                  <MedicalIssuesIcon width={14} />
                </IconContainer>
              )}
              {!photographyAllowed && (
                <IconContainer>
                  <NoPhotographyIcon width={14} />
                </IconContainer>
              )}
              {!presenceStatus && consentStatus === 1 && (
                <IconContainer>
                  <NotPresentIcon width={14} />
                </IconContainer>
              )}
              {instructorNotes?.length && (
                <IconContainer>
                  <NoteFromTeacherIcon width={14} />
                </IconContainer>
              )}
              {hasBike === 0 && (
                <IconContainer>
                  <DontHasBikeIcon width={14} />
                </IconContainer>
              )}
            </DivRow>

            <Animated.View style={[rotateStyle, genericStyles.selfEnd]}>
              <CustomIcon name="chevron-down" />
            </Animated.View>
          </DivColumn>
        </DetailContainer>

        <SpacerRow size={1} />

        <View>
          <ConsentIcon />
        </View>
      </View>

      <Animated.View style={[genericStyles.w100, style]}>
        <View
          ref={aref}
          onLayout={({
            nativeEvent: {
              layout: { height: h },
            },
          }) => (heightRef.current = h)}
          style={genericStyles.w100}>
          <SpacerColumn size={1} />
          <DivColumn>
            <ChildButton
              title="Cycle & Kit Check"
              color="mercury"
              onPress={onPressBikeAndKitCheck}
            />
            <ChildButton
              title={`${courseShortName} Training`}
              color="tequila"
              onPress={onPressTraining}
            />
            <ChildButton
              title={`${courseShortName} Instructor Feedback`}
              color="mercury"
              onPress={onPressInstructorFeedback}
            />
            <ChildButton
              title="Instructor Notes"
              color="blueRomance"
              badgeCount={riderInstructorNotes?.length || 0}
              onPress={() =>
                navigate('riderInstructorNotes', {
                  deliveryId: delivery.id,
                  courseId: course.id,
                  riderId: id,
                })
              }
            />
            <ChildButton
              title="View Rider Details"
              color="blueRomance"
              onPress={onPressRidersDetails}
            />

            <ChildButton
              title="Rider withdrawn"
              color="blueRomance"
              onPress={onPressRiderWithdrawn}
            />
          </DivColumn>
        </View>
      </Animated.View>
    </View>
  );
};

const DetailContainer = styled.Pressable(({ theme: { colors } }) => ({
  ...genericStyles.rowWithSB,
  flex: 1,
  backgroundColor: colors.cornflowerBlue,
}));

const ColorButton = styled.Pressable<{ color: ColorPalette }>(
  ({ theme: { colors, layout }, color }) => ({
    ...genericStyles.rowWithCenterAndSB,
    flex: 1,
    backgroundColor: colors[color],
    paddingVertical: layout.padding_x2,
    paddingHorizontal: layout.padding_x1,
    marginBottom: layout.padding_x1,
  }),
);

const NameContainer = styled.View(({ theme: { layout } }) => ({
  paddingVertical: layout.padding_x1,
  paddingHorizontal: layout.padding_x2,
}));

const IconContainer = styled.View(({ theme: { layout } }) => ({
  marginRight: layout.padding_x0_5,
}));
