// libraries
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/native';
import { FlatList } from 'react-native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useNetInfo } from '@react-native-community/netinfo';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { SvgProps } from 'react-native-svg';

// components
import {
  CustomIcon,
  CustomText,
  DivRow,
  Heading,
  SpacerColumn,
  SpacerRow,
} from '@components/atoms';
import { MenuItem } from './components';
import { FullScreenLoader } from '@components/molecules';

// misc
import EventDetailsIcon from '@assets/icons/event-details.svg';
import AllRidersIcon from '@assets/icons/cycle.svg';
import DeliveryCompleteIcon from '@assets/icons/delivery-complete.svg';
import RiderIssuesIcon from '@assets/icons/rider-Issues.svg';
import { genericStyles } from '@styles/genericStyles';
import { layout } from '@styles/layout';
import { DashboardStackParamList, TabNavigatorParamList } from '@utils/navigation';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectDeliveryById, selectInstructorNotesByRiders } from './selectors';
import { deliveriesSlice } from './slice';
import { useSubmitSavedDelivery } from '@hooks/useSubmitSavedDelivery';
import InstructorNotesIcon from '@assets/icons/instructor-notes.svg';
import FIXIcon from '@assets/icons/Fix.svg';
import BalanceIcon from '@assets/icons/Balance.svg';
import L1Icon from '@assets/icons/L1.svg';
import L2Icon from '@assets/icons/L2.svg';
import L3Icon from '@assets/icons/L3.svg';
import LTRIcon from '@assets/icons/LTR.svg';
import { CourseLabelShortNameType } from './types';

const LEVEL_ICONS: { [courseName in CourseLabelShortNameType]: React.FC<SvgProps> } = {
  Fix: FIXIcon,
  L1: L1Icon,
  L2: L2Icon,
  L3: L3Icon,
  Balance: BalanceIcon,
  LTR: LTRIcon,
};

export const DeliverySummaryScreen = () => {
  // variables
  const { navigate, goBack } = useNavigation<NativeStackNavigationProp<TabNavigatorParamList>>();
  const {
    params: { deliveryId: id },
  } = useRoute<RouteProp<DashboardStackParamList, 'deliverySummary'>>();
  const { isConnected } = useNetInfo();
  const [startDeliverySubmit, setStartDeliverySubmit] = useState(false);

  const delivery = useAppSelector(state => selectDeliveryById(state, id));

  const dispatch = useAppDispatch();
  const { submit } = useSubmitSavedDelivery('Delivery completed succesfully!', {
    onSuccess: () => {
      goBack();
      setStartDeliverySubmit(false);
    },
  });
  const instructorNotes = useAppSelector(state =>
    selectInstructorNotesByRiders(state, delivery.id),
  );

  const menuData = useMemo(() => {
    const details = [
      {
        Icon: EventDetailsIcon,
        text: 'Delivery Details',
        onPress: () => navigate('home', { screen: 'deliveryDetail', params: { id } }),
      },
      {
        Icon: AllRidersIcon,
        text: 'All Riders',
        onPress: () => navigate('home', { screen: 'allRiders', params: { deliveryId: id } }),
      },
      {
        Icon: RiderIssuesIcon,
        text: 'Rider Summary Report',
        onPress: () => navigate('home', { screen: 'riderIssues', params: { deliveryId: id } }),
      },
      {
        Icon: InstructorNotesIcon,
        text: 'Instructor Notes',
        badgeCount:
          Object.values(instructorNotes)?.reduce((prev, cur) => [...prev, ...cur.notes], [])
            ?.length || 0,
        onPress: () =>
          navigate('home', {
            screen: 'instructorNotes',
            params: { deliveryId: id },
          }),
      },
      // {
      //   Icon: DeliveryCompleteIcon,
      //   text: 'Delivery Complete',
      //   onPress: () => {
      //     dispatch(deliveriesSlice.actions.setDeliveryCompleteFlag({ deliveryId: id }));

      //     setTimeout(() => {
      //       setStartDeliverySubmit(true);
      //     }, 500);
      //   },
      // },
    ];

    delivery.courses.forEach(c => {
      details.push({
        Icon: LEVEL_ICONS[c.courseLabelShortName] || L1Icon,
        text: `${c.courseLabelShortName} (${c.yearGroup}) Training`,
        onPress: () =>
          navigate('home', { screen: 'courseInfo', params: { deliveryId: id, courseId: c.id } }),
      });
      // details.splice(2, 0, {
      //   Icon: LEVEL_ICONS[c.courseLabelShortName] || L1Icon,
      //   text: `${c.courseLabelShortName} (${c.yearGroup}) Training`,
      //   onPress: () =>
      //     navigate('home', { screen: 'courseInfo', params: { deliveryId: id, courseId: c.id } }),
      // });
    });

    if (delivery.displayCharacteristic) {
      details.splice(4, 0, {
        Icon: AllRidersIcon,
        text: 'Rider Characteristics',
        onPress: () => navigate('home', { screen: 'characteristics', params: { deliveryId: id } }),
      });
    }

    return details;
  }, [delivery, isConnected]);

  // hooks
  useEffect(() => {
    if (startDeliverySubmit) {
      submit();
    }
  }, [startDeliverySubmit]);

  // functions

  // returns
  if (!delivery) {
    return <FullScreenLoader />;
  }

  return (
    <Container>
      <Heading showSchoolIcon title={delivery.hostName} status={delivery.status} />

      <FlatList
        data={menuData}
        keyExtractor={item => item.text}
        renderItem={({ item }) => <MenuItem {...item} />}
        numColumns={2}
        ListHeaderComponent={() => (
          <Content>
            <DivRow style={genericStyles.w100}>
              <DivRow style={genericStyles.fill}>
                <CustomIcon name="groups" type="material" size={30} color="text" />
                <SpacerRow size={0.5} />
                <CustomText font="bodyBold" size={20}>
                  {delivery.riderAllocation}
                </CustomText>
              </DivRow>

              <DivRow style={genericStyles.fill}>
                <CustomIcon name="bicycle-outline" type="ionic" size={30} color="text" />
                <SpacerRow size={0.5} />
                <CustomText font="bodyBold" size={16}>
                  {delivery.courses.reduce(
                    (prev, cur, index) =>
                      index === delivery.courses.length - 1
                        ? cur.courseLabelShortName + prev
                        : prev + ', ' + cur.courseLabelShortName,
                    '',
                  )}
                </CustomText>
              </DivRow>
            </DivRow>

            <SpacerColumn size={2} />

            <DivRow>
              <CustomIcon name="school" type="material" color="text" size={30} />
              <SpacerRow size={0.5} />
              <CustomText size={14}>
                {delivery.instructors.reduce(
                  (prev, cur, index) =>
                    index === delivery.instructors.length - 1
                      ? cur.instructorName + prev
                      : prev + ', ' + cur.instructorName,
                  '',
                )}
              </CustomText>
            </DivRow>
          </Content>
        )}
        ItemSeparatorComponent={() => <SpacerColumn size={2} />}
        ListFooterComponent={() => <SpacerColumn size={3} />}
        contentContainerStyle={{ justifyContent: 'space-between', padding: layout.padding_x2 }}
        columnWrapperStyle={{ justifyContent: 'space-between' }}
      />
    </Container>
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));

const Content = styled.ScrollView(() => ({
  paddingBottom: layout.padding_x2,
}));
