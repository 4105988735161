// libraries
import React from 'react';
import styled from 'styled-components/native';
import { Control, FieldValues, Path, RegisterOptions, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextStyle, ViewStyle } from 'react-native';

// components
import { CustomIcon, CustomText, ErrorText, SpacerRow } from '@components/atoms';

// styles
import { genericStyles } from '@styles/genericStyles';

//types
interface CheckboxProps<T extends FieldValues> {
  text: string | React.ReactNode;
  control: Control<T>;
  name: Path<T>;
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  defaultValue: boolean;
  value?: boolean;
  containerStyle?: ViewStyle;
  errorStyle?: TextStyle;
  onChange?: () => void;
}

export const Checkbox = <T extends FieldValues>({
  text,
  name,
  control,
  value,
  rules,
  containerStyle,
  errorStyle,
  onChange,
}: CheckboxProps<T>): React.ReactElement => {
  // variables
  const { t } = useTranslation();
  const { field, fieldState } = useController<T>({
    name,
    control,
    rules,
  });

  // renders
  return (
    <Container>
      <ButtonContainer
        onPress={() => {
          if (field.value === undefined && value !== undefined) {
            field.onChange(!value);
          } else {
            field.onChange(!field.value);
          }
          onChange && onChange();
        }}
        style={containerStyle}>
        {typeof text === 'function' ? (
          text
        ) : (
          <CustomText size={14} color={'text'}>
            {text}
          </CustomText>
        )}
        <SpacerRow size={0.4} />
        {field.value === undefined && value !== undefined ? (
          <CustomIcon
            name={value === true ? 'check-square' : 'square'}
            color={fieldState.error?.type ? 'error' : 'text'}
          />
        ) : (
          <CustomIcon
            name={field.value === true ? 'check-square' : 'square'}
            color={fieldState.error?.type ? 'error' : 'text'}
          />
        )}
      </ButtonContainer>
      <ErrorText style={errorStyle}>
        {fieldState.error?.type && (fieldState.error?.message || t('form.errors.required') || '')}
      </ErrorText>
    </Container>
  );
};

const Container = styled.View(() => ({}));

const ButtonContainer = styled.Pressable(({ theme: { layout } }) => ({
  ...genericStyles.rowWithCenterAndSB,
  paddingVertical: layout.padding * 0.2,
}));
