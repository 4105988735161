// libraries
import React, { useEffect, useRef, useState } from 'react';
import PinView, { PinViewProps } from 'react-native-pin-view';
import { useTheme } from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import { showMessage } from 'react-native-flash-message';

// components
import { CustomIcon } from '@components/atoms';

// misc
import { fonts } from '@styles/fonts';
import { getSecretPin, setSecretPin } from '@utils/secure-storage';
import { useUser } from '@context/UserProvider';
import cache from '@utils/cache';
import { APP_AUTH_TOKEN, APP_USER, DELIVERY_SAVE_KEY } from '@utils/keys';

enum PinMode {
  OLD_PIN,
  NEW_PIN,
}

// todo: fix ts error for this file
export const ChangePinScreen = () => {
  // variables
  const pinView = useRef<PinViewProps>(null);
  const { colors, layout } = useTheme();
  const [pin, setPin] = useState('');
  const [mode, setMode] = useState<PinMode>(PinMode.OLD_PIN);
  const { setOptions } = useNavigation();
  const { goBack } = useNavigation();
  const { token, user } = useUser();

  // hooks
  useEffect(() => {
    if (pin.length === 4) updatePin();
  }, [pin]);

  useEffect(() => {
    setOptions({ title: mode === PinMode.OLD_PIN ? 'Current Pin' : 'Set New Pin' });
  }, [mode]);

  // functions
  const updatePin = async () => {
    if (mode === PinMode.OLD_PIN) {
      const currentPin = getSecretPin();

      if (currentPin === pin) {
        setMode(PinMode.NEW_PIN);
        pinView.current?.clearAll();
      } else {
        showMessage({
          message: 'Pin is incorrent. Please your current pin.',
          type: 'danger',
        });
        pinView.current?.clearAll();
      }
    } else {
      const oldData = await cache.get(DELIVERY_SAVE_KEY);
      await cache.remove(DELIVERY_SAVE_KEY);
      await cache.remove(APP_USER);
      await cache.remove(APP_AUTH_TOKEN);

      setSecretPin(pin);

      setTimeout(async () => {
        await cache.store(APP_AUTH_TOKEN, token);
        await cache.store(APP_USER, user);
        await cache.store(DELIVERY_SAVE_KEY, oldData);

        showMessage({
          message: 'Your pin is updated succesfully!',
          type: 'success',
        });
        goBack();
      }, 500);
    }
  };

  return (
    <PinView
      ref={pinView}
      pinLength={4}
      style={{
        backgroundColor: colors.primaryBackground,
        flex: 1,
      }}
      onValueChange={setPin}
      inputAreaStyle={{
        marginVertical: 40,
      }}
      inputViewEmptyStyle={{
        backgroundColor: colors.transparent,
        borderColor: colors.text,
        borderWidth: 1,
      }}
      inputViewFilledStyle={{
        backgroundColor: colors.text,
      }}
      inputSize={30}
      buttonViewStyle={{
        backgroundColor: colors.transparent,
        borderColor: colors.text,
        borderWidth: 1,
        marginBottom: layout.padding_x2,
      }}
      buttonTextStyle={{
        borderColor: colors.text,
        fontFamily: fonts.family.bodyLight,
      }}
      onButtonPress={key => {
        key === 'custom_left' && pinView.current?.clear();
      }}
      customLeftButton={<CustomIcon color="text" name="delete" size={40} />}
    />
  );
};
