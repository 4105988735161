// libraries
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/native';
import moment from 'moment';

// components
import { CustomModal, SelectInput } from '@components/molecules';
import { CustomText, DivColumn, Input, SmallButton, SpacerColumn } from '@components/atoms';

// misc
import { genericStyles } from '@styles/genericStyles';
import { useGetDeliveryCourseAndRiders } from '@hooks/useGetDeliveryCourseAndRiders';
import { useAppDispatch, useAppSelector } from '@redux/store';
import {
  selectGeneralInstructorNotes,
  selectRiderInstructorNotes,
  selectSavedDeliveryById,
} from '@screens/dashboard/selectors';
import { useUser } from '@context/UserProvider';
import { deliveriesSlice } from '@screens/dashboard/slice';
import { InstructorNote } from '@api/types';
import { useSubmitSavedDelivery } from '@hooks/useSubmitSavedDelivery';
import alert from '@utils/alert';
import { StateGeneralInstructorNote } from '@screens/dashboard/types';

// types
export type AddInstructorsNotesModalProps = {
  isVisible: boolean;
  onClose?(): void;
  selectedRiderId?: number;
};

type FormType = { riderId: string; notes: string };

export const AddInstructorsNotesModal: React.FC<AddInstructorsNotesModalProps> = ({
  isVisible,
  selectedRiderId,
  onClose,
}) => {
  // variables
  const { user } = useUser();
  const [startDeliverySubmit, setStartDeliverySubmit] = useState(false);
  const { handleSubmit, control, setValue, reset, watch, getValues } = useForm<FormType>({
    defaultValues: { riderId: selectedRiderId?.toString() },
  });
  const { riders, delivery } = useGetDeliveryCourseAndRiders();
  const { submit, isLoading } = useSubmitSavedDelivery('Instruction added successfully!', {
    onSuccess: () => {
      setStartDeliverySubmit(false);
      onClose();
    },
  });
  const riderId = parseInt(watch('riderId'));

  const dispatch = useAppDispatch();
  const savedDelivery = useAppSelector(state => selectSavedDeliveryById(state, delivery.id));
  const savedRiderNotes = useAppSelector(state =>
    selectRiderInstructorNotes(state, delivery.id, riderId),
  );
  const instructorGeneralNotes = useAppSelector(state =>
    selectGeneralInstructorNotes(state, delivery.id),
  );

  let savedGenralInstructorNote: StateGeneralInstructorNote;
  if (instructorGeneralNotes) {
    savedGenralInstructorNote = instructorGeneralNotes[user.id];
  }

  const modifyRiders = useMemo(() => {
    const allRiders = [{ label: 'General', value: 'general' }];
    const rider = riders
      .filter(rider => (selectedRiderId ? rider.id === selectedRiderId : true))
      .map(r => ({ label: r.name, value: r.id.toString() }));
    return allRiders.concat(rider);
  }, [riders]);

  // hooks
  useEffect(() => {
    reset();
  }, [isVisible]);

  useEffect(() => {
    setValue('notes', '');
  }, [riderId]);

  useEffect(() => {
    if (startDeliverySubmit) {
      submit();
    }
  }, [startDeliverySubmit]);

  // functions
  const onSubmit = (data: FormType) => {
    let updatedNotes: InstructorNote[] = [];
    if (getValues('riderId') === 'general') {
      if (savedGenralInstructorNote) {
        updatedNotes = [
          {
            ...savedGenralInstructorNote,
            instructor_id: user?.id,
            notes: [
              ...savedGenralInstructorNote.notes,
              { note_text: data.notes, datetime: moment().toISOString() },
            ],
          },
        ];
      } else {
        updatedNotes = [
          {
            instructor_id: user?.id,
            notes: [{ note_text: data.notes, datetime: moment().toISOString() }],
          },
        ];
      }
      dispatch(
        deliveriesSlice.actions.setGeneralInstructorNotes({
          generalInstructorNotes: updatedNotes,
          deliveryId: delivery.id,
        }),
      );
      // setGeneralInstructorNotes
    } else {
      if (savedDelivery && savedRiderNotes) {
        updatedNotes = [
          {
            ...savedRiderNotes,
            notes: [
              ...savedRiderNotes.notes,
              { note_text: data.notes, datetime: moment().toISOString() },
            ],
          },
        ];
      } else {
        updatedNotes = [
          {
            instructor_id: user?.id,
            notes: [{ note_text: data.notes, datetime: moment().toISOString() }],
          },
        ];
      }

      dispatch(
        deliveriesSlice.actions.setSaveDeliveryRider({
          instructorNotes: updatedNotes,
          deliveryId: delivery.id,
          riderId: parseInt(data.riderId),
        }),
      );
    }

    setTimeout(() => {
      setStartDeliverySubmit(true);
    }, 500);
  };

  // Handle Close button of popup
  const handleClose = () => {
    if (getValues('notes') !== '' || getValues('riderId') !== undefined) {
      alert('Instructor Notes', 'You have unsaved data, would you like to save it now?', [
        {
          text: 'Ok',
          onPress: () => {
            handleSubmit(onSubmit)();
          },
        },
        {
          text: 'Cancel',
          onPress: () => onClose(),
          style: 'cancel',
        },
      ]);
    } else {
      onClose();
    }
  };

  // renders
  return (
    <CustomModal isVisible={isVisible} title="Instructor Notes" onClose={handleClose}>
      <Content>
        <CustomText textAlign="center" font="bodyBold">
          This section is for instructors to make notes whilst they are training. The notes are not
          retained after the training and are not sent to parents/schools.
        </CustomText>
        <SpacerColumn size={2} />

        <DivColumn style={{ width: '100%', height: 80 }}>
          <SelectInput
            name="riderId"
            control={control}
            data={modifyRiders}
            onChangeValue={setValue}
            title=""
            placeHolder="Choose Rider"
            sheetTitle="Select rider"
            rules={{ required: true }}
          />
        </DivColumn>
        <SpacerColumn size={2} />

        <Input
          title="Notes"
          fontSize={14}
          name="notes"
          control={control}
          multiline
          numberOfLines={30}
          containerStyle={{ flex: 1 }}
          rules={{ required: true }}
        />
        <SpacerColumn size={3} />
        <SmallButton title="Save" onPress={handleSubmit(onSubmit)} isLoading={isLoading} />
        <SpacerColumn size={1} />
      </Content>
    </CustomModal>
  );
};

const Content = styled.View(({ theme: { layout } }) => ({
  ...genericStyles.fill,
  padding: layout.padding_x2,
}));
