// libraries
import { useMutation } from 'react-query';

// misc
import { useErrorHandler } from '@hooks/useErrorHandler';
import request from '@utils/request';
import { ServerResponse } from './types';

export interface LoginRequest {
  email: string;
}

export type SaveDeliveryResponse = ServerResponse<null>;

export const useResetPassword = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: SaveDeliveryResponse) => void;
  onError?: (res: ServerResponse<unknown>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();

  // request
  const query = useMutation<
    SaveDeliveryResponse,
    ServerResponse<unknown> | undefined,
    LoginRequest
  >(['reset-password'], async ({ email }: LoginRequest) => {
    try {
      const resetPassword = await request<SaveDeliveryResponse>({
        method: 'post',
        url: `/reset/password`,
        data: { email },
      });

      onSuccess && onSuccess(resetPassword);
      return resetPassword;
    } catch (error) {
      onError && onError(error);
      triggerError({ error });
    }
  });

  // return
  return query;
};
