// libraries
import React, { ReactElement, useEffect } from 'react';
import { useTheme } from 'styled-components/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

// components
import { SignInScreen, AuthoriseScreen, ForgotPasswordScreen } from '@screens/authentication';
import { ATELogo } from '@components/atoms/ATELogo';
import { Logo } from '@components/atoms';

// types
import { AuthStackParamList } from '@utils/navigation';
import { fonts } from '@styles/fonts';
import { useUser } from '@context/UserProvider';
import { AuthPinScreen, AuthPinMode } from '@screens/authentication/AuthPinScreen';

//Misc
import { PILOT_MODE } from '@env';

const Stack = createStackNavigator<AuthStackParamList>();

const AuthStacks = (): ReactElement => {
  // variables
  const { colors, layout } = useTheme();
  const { initLoading } = useUser();
  const { isUserLoggedIn: isUserLoggedInWhenStart } = useUser();
  const { navigate } = useNavigation<NativeStackNavigationProp<AuthStackParamList>>();

  const isPilotMode = PILOT_MODE === 'true';
  // hooks
  useEffect(() => {
    setTimeout(() => {
      if (isUserLoggedInWhenStart) {
        navigate('authPin', { type: AuthPinMode.ENTER });
      }
    }, 9);
    // if (isUserLoggedInWhenStart) {
    //   navigate('authPin', { type: AuthPinMode.ENTER });
    // }
  }, [isUserLoggedInWhenStart]);

  // returns
  if (initLoading) {
    return null;
  }

  return (
    <Stack.Navigator
      screenOptions={{
        headerShadowVisible: false,
        headerStyle: {
          backgroundColor: isPilotMode ? colors.error : colors.secondary,
          minHeight: 100,
        },
        headerLeftContainerStyle: {
          paddingLeft: layout.padding_x1_5,
        },
        headerRightContainerStyle: {
          paddingRight: layout.padding_x1_5,
        },
        headerTitleStyle: {
          fontFamily: fonts.family.bodyBold,
          fontSize: 20,
        },
        headerTitleAlign: 'center',
      }}>
      <Stack.Screen
        name="signIn"
        options={{
          title: isPilotMode ? 'Training' : 'Sign In',
          headerLeft: () => <Logo />,
          headerRight: () => <ATELogo />,
        }}
        component={SignInScreen}
      />

      <Stack.Screen
        name="authorise"
        options={{
          title: isPilotMode ? 'Verify Code - Training' : 'Verify Code',
          headerLeft: () => <Logo />,
          headerRight: () => <ATELogo />,
        }}
        component={AuthoriseScreen}
      />

      <Stack.Screen
        name="authPin"
        options={{
          title: isPilotMode ? 'Enter Pin - Training' : 'Enter Pin',
          headerLeft: () => <Logo />,
          headerRight: () => <ATELogo />,
        }}
        component={AuthPinScreen}
      />

      <Stack.Screen
        name="forgotPassword"
        options={{
          title: isPilotMode ? 'Forgot Password - Training' : 'Forgot Password',
          headerLeft: () => <Logo />,
          headerRight: () => <ATELogo />,
        }}
        component={ForgotPasswordScreen}
      />
    </Stack.Navigator>
  );
};

export default AuthStacks;
