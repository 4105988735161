// libraries
import React from 'react';
import styled from 'styled-components/native';

// misc
import { AVATAR_WIDTH } from '@utils/image';

// types
export interface AvatarProps {
  uri: string;
  size?: 'x-small' | 'small' | 'regular' | 'big' | 'extra-large';
}

export const Avatar = ({ uri, size = 'regular' }: AvatarProps) => {
  // functions
  const getSize = () => {
    switch (size) {
      case 'x-small':
        return AVATAR_WIDTH / 2;
      case 'small':
        return AVATAR_WIDTH / 1.5;
      case 'big':
        return AVATAR_WIDTH * 2;
      case 'extra-large':
        return AVATAR_WIDTH * 3;
      default:
        return AVATAR_WIDTH;
    }
  };

  // variables
  const avatarSize = getSize();

  // renders
  return (
    <Image
      source={{
        uri,
      }}
      resizeMode="cover"
      size={avatarSize}
    />
  );
};

interface ImageProps {
  size: number;
}

const Image = styled.Image(({ size }: ImageProps) => ({
  width: size,
  height: size,
  borderRadius: size / 2,
}));
