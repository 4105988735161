// libraries
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/native';

// components
import { Checkbox, CustomModal } from '@components/molecules';
import { CustomText, DesigningButton, SpacerColumn } from '@components/atoms';

// misc
import { useSubmitSavedDelivery } from '@hooks/useSubmitSavedDelivery';
import { useAppDispatch } from '@redux/store';
import { useGetDeliveryCourseAndRiders } from '@hooks/useGetDeliveryCourseAndRiders';
import { deliveriesSlice } from '@screens/dashboard/slice';

// types
export type TrainingOptionModalProps = {
  isVisible: boolean;
  onClose(): void;
  onStartTraining(): void;
  onTrainingChecklist(): void;
  color: string;
};

export const TrainingOptionModal: React.FC<TrainingOptionModalProps> = ({
  isVisible,
  onClose,
  onStartTraining,
  onTrainingChecklist,
  color,
}) => {
  // variables
  const dispatch = useAppDispatch();
  const [completeProcessStarted, setCompleteProcessStarted] = useState(false);
  const [submitTrainingComplete, setSubmitTrainingComplete] = useState(false);
  const { delivery, course, riderIds } = useGetDeliveryCourseAndRiders();
  const { control, handleSubmit } = useForm<{ completedAllFeedbacks: boolean }>();
  const { submit } = useSubmitSavedDelivery('Training completed successfully!', {
    onSuccess: () => {
      setSubmitTrainingComplete(false);
      setCompleteProcessStarted(false);
      onClose();
    },
  });

  // hooks
  useEffect(() => {
    // if (submitTrainingComplete) submit(); // code to complete training
    if (submitTrainingComplete) onTrainingChecklist();
  }, [submitTrainingComplete]);

  // functions
  const onComplete = () => {
    setCompleteProcessStarted(true);
    riderIds.forEach(id => {
      dispatch(
        deliveriesSlice.actions.setSavedRiderCourse({
          riderId: id,
          deliveryId: delivery.id,
          courseId: course.id,
          checkComplete: true,
        }),
      );
    });

    setTimeout(() => {
      setSubmitTrainingComplete(true);
    }, 2000);
  };

  // renders
  return (
    <CustomModal
      isVisible={isVisible}
      onClose={onClose}
      backdropOpacity={1}
      backdropColor={color}
      containerStyle={{ height: 350 }}
      onBackdropPress={() => null}>
      <Content>
        <CustomText font="bodyBold">Training Options</CustomText>
        <SpacerColumn size={2} />
        <DesigningButton title="Start Training Session" onPress={onStartTraining} />
        <SpacerColumn size={3} />
        <DesigningButton title="Go to Training Checklist" onPress={onTrainingChecklist} />
        <SpacerColumn size={2} />
      </Content>
    </CustomModal>
  );
};

const Content = styled.View(({ theme: { layout } }) => ({
  marginTop: layout.padding_x5,
  width: '80%',
  alignSelf: 'center',
}));
