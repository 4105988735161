// libraries
import React, { useMemo } from 'react';
import { Image } from 'react-native';

// logo
import logoImage from '@assets/images/ATE-Logo-Transparent.png';

export type ATELogoProps = {
  size?: 'small' | 'medium';
};

export const ATELogo: React.FC<ATELogoProps> = ({ size = 'medium' }) => {
  // variables
  const style = useMemo(() => {
    switch (size) {
      case 'medium':
        return { height: 992 / 20, width: 795 / 20 };

      default:
        return { height: 992 / 25, width: 795 / 25 };
    }
  }, [size]);

  // renders
  return <Image source={logoImage} style={style} />;
};
