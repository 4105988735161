// libraries
import { useMutation } from 'react-query';

// misc
import { useErrorHandler } from '@hooks/useErrorHandler';
import request from '@utils/request';
import { AuthResponse, ServerResponse } from './types';

export type LoginResponse = AuthResponse;

export interface LoginRequest {
  email: string;
  password: string;
}

export const useLogin = ({
  onError,
  onSuccess,
}: {
  onSuccess?: (res: LoginResponse) => void;
  onError?: (res: ServerResponse<unknown>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();

  // request
  const query = useMutation<LoginResponse, ServerResponse<unknown> | undefined, LoginRequest>(
    ['login'],
    async ({ email, password }: LoginRequest) => {
      try {
        const loginData = await request<ServerResponse<AuthResponse>>({
          method: 'post',
          url: `/login`,
          data: { email, password },
        });

        console.log('code', loginData?.data?.code);
        onSuccess && onSuccess(loginData.data);
        return loginData.data;
      } catch (error) {
        triggerError({ error });
        onError && onError(error);
      }
    },
  );

  // return
  return query;
};
