// libraries
import { useQuery } from 'react-query';

// misc
import { useErrorHandler } from '@hooks/useErrorHandler';
import request from '@utils/request';
import { NewsType, ServerResponse } from './types';

type GetNewsResponse = {
  newsData: NewsType[];
};

export const useGetNews = () => {
  // variables
  const { triggerError } = useErrorHandler();

  // request
  const query = useQuery<NewsType[]>(
    [`news`],
    async () => {
      try {
        const data = await request<ServerResponse<GetNewsResponse>>({
          method: 'get',
          url: `/deliveries/news`,
        });

        return data.data.newsData;
      } catch (error) {
        triggerError({ error });
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  // return
  return query;
};
