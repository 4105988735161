// libraries
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/native';
import { useForm } from 'react-hook-form';

// components
import { CustomModal } from '@components/molecules';
import { CustomText, Input, SmallButton, SpacerColumn } from '@components/atoms';

// misc
import { genericStyles } from '@styles/genericStyles';
import { DeliveryDatumRider } from '@screens/dashboard/types';
import { useAppDispatch } from '@redux/store';
import { deliveriesSlice } from '@screens/dashboard/slice';
import { useGetDeliveryCourseAndRiders } from '@hooks/useGetDeliveryCourseAndRiders';
import { useSubmitSavedDelivery } from '@hooks/useSubmitSavedDelivery';
import alert from '@utils/alert';

// types
export type RiderWithdrawnModalProps = {
  isVisible: boolean;
  onClose?(): void;
  rider: DeliveryDatumRider;
};
type FormType = { reason: string };

export const RiderWithdrawnModal: React.FC<RiderWithdrawnModalProps> = ({
  isVisible,
  onClose,
  rider,
}) => {
  // variables
  const [startDeliverySubmit, setStartDeliverySubmit] = useState(false);
  const { handleSubmit, control, reset, getValues } = useForm<FormType>();
  const { delivery, course } = useGetDeliveryCourseAndRiders();
  const dispatch = useAppDispatch();
  const { submit, isLoading } = useSubmitSavedDelivery('Rider withdrawn successfully!', {
    onSuccess: () => {
      onClose();
      setStartDeliverySubmit(false);
    },
  });

  // hooks
  useEffect(() => {
    if (startDeliverySubmit) {
      submit();
    }
  }, [startDeliverySubmit]);

  useEffect(() => {
    reset();
  }, [isVisible]);

  // functions
  const onSubmit = (data: FormType) => {
    dispatch(
      deliveriesSlice.actions.setSavedRiderCourse({
        withdrawalText: data.reason,
        riderId: rider.id,
        deliveryId: delivery.id,
        courseId: course.id,
      }),
    );

    setTimeout(() => {
      setStartDeliverySubmit(true);
    }, 500);
  };

  // Handle Close button of popup
  const handleClose = () => {
    if (getValues('reason') !== undefined) {
      alert('Rider Withdrawn', 'You have unsaved data, would you like to save it now?', [
        {
          text: 'Ok',
          onPress: () => {
            handleSubmit(onSubmit)();
          },
        },
        {
          text: 'Cancel',
          onPress: () => onClose(),
          style: 'cancel',
        },
      ]);
    } else {
      onClose();
    }
  };

  // renders
  return (
    <CustomModal isVisible={isVisible} title="Rider Withdrawn" onClose={handleClose}>
      <Content>
        <CustomText textAlign="center" font="bodyBold">
          {rider?.name}
        </CustomText>
        <SpacerColumn size={2} />
        <CustomText textAlign="center" font="bodyBold">
          Note: Parent see this
        </CustomText>
        <SpacerColumn size={2} />

        <CustomText textAlign="center" font="bodyBold">
          Use this space to provide parent feedback as to why you have withdrawn the rider from the
          course.{' '}
          <CustomText textAlign="center" color="error" font="bodyBold">
            This information will be sent to the parent.
          </CustomText>
        </CustomText>
        <SpacerColumn size={2} />

        <Input
          title=""
          name="reason"
          control={control}
          multiline
          numberOfLines={30}
          containerStyle={{ flex: 1 }}
          rules={{ required: true }}
        />
        <SpacerColumn size={3} />

        <SmallButton title="Add to record" onPress={handleSubmit(onSubmit)} isLoading={isLoading} />
        <SpacerColumn size={1} />
      </Content>
    </CustomModal>
  );
};

const Content = styled.View(({ theme: { layout } }) => ({
  ...genericStyles.fill,
  padding: layout.padding_x2,
}));
