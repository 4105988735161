// libraries
import { ColorPalette } from '@styles/types';
import React from 'react';
import { ActivityIndicator, ActivityIndicatorProps } from 'react-native';
import { useTheme } from 'styled-components/native';

interface LoadingProps extends ActivityIndicatorProps {
  color?: ColorPalette;
}

export const Loading: React.FC<LoadingProps> = ({ color = 'primary', ...restProps }) => {
  const { colors } = useTheme();
  return <ActivityIndicator color={colors[color]} {...restProps} />;
};
