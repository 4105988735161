// libraries
import { layout } from '@styles/layout';
import React from 'react';
import { FlatList } from 'react-native';
import RenderHTML from 'react-native-render-html';
import styled from 'styled-components/native';
import { RouteProp, useRoute } from '@react-navigation/native';

// components
import { SpacerColumn } from '@components/atoms';
import { CollapsableSection, FullScreenLoader } from '@components/molecules';

// misc
import { useGetNews } from '@api/useGetNews';
import { DashboardStackParamList } from '@utils/navigation';
import { formatDateToSimple } from '@utils/date';

export const NewsScreen = () => {
  // variables
  const { data, isLoading } = useGetNews();
  const { params } = useRoute<RouteProp<DashboardStackParamList, 'news'>>();

  // hooks

  // functions

  // returns
  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Container>
      <FlatList
        data={data}
        renderItem={({ item }) => (
          <CollapsableSection
            isExpandedByDefault={item.id === params?.id}
            headerStyle={{ width: '-webkit-fill-available' }}
            title={`${item.title}` + ` - ${formatDateToSimple(item.date, '/', null)}`}
            isRead>
            <RenderHTML contentWidth={400} source={{ html: item.content }} />
          </CollapsableSection>
        )}
        keyExtractor={item => item.id.toString()}
        ItemSeparatorComponent={() => <SpacerColumn size={1} />}
        contentContainerStyle={{ padding: layout.padding_x2 }}
      />
    </Container>
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));
