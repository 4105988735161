// libraries
import React from 'react';
import styled from 'styled-components/native';
import { SvgProps } from 'react-native-svg';
import { PressableProps, useWindowDimensions } from 'react-native';

// components
import { CustomText, SpacerColumn, Badge } from '@components/atoms';

// types
export interface MenuItemProps extends PressableProps {
  Icon: React.FC<SvgProps>;
  text?: string;
  badgeCount?: number;
}

export const MenuItem: React.FC<MenuItemProps> = ({ Icon, text, badgeCount, ...restProps }) => {
  // variables
  let { width } = useWindowDimensions();
  if (document.body.parentElement.style.width == '405px') {
    width = 405;
  }

  // renders
  return (
    <Container width={width} {...restProps}>
      <Icon width={34} />
      <SpacerColumn size={1} />
      <CustomText size={20} font="heading" textAlign="center" style={{ width: 'inherit' }}>
        {text}
      </CustomText>
      {!!badgeCount && <Badge count={badgeCount} />}
    </Container>
  );
};

const Container = styled.Pressable<{ width: number }>(({ theme: { colors, layout }, width }) => ({
  borderRadius: 24,
  backgroundColor: colors.cornflowerBlue,
  width: (width - (layout.padding_x2 * 2 + layout.padding_x2)) / 2,
  // height: (width - (layout.padding_x2 * 2 + layout.padding_x2)) / 2,
  height: 126,
  justifyContent: 'center',
  alignItems: 'center',
  padding: layout.padding_x2,
}));
