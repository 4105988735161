// libraries
import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { useForm } from 'react-hook-form';
import { StatusBar, ListRenderItemInfo, FlatList } from 'react-native';

// components
import {
  FilterCheckbox,
  CustomModal,
  CustomModalProps,
  FullScreenLoader,
} from '@components/molecules';
import {
  CustomIcon,
  CustomText,
  DivColumn,
  Divider,
  SmallButton,
  SpacerColumn,
} from '@components/atoms';

// styles
import { genericStyles } from '@styles/genericStyles';

export interface SortingModalProps extends Omit<CustomModalProps, 'children'> {
  onSortingChange: (settings: SortingType) => void;
}

export type SortingType = {
  category_id: SORTING_TYPE;
};

export type SortingCategory = {
  id: 'follower' | 'following' | number;
  name: string;
};

export type SORTING_TYPE = 'YEAR_GROUP' | 'A-TO-Z';
export type SORTING_DATA_TYPE = { id: SORTING_TYPE; title: string };

const SORTING_DATA: SORTING_DATA_TYPE[] = [
  {
    id: 'YEAR_GROUP',
    title: 'Year Group Order',
  },
  {
    id: 'A-TO-Z',
    title: 'Alphabetical Order',
  },
];

export const SortingModal = ({ onSortingChange, ...restProps }: SortingModalProps) => {
  // variables
  const { colors } = useTheme();
  const { control, setValue, handleSubmit } = useForm<SortingType>({
    mode: 'all',
  });
  const [currentFilter, setCurrentFilter] = useState<SortingType['category_id']>(null);

  // hooks
  useEffect(() => {
    setValue('category_id', currentFilter);
  }, [restProps.isVisible]);

  // functions
  const onApply = (data: SortingType) => {
    onSortingChange(data);
    setCurrentFilter(data['category_id']);
    restProps.onClose();
  };

  const onReset = async () => {
    onSortingChange({ category_id: null });
    setCurrentFilter(null);
    setValue('category_id', null);
    restProps.onClose();
  };

  const renderCategory = ({ item }: ListRenderItemInfo<SORTING_DATA_TYPE>) => (
    <DivColumn key={item.id}>
      <FilterCheckbox
        control={control}
        name={'category_id'}
        text={item.title}
        defaultValue={item.id}
      />

      <SpacerColumn size={0.25} />
    </DivColumn>
  );

  // returns
  return (
    <CustomModal
      {...restProps}
      renderHeader={() => null}
      animationIn="slideInUp"
      animationOut="slideOutDown">
      <HeaderContainer style={genericStyles.rowWithCenterAndSB}>
        <CustomIcon name="x" onPress={restProps.onClose} />
        <CustomText color="text" font="bodyBold" size={16}>
          Sorting
        </CustomText>
        <CustomText color="text" font="bodyRegular" onPress={onReset}>
          Reset
        </CustomText>
      </HeaderContainer>
      <Divider color="silver" />

      {restProps.isVisible && <StatusBar backgroundColor={colors.white} barStyle="dark-content" />}

      <FullScreenLoader isLoading={false}>
        <>
          <Container>
            <SpacerColumn size={0.5} />
            <Heading color="text" font="bodyMedium" size={16} />

            <FlatList
              data={SORTING_DATA}
              renderItem={renderCategory}
              keyExtractor={item => item.id.toString()}
            />
          </Container>

          <Footer>
            <SmallButton title="Apply" onPress={handleSubmit(onApply)} />
          </Footer>
        </>
      </FullScreenLoader>
    </CustomModal>
  );
};

const Container = styled.View(({ theme: { layout, colors } }) => ({
  flex: 1,
  backgroundColor: colors.primaryBackground,
  paddingHorizontal: layout.padding_x2,
}));

const HeaderContainer = styled.View(({ theme: { layout, colors } }) => ({
  backgroundColor: colors.primaryBackground,
  paddingBottom: layout.padding_x2,
  paddingHorizontal: layout.padding_x2,
}));

const Heading = styled(CustomText)(({ theme: { layout } }) => ({
  ...genericStyles.upperCase,
  paddingBottom: layout.padding * 0.2,
}));

const Footer = styled.View(({ theme: { layout, colors } }) => ({
  // todo-ios: implement ios shadow
  elevation: 0.5,
  paddingHorizontal: layout.padding_x2,
  paddingVertical: layout.padding_x2,
  backgroundColor: colors.primaryBackground,
}));
