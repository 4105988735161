// libraries
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FlatList, View } from 'react-native';
import styled from 'styled-components/native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useNetInfo } from '@react-native-community/netinfo';
import * as Animatable from 'react-native-animatable';

// components
import { CustomText, Heading, SmallButton, SpacerColumn } from '@components/atoms';
import { RiderItem } from './components/RiderItem';
import { RiderHeaderTools } from './components/RiderHeaderTools';

// misc
import { SORTING_TYPE } from '@components/organisms';
import { layout } from '@styles/layout';
import { genericStyles } from '@styles/genericStyles';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { DashboardStackParamList } from '@utils/navigation';
import {
  selectCourseById,
  selectDeliveryById,
  selectSavedDeliveryById,
} from '@screens/dashboard/selectors';
import { DeliveryDatumRider } from '@screens/dashboard/types';
import { deliveriesSlice } from '@screens/dashboard/slice';
import { useGetDeliveryCourseAndRiders } from '@hooks/useGetDeliveryCourseAndRiders';
import { isOnline } from '@utils/connect';
import { flexSearch } from '@utils/text';
import { useSubmitSavedDelivery } from '@hooks/useSubmitSavedDelivery';
import alert from '@utils/alert';

export const AllRiderScreen = () => {
  // variables
  const { isConnected } = useNetInfo();
  const {
    params: { deliveryId, courseId, isClearRiders },
  } = useRoute<RouteProp<DashboardStackParamList, 'allRiders'>>();
  const delivery = useAppSelector(state => selectDeliveryById(state, deliveryId));
  const course = useAppSelector(state => selectCourseById(state, deliveryId, courseId));
  const isRiderSelectionScreen = !!course;
  const { navigate, setOptions, replace } =
    useNavigation<NativeStackNavigationProp<DashboardStackParamList>>();
  const { riderIds } = useGetDeliveryCourseAndRiders();
  const [selectedRiderIds, setSelectedRiderIds] = useState((!isClearRiders && riderIds) || []);
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState('');
  const [sorting, setSorting] = useState<SORTING_TYPE>(null);
  const savedDelivery = useAppSelector(state => selectSavedDeliveryById(state, deliveryId));

  const [updateRiderAttendance, setUpdateRiderAttendance] = useState<{
    riderId: number;
    type: 'absent' | 'present';
  }>(null);

  const { submit } = useSubmitSavedDelivery(
    `Rider marked ${updateRiderAttendance?.type} successfully!`,
    { onSuccess: () => setUpdateRiderAttendance(null) },
  );

  const riders = useMemo(() => {
    const filteredRiders = delivery.riders.filter(rider => flexSearch(rider.name, searchText));

    if (sorting === 'YEAR_GROUP') {
      filteredRiders.sort((a, b) => {
        const aVal = !a.yearGroup ? '' : a.yearGroup;
        const bVal = !b.yearGroup ? '' : b.yearGroup;
        return parseInt(aVal.replace('Y', '')) - parseInt(bVal.replace('Y', ''));
      });
    } else if (sorting === 'A-TO-Z') {
      filteredRiders.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    }

    return filteredRiders;
  }, [searchText, delivery.riders, sorting]);

  // hooks
  useEffect(() => {
    if (isRiderSelectionScreen) {
      setOptions({ title: 'Rider Selection' });
    }
  }, []);

  useEffect(() => {
    if (updateRiderAttendance?.riderId) {
      submit();
    }
  }, [JSON.stringify(savedDelivery)]);

  // functions
  const onPressRider = (item: DeliveryDatumRider) => {
    if (course) {
      if (item.presenceStatus === 0) {
        alert(
          'Rider Selection',
          "You have chosen an absent rider, if you select 'Ok' then the rider will be marked as 'not absent'.",
          [
            {
              text: 'Ok',
              onPress: () => {
                const selectedItem = { ...item };
                selectedItem.presenceStatus = 1;
                handleSelectRider(selectedItem);
                onSwipePresent(item.id);
              },
            },
            {
              text: 'Cancel',
              onPress: () => {},
              style: 'cancel',
            },
          ],
        );
      } else {
        handleSelectRider(item);
      }
    } else {
      navigate('riderDetail', { deliveryId: deliveryId, riderId: item.id });
    }
  };

  const handleSelectRider = (item: DeliveryDatumRider) => {
    let riderIds = [...selectedRiderIds];
    if (riderIds.includes(item.id)) {
      riderIds = riderIds.filter(riderId => riderId !== item.id);
    } else if (riderIds.length < course.maxRidersPerInstructor) {
      riderIds.push(item.id);
    }

    setSelectedRiderIds(riderIds);
  };

  const onPressUpdate = () => {
    replace('training', { deliveryId, courseId });
    if (isOnline() && isConnected) {
      dispatch(deliveriesSlice.actions.clearRiders({ deliveryId }));
    }
    dispatch(
      deliveriesSlice.actions.setSelectedRidersIds({
        deliveryId,
        courseId,
        ridersIds: selectedRiderIds,
      }),
    );
  };

  const onSwipeAbsent = (id: number) => {
    setUpdateRiderAttendance({ riderId: id, type: 'absent' });
    setTimeout(() => {
      dispatch(
        deliveriesSlice.actions.setSaveDeliveryRider({
          deliveryId,
          riderId: id,
          attended: 0,
        }),
      );
    }, 500);
    setTimeout(() => {
      dispatch(
        deliveriesSlice.actions.updatePresenceStatusOfRider({
          delivery,
          deliveryId,
          riderId: id,
          presenceValue: 0,
        }),
      );
    }, 1000);
  };

  const onSwipePresent = (id: number) => {
    setUpdateRiderAttendance({ riderId: id, type: 'present' });
    setTimeout(() => {
      dispatch(
        deliveriesSlice.actions.setSaveDeliveryRider({
          deliveryId,
          riderId: id,
          attended: 1,
        }),
      );
    }, 500);
    setTimeout(() => {
      dispatch(
        deliveriesSlice.actions.updatePresenceStatusOfRider({
          delivery,
          deliveryId,
          riderId: id,
          presenceValue: 1,
        }),
      );
    }, 1000);
  };

  // returns
  const Header = useCallback(
    () => (
      <View style={{ backgroundColor: 'white' }}>
        <RiderHeaderTools
          RenderHeader={() => (
            <View style={genericStyles.rowWithCenterAndSB}>
              <CustomText color="primary" size={20} font="bodyBold">
                {isRiderSelectionScreen
                  ? `Riders (${selectedRiderIds.length}/${course.maxRidersPerInstructor}) selected`
                  : `Select trainee to view:`}
              </CustomText>

              {isRiderSelectionScreen && (
                <Animatable.View animation="pulse" easing="ease-in" iterationCount={5}>
                  <SmallButton
                    title="Update"
                    disabled={!selectedRiderIds.length}
                    onPress={onPressUpdate}
                  />
                </Animatable.View>
              )}
            </View>
          )}
          onChangeSearchText={setSearchText}
          onSortingChange={sort => setSorting(sort['category_id'])}
        />
      </View>
    ),
    [selectedRiderIds],
  );

  return (
    <Container>
      <Heading title={delivery.hostName} status={delivery.status} showSchoolIcon />

      <Content>
        <FlatList
          data={riders}
          ListHeaderComponent={Header}
          stickyHeaderIndices={[0]}
          renderItem={({ item }) => (
            <RiderItem
              showAnimation={!isRiderSelectionScreen}
              isSelected={selectedRiderIds.includes(item.id)}
              onPress={() => onPressRider(item)}
              onSwipeAbsent={onSwipeAbsent}
              onSwipePresent={onSwipePresent}
              {...item}
            />
          )}
          keyExtractor={item => item.id.toString()}
          ItemSeparatorComponent={() => <SpacerColumn size={1} />}
          contentContainerStyle={{
            paddingHorizontal: layout.padding_x1,
            paddingVertical: layout.padding_x2,
          }}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        />
      </Content>
    </Container>
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));

const Content = styled.View(() => ({
  flex: 1,
}));
