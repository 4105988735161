import cryptojs from 'crypto-js';
import { getSecretPin } from './secure-storage';

export const encrypt = (data: any) => {
  const secretPin = getSecretPin();

  return cryptojs.AES.encrypt(JSON.stringify(data), secretPin).toString();
};

export const decrypt = (data: string) => {
  const secretPin = getSecretPin();

  if (secretPin) {
    const bytes = cryptojs.AES.decrypt(data, secretPin);
    const originalData = JSON.parse(bytes.toString(cryptojs.enc.Utf8));

    return originalData;
  } else {
    return undefined;
  }
};

export const PIN_EXPIRE_DAYS = 14;
