// libraries
import React from 'react';
import { CustomPressable, CustomPressableProps } from './CustomPressable';
import styled, { useTheme } from 'styled-components/native';
import { StyleSheet } from 'react-native';

// components
import { CustomText } from './CustomText';

// misc
import { genericStyles } from '@styles/genericStyles';
import { Loading } from './Loading';
import { AnimationFadeIn } from './animations';

// types
export interface DesigningButtonProps extends CustomPressableProps {
  title: string;
  isLoading?: boolean;
  width?: string;
  bgColor?: string;
  maxWidth?: string;
}

export const DesigningButton: React.FC<DesigningButtonProps> = ({
  title,
  isLoading,
  width,
  bgColor,
  maxWidth,
  ...restProps
}) => {
  // variables
  const { colors } = useTheme();

  // functions

  // renders
  return (
    <AButton
      {...restProps}
      style={{
        backgroundColor: bgColor ? bgColor : colors.primary,
        width: width,
        maxWidth: maxWidth,
      }}>
      {!!isLoading && (
        <AnimationFadeIn
          style={[
            styles.loading,
            {
              backgroundColor: bgColor ? bgColor : colors.primary,
              width: width,
              maxWidth: maxWidth,
            },
          ]}>
          <Loading color="white" />
        </AnimationFadeIn>
      )}
      <CustomText style={{ color: 'black' }} font="bodyBold">
        {title}
      </CustomText>
    </AButton>
  );
};

const AButton = styled(CustomPressable)(({ theme: { layout, colors } }) => ({
  ...genericStyles.jcAiCenter,
  //   width: layout.window.width * 0.7,
  backgroundColor: colors.primary,
  borderRadius: 10,
  padding: layout.padding_x1,
}));

const styles = StyleSheet.create({
  loading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
    borderRadius: 10,
    ...genericStyles.jcAiCenter,
  },
});
