// libraries
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/native';

// components
import { CustomModal, SelectInput } from '@components/molecules';
import { DivColumn, Input, SmallButton, SpacerColumn } from '@components/atoms';

// misc
import { DeliveryDatumRider } from '@screens/dashboard/types';
import { genericStyles } from '@styles/genericStyles';

// types
export type InstructorNotesModalProps = {
  isVisible: boolean;
  onClose?(): void;
  riders?: DeliveryDatumRider[];
};

type FormType = { riderNotes: { id: string; notes: string } };

export const InstructorNotesModal: React.FC<InstructorNotesModalProps> = ({
  isVisible,
  onClose,
  riders,
}) => {
  // variables
  const { handleSubmit, control, setValue } = useForm<FormType>();
  const modifyRiders = useMemo(
    () => riders.map(r => ({ label: r.name, value: r.id.toString() })),
    [riders],
  );

  // functions
  const onSubmit = data => {};

  // renders
  return (
    <CustomModal isVisible={isVisible} title="Instructor Notes" onClose={onClose}>
      <Content>
        <DivColumn style={{ width: '100%', height: 80 }}>
          <SelectInput
            name="riderNotes.id"
            control={control}
            data={modifyRiders}
            onChangeValue={setValue}
            title=""
            placeHolder="Choose Rider"
            sheetTitle="Select rider"
            rules={{ required: true }}
          />
        </DivColumn>

        <SpacerColumn size={2} />
        <Input
          title="Notes"
          fontSize={14}
          name="riderNotes.notes"
          control={control}
          multiline
          numberOfLines={30}
          containerStyle={{ flex: 1 }}
        />
        <SpacerColumn size={3} />
        <SmallButton title="Add" onPress={handleSubmit(onSubmit)} />
        <SpacerColumn size={1} />
      </Content>
    </CustomModal>
  );
};

const Content = styled.View(({ theme: { layout } }) => ({
  ...genericStyles.fill,
  padding: layout.padding_x2,
}));
