// libraries
import React, { useEffect, useMemo, useState } from 'react';
import { SectionList, StyleSheet } from 'react-native';
import styled from 'styled-components/native';
import { useForm } from 'react-hook-form';
import { useNavigation } from '@react-navigation/native';

// components
import { CustomText, DivColumn, Heading, SmallButton, SpacerColumn } from '@components/atoms';
import { PointCounter, SelectInput } from '@components/molecules';

// misc
import { genericStyles } from '@styles/genericStyles';
import { layout } from '@styles/layout';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectCharacteristics } from './selectors';
import { useGetDeliveryCourseAndRiders } from '@hooks/useGetDeliveryCourseAndRiders';
import { deliveriesSlice } from './slice';
import { ID_SUFFIX } from '@utils/keys';
import { addSuffix } from '@utils/text';
import { useSubmitSavedDelivery } from '@hooks/useSubmitSavedDelivery';
import { Characteristic } from './types';

type CharacteristicsType = {
  courseId: string;
  charasteristicIds: { [type in Characteristic['type']]: { [id: string]: string } };
};

export const CharacteristicsScreen = () => {
  // variables
  const [startDeliverySubmit, setStartDeliverySubmit] = useState(false);
  const { delivery } = useGetDeliveryCourseAndRiders();
  const { goBack } = useNavigation();
  const { submit, isLoading } = useSubmitSavedDelivery('Characteristics updated successfully!', {
    onSuccess: () => {
      setStartDeliverySubmit(false);
      goBack();
    },
  });
  const dispatch = useAppDispatch();
  const { control, setValue, getValues, watch, handleSubmit, trigger, formState } =
    useForm<CharacteristicsType>({
      mode: 'onSubmit',
      defaultValues: {
        courseId: delivery.courseSummary[0].id.toString(),
      },
    });
  const selectedCourseId = watch('courseId');

  const selectedCharacteristicIds = watch('charasteristicIds');
  const characteristics = useAppSelector(selectCharacteristics);

  const sectionedCharacteristics = useMemo(
    () => [
      { title: 'Gender', data: characteristics.filter(v => v.type === 'gender') },
      {
        title: 'SEND / Pupil Premium',
        data: characteristics.filter(v => v.type === 'send' || v.type === 'fsm'),
      },
      {
        title: 'Ethnicity',
        data: characteristics.filter(v => v.type === 'ethnicity'),
      },
    ],
    [characteristics],
  );

  const courseData = useMemo(() => {
    return delivery.courseSummary.map(course => ({
      label: `${course.yearGroupLabel}, ${course.courseLabel} (${course.bookedAllocation})`,
      value: course.id.toString(),
    }));
  }, [delivery.courseSummary]);

  // hooks

  useEffect(() => {
    if (startDeliverySubmit) {
      submit();
    }
  }, [startDeliverySubmit]);

  useEffect(() => {
    // setValue(
    //   'charasteristicIds',
    //   characteristics.reduce((prev, cur) => ({ ...prev, [addSuffix(cur.id)]: '0' }), {}),
    // );
    // if (selectedCourseId && savedCourseChars?.characteristics) {
    //   const charIds: CharacteristicsType['charasteristicIds'] =
    //     savedCourseChars.characteristics.reduce(
    //       (prev, cur) => ({ ...prev, [addSuffix(cur.id)]: cur.total.toString() }),
    //       {},
    //     );
    //   setValue('charasteristicIds', charIds);
    // }
  }, [selectedCourseId]);

  // functions
  const onChangeCharacteristics = () => {
    const updatedChar = [];
    for (const [id, total] of Object.entries(
      Object.values(selectedCharacteristicIds).reduce((prev, char) => ({ ...prev, ...char }), {}) ||
        {},
    )) {
      if (parseInt(total) > 0) {
        updatedChar.push({ id: parseInt(id.replace(ID_SUFFIX, '')), total: parseInt(total) });
      }
    }

    dispatch(
      deliveriesSlice.actions.setDeliveryCharaceteristics({
        characteristics: updatedChar,
        courseId: parseInt(selectedCourseId),
        deliveryId: delivery.id,
      }),
    );
  };

  const onPressSave = () => {
    onChangeCharacteristics();

    setTimeout(() => {
      setStartDeliverySubmit(true);
    }, 500);
  };

  // returns
  return (
    <Container>
      <Heading showSchoolIcon title={delivery.hostName} status={delivery.status} />

      <DivColumn ph={2} pt={2}>
        <CustomText textAlign="center">
          Please choose the year/course, take the characteristics for all riders and select save:
        </CustomText>
      </DivColumn>

      <SpacerColumn size={2} />
      <DivColumn style={styles.riderContainer}>
        <SelectInput
          name="courseId"
          control={control}
          data={courseData}
          onChangeValue={setValue}
          title=""
          placeHolder="Choose Rider"
          sheetTitle="Select rider"
          rules={{ required: true }}
        />
      </DivColumn>

      <SectionList
        sections={sectionedCharacteristics}
        keyExtractor={item => item.id.toString()}
        renderSectionHeader={({ section }) => (
          <HeadingText font="bodyBold" size={14}>
            {section.title}
          </HeadingText>
        )}
        renderItem={({ item }) => (
          <PointCounter
            name={`charasteristicIds.${item.type}.${addSuffix(item.id)}`}
            text={item.label_name}
            control={control}
            defaultValue={`0`}
            backgroundColor={item.colour}
            onChange={() => formState.isSubmitted && trigger('charasteristicIds')}
            rules={{
              validate: () => {
                const genderPoints =
                  Object.values(getValues('charasteristicIds')['gender'])?.reduce(
                    (prev, cur) => prev + parseInt(cur),
                    0,
                  ) || 0;

                const sendPoints =
                  Object.values(getValues('charasteristicIds')['send'])?.reduce(
                    (prev, cur) => prev + parseInt(cur),
                    0,
                  ) || 0;

                const fsmPoints =
                  Object.values(getValues('charasteristicIds')['fsm'])?.reduce(
                    (prev, cur) => prev + parseInt(cur),
                    0,
                  ) || 0;

                const ethnicityPoints =
                  Object.values(getValues('charasteristicIds')['ethnicity'])?.reduce(
                    (prev, cur) => prev + parseInt(cur),
                    0,
                  ) || 0;

                return genderPoints !== ethnicityPoints
                  ? `Gender and Ethnicity total count doesn't match, please check again.`
                  : true;
                // return genderPoints !== ethnicityPoints ||
                //   sendPoints + fsmPoints !== ethnicityPoints
                //   ? `Gender, Send / Pupil Premium and Ethnicity total count doesn't match, please check again.`
                //   : true;
              },
            }}
          />
        )}
        ListHeaderComponent={() => <SpacerColumn size={2} />}
        contentContainerStyle={{ paddingHorizontal: layout.padding_x2 }}
        ListFooterComponent={() => <SpacerColumn size={6.3} />}
      />

      <Footer>
        <SmallButton
          title="Save"
          pressableStyle={genericStyles.fill}
          onPress={handleSubmit(onPressSave)}
          isLoading={isLoading}
          style={{ paddingVertical: layout.padding_x1_5 }}
        />
      </Footer>
    </Container>
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));

const Footer = styled.View(({ theme: { layout } }) => ({
  ...genericStyles.rowWithCenterAndSB,
  paddingHorizontal: layout.padding_x2,
  paddingVertical: layout.padding_x1,
  backgroundColor: 'transparent',
  position: 'absolute',
  bottom: 0,
  zIndex: 1,
  width: '100%',
}));

const styles = StyleSheet.create({
  checkboxContainer: {
    ...genericStyles.rowWithSB,
    marginHorizontal: layout.padding_x2,
  },
  riderContainer: { width: '100%', height: 64, paddingHorizontal: layout.padding_x2 },
});

const HeadingText = styled(CustomText)(({ theme: { layout } }) => ({
  paddingTop: layout.padding_x2,
  paddingBottom: layout.padding_x3,
  textAlign: 'center',
}));
