import { useUser } from '@context/UserProvider';
import { RouteItem } from '@utils/navigation';
import { useMemo } from 'react';

export const useFilterRoutes = <T>(routes: RouteItem<T>[]) => {
  // variables
  const { isAuthComplete } = useUser();

  // hooks
  const modifyStack = useMemo(
    () =>
      routes.filter(({ forRole }) =>
        isAuthComplete ? forRole != 'only-guest' : forRole != 'only-loggedin-user',
      ),
    [isAuthComplete],
  );

  // returns
  return modifyStack;
};
