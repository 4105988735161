// libraries
import moment from 'moment';

// misc
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootState } from '@redux/store';
import { DeliveryStatusType, InstructorNote, Note, StateInstructorNote } from './types';
import { DELIVERY_STATUS } from '@utils/deliveries';

const deliveries = (state: RootState) => state.deliveries;
const deliveryStatus = (state: RootState, status: DeliveryStatusType) => status;
const deliveryId = (state: RootState, deliveryId: number) => deliveryId;
const getRiderId = (state: RootState, id: number, riderId: number) => riderId;
const getCourseId = (state: RootState, id: number, courseId?: number) => courseId;
const getRiderIds = (state: RootState, id: number, riderIds?: number[]) => riderIds;

export const selectAllApiDeliveries = createDraftSafeSelector(
  [deliveries],
  deliveries => deliveries.apiData,
);

export const selectSystemDataDeliveries = createDraftSafeSelector(
  [deliveries],
  deliveries => deliveries.apiData.SystemData,
);

export const selectDeliveriesByStatus = createDraftSafeSelector(
  [deliveries, deliveryStatus],
  (deliveries, status) => {
    // do something with a, b, and c, and return a result
    return deliveries.apiData?.deliveryData.filter(
      d => DELIVERY_STATUS[d.deliveries.status] === status,
    );
  },
);

export const selectDeliveryById = createDraftSafeSelector(
  [deliveries, deliveryId],
  (deliveries, id) => (deliveries.structuredData ? deliveries.structuredData[id] : null),
);

export const selectRiderById = createDraftSafeSelector(
  [deliveries, deliveryId, getRiderId],
  (deliveries, id, riderId) =>
    deliveries.structuredData
      ? deliveries.structuredData[id]?.riders.find(rider => rider.id === riderId) || null
      : null,
);

export const selectCourseById = createDraftSafeSelector(
  [deliveries, deliveryId, getCourseId],
  (deliveries, id, courseId) =>
    deliveries.structuredData
      ? deliveries.structuredData[id]?.courses?.find(course => course.id === courseId) || null
      : null,
);

export const selectDeliveryRiders = createDraftSafeSelector(
  [deliveries, deliveryId, getRiderIds],
  (deliveries, id, riderIds) =>
    deliveries.structuredData
      ? riderIds?.length
        ? deliveries.structuredData[id]?.riders.filter(rider => riderIds.includes(rider.id))
        : deliveries.structuredData[id]?.riders
      : null,
);

export const selectInstructorNotesById = createDraftSafeSelector(
  [deliveries, deliveryId, getRiderId],
  (deliveries, deliveryId, riderId) => {
    let instructorNotes: (Omit<InstructorNote, 'notes'> & Note)[] = [];

    const valueDelivery = deliveries.instructorNotes[deliveryId];

    if (valueDelivery) {
      for (const [key, value] of Object.entries(valueDelivery)) {
        if (Object.values(value.notes).find(n => riderId === n.riderId)) {
          const notes: (Omit<InstructorNote, 'notes'> & Note)[] = value.notes
            .filter(n => riderId === n.riderId)
            .map(note => ({
              instructorId: value.instructorId,
              instructorName: value.instructorName,
              note_text: note.note_text,
              datetime: note.datetime,
            }))
            .sort((left, right) => {
              return moment(right.datetime).diff(moment(left.datetime));
            });

          instructorNotes = [...instructorNotes, ...notes];
        }
      }

      return instructorNotes;
    }
  },
);

export const selectInstructorNotesByRiders = createDraftSafeSelector(
  [deliveries, deliveryId, getRiderIds],
  (deliveries, id, riderIds) => {
    if (deliveries.instructorNotes && Object.values(deliveries.instructorNotes).length && id) {
      if (riderIds) {
        let instructorNotes: { [instructorId: number]: StateInstructorNote } = {};

        for (const [key, value] of Object.entries(deliveries.instructorNotes[id])) {
          if (Object.values(value.notes).find(n => riderIds.includes(n.riderId))) {
            const notes = [
              ...(instructorNotes[key]?.notes || []),
              ...value.notes.filter(n => riderIds.includes(n.riderId)),
            ].sort((left, right) => {
              return moment(right.datetime).diff(moment(left.datetime));
            });

            instructorNotes = {
              ...instructorNotes,
              [key]: {
                ...value,
                notes,
              },
            };
          }
        }

        return instructorNotes;
      }

      return deliveries.instructorNotes[id];
    }

    return null;
  },
);

export const selectCharacteristics = createDraftSafeSelector(
  [deliveries],
  deliveries => deliveries.characteristics,
);

export const selectSelectedRiders = createDraftSafeSelector(
  [deliveries, deliveryId, getCourseId],
  (deliveries, deliveryId, courseId) =>
    (deliveries.selectedRiders[deliveryId] && deliveries.selectedRiders[deliveryId][courseId]) ||
    [],
);

export const selectAllSelectedRiders = createDraftSafeSelector(
  [deliveries],
  deliveries =>
    (Object.keys(deliveries.selectedRiders)?.length && deliveries.selectedRiders) || null,
);

export const selectSelectedRidersIds = createDraftSafeSelector(
  [deliveries, deliveryId, getCourseId],
  (deliveries, deliveryId, courseId) =>
    (deliveries.selectedRiders[deliveryId] &&
      deliveries.selectedRiders[deliveryId][courseId]?.map(r => r.id)) ||
    [],
);

export const selectSavedDeliveries = createDraftSafeSelector(
  [deliveries],
  deliveries => deliveries.savedDeliveries,
);

export const selectGeneralInstructorNotes = createDraftSafeSelector(
  [deliveries, deliveryId],
  (deliveries, deliveryId) => deliveries.generalInstructorNotes[deliveryId] || [],
);

export const selectInstructorNotes = createDraftSafeSelector(
  [deliveries],
  deliveries => deliveries.instructorNotes,
);

export const selectSavedDeliveryById = createDraftSafeSelector(
  [deliveries, deliveryId],
  (deliveries, deliveryId) => deliveries.savedDeliveries[deliveryId],
);

export const selectSavedCoursesByDeliveryId = createDraftSafeSelector(
  [deliveries, deliveryId],
  (deliveries, deliveryId) => deliveries.savedDeliveries[deliveryId]?.courses,
);

export const selectSavedChars = createDraftSafeSelector(
  [deliveries, deliveryId, getCourseId],
  (deliveries, deliveryId, courseId) =>
    deliveries.savedDeliveries[deliveryId]?.riderCharacteristics.find(r => r.id === courseId) ||
    undefined,
);

export const selectSavedCourse = createDraftSafeSelector(
  [deliveries, deliveryId, getCourseId],
  (deliveries, deliveryId, courseId) =>
    deliveries.savedDeliveries[deliveryId]?.courses?.find(r => r.id === courseId) || undefined,
);

export const selectSavedRiders = createDraftSafeSelector(
  [deliveries, deliveryId, getRiderIds],
  (deliveries, deliveryId, riderIds) =>
    deliveries.savedDeliveries[deliveryId]?.riders.filter(r => riderIds.includes(r.rider_id)) ||
    undefined,
);

export const selectSavedRider = createDraftSafeSelector(
  [deliveries, deliveryId, getRiderId],
  (deliveries, deliveryId, riderId) =>
    deliveries.savedDeliveries[deliveryId]?.riders.find(r => riderId === r.rider_id) || undefined,
);

export const selectCompleteTrainingCourseSurvey = createDraftSafeSelector(
  [deliveries, deliveryId, getCourseId],
  (deliveries, deliveryId) => {
    const completedCourseSurvey = deliveries.savedDeliveries[deliveryId]?.riders?.map(r =>
      r.courses.find(course => course.complete === 1),
    );
    return completedCourseSurvey;
  },
);

export const selectRiderKitCheckText = createDraftSafeSelector(
  [deliveries, deliveryId, getRiderId],
  (deliveries, deliveryId, riderId) =>
    deliveries.savedDeliveries[deliveryId]?.riders?.find(r => r.rider_id === riderId)
      ?.kitCheckNotes || undefined,
);

export const selectRiderInstructorNotes = createDraftSafeSelector(
  [deliveries, deliveryId, getRiderId],
  (deliveries, deliveryId, riderId) =>
    deliveries.savedDeliveries[deliveryId]?.riders?.find(r => r.rider_id === riderId)
      ?.instructor_notes[0] || undefined,
);

export const selectAllRiders = createDraftSafeSelector(
  [deliveries],
  deliveries => deliveries.riders,
);

export const selectApiAppVersion = createDraftSafeSelector(
  [deliveries],
  deliveries => deliveries?.apiData?.SystemData?.appVersion,
);
