export const APP_AUTH_TOKEN = 'APP_AUTH_TOKEN';
export const APP_USER = 'APP_USER';
export const APP_IS_LOGGED_IN_KEY = 'APP_IS_LOGGED_IN_KEY';
export const APP_LANGUAGE_KEY = 'APP_LANGUAGE_KEY';
export const APP_ONBOARDING_KEY = 'APP_ONBOARDING_KEY';
export const PIN_STORAGE_KEY = 'PIN_STORAGE_KEY';
export const DELIVERY_SAVE_KEY = 'DELIVERY_SAVE_KEY';
export const DELIVERY_POST_SUBMIT_KEY = 'DELIVERY_POST_SUBMIT_KEY';
export const DELIVERY_SELECTED_RIDERS_KEY = 'DELIVERY_SELECTED_RIDERS_KEY';
export const APP_VERSION_KEY = 'APP_VERSION_KEY';
export const ID_SUFFIX = '_id';
export const PIN_SET_TIME = 'PIN_SET_TIME';
