// libraries
import React from 'react';
import styled from 'styled-components/native';

// components
import { CustomText, DivColumn } from '@components/atoms';

// misc

// types
export type DeliveryDetailSectionProps = {
  title?: string;
};

export const DeliveryDetailSection: React.FC<
  React.PropsWithChildren<DeliveryDetailSectionProps>
> = ({ title, children }) => {
  // variables

  // functions

  // renders
  return (
    <DivColumn>
      {title && <HeadingText font="bodyBold">{title}</HeadingText>}
      <ContentContainer>{children}</ContentContainer>
    </DivColumn>
  );
};

const HeadingText = styled(CustomText)(({ theme: { colors, layout } }) => ({
  backgroundColor: colors.alto,
  padding: layout.padding_x1,
}));

const ContentContainer = styled.View(({ theme: { layout } }) => ({
  padding: layout.padding_x1,
}));
