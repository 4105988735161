// libraries
import React, { useCallback } from 'react';
import { Dimensions, View } from 'react-native';
import styled from 'styled-components/native';
import { PanGestureHandler, PanGestureHandlerGestureEvent } from 'react-native-gesture-handler';
import Animated, {
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { showMessage } from 'react-native-flash-message';

// components
import { CustomIcon, CustomText, SpacerRow } from '@components/atoms';

// misc
import { genericStyles } from '@styles/genericStyles';
import ConsentNotReceivedIcon from '@assets/icons/consent-not-received.svg';
import ConsentReceivedIcon from '@assets/icons/consent-received.svg';
import ConsentWithdrawnIcon from '@assets/icons/consent-withdrawn.svg';
import DontHasBikeIcon from '@assets/icons/cycle-cross.svg';
import MedicalIssuesIcon from '@assets/icons/medical-issues.svg';
import NoPhotographyIcon from '@assets/icons/no-photography.svg';
import NoteFromTeacherIcon from '@assets/icons/note-from-teacher.svg';
import NotPresentIcon from '@assets/icons/not-present.svg';
import RiderCannotRideIcon from '@assets/icons/rider-cannot-ride.svg';
import SendNotesIcon from '@assets/icons/send-notes.svg';
import { DeliveryDatumRider } from '@screens/dashboard/types';

// types
export interface RiderItemProps extends DeliveryDatumRider {
  onPress?: () => void;
  isSelected?: boolean;
  showAnimation?: boolean;
  onSwipeAbsent?: (riderId: number) => void;
  onSwipePresent?: (riderId: number) => void;
}

const ICON_SIZE = 30;

const { width: SCREEN_WIDTH } = Dimensions.get('window');
const ABSENT_TRANSLATE_X_THRESHOLD = -SCREEN_WIDTH * 0.3;
const PRESENT_TRANSLATE_X_THRESHOLD = SCREEN_WIDTH * 0.3;

export const RiderItem: React.FC<RiderItemProps> = ({
  id,
  name,
  yearGroup,
  consentStatus,
  hasBike,
  consentInformation,
  sendNotes,
  medicalNotes,
  photographyAllowed,
  presenceStatus,
  instructorNotes,
  onPress,
  showAnimation = true,
  isSelected,
  onSwipeAbsent,
  onSwipePresent,
}) => {
  // variables
  const translateX = useSharedValue(0);

  // functions
  const panGesture = useAnimatedGestureHandler<PanGestureHandlerGestureEvent>({
    onActive: event => {
      translateX.value = event.translationX;
    },
    onEnd: () => {
      const makeItAbsent = translateX.value < ABSENT_TRANSLATE_X_THRESHOLD;
      const makeItPresent = translateX.value > PRESENT_TRANSLATE_X_THRESHOLD;

      if (makeItAbsent) {
        translateX.value = withTiming(0, undefined, isFinished => {
          if (isFinished && onSwipeAbsent) {
            onSwipeAbsent(id);
          }
        });
      } else if (makeItPresent) {
        translateX.value = withTiming(0, undefined, isFinished => {
          if (isFinished && onSwipePresent) {
            onSwipePresent(id);
          }
        });
      } else {
        translateX.value = withTiming(0);
      }
    },
  });

  // renders
  const ConsentIcon = useCallback(() => {
    switch (consentStatus) {
      case 0:
        return <ConsentWithdrawnIcon width={ICON_SIZE} />;

      case 1:
        return <ConsentReceivedIcon width={ICON_SIZE} />;

      default:
        return <ConsentNotReceivedIcon width={ICON_SIZE} />;
    }
  }, []);

  const rStyle = useAnimatedStyle(() => ({
    transform: [
      {
        translateX: translateX.value,
      },
    ],
  }));

  const absentContainerStyle = useAnimatedStyle(() => {
    const opacity = withTiming(translateX.value < ABSENT_TRANSLATE_X_THRESHOLD ? 1 : 0);
    return { opacity, top: 3 };
  });

  const presentContainerStyle = useAnimatedStyle(() => {
    const opacity = withTiming(translateX.value > PRESENT_TRANSLATE_X_THRESHOLD ? 1 : 0);
    return { opacity, top: 8 };
  });

  return (
    <Animated.View style={[genericStyles.row, genericStyles.w100, genericStyles.fill]}>
      <ActionButtonContainer type="absent" style={[absentContainerStyle]}>
        <CustomIcon color="error" type="material" name="person-remove" />
        <SpacerRow size={0.5} />
        <CustomText color="error">Absent</CustomText>
      </ActionButtonContainer>

      <ActionButtonContainer type="present" style={[presentContainerStyle]}>
        <CustomIcon color="success" type="material" name="person-add" />
        <SpacerRow size={0.5} />
        <CustomText color="success">Present</CustomText>
      </ActionButtonContainer>

      <PanGestureHandler
        enabled={showAnimation}
        onGestureEvent={panGesture}
        failOffsetY={[-5, 5]}
        activeOffsetX={[-5, 5]}>
        <Animated.View style={[genericStyles.fill, genericStyles.row, rStyle]}>
          <DetailContainer onPress={onPress} isSelected={isSelected}>
            <View style={genericStyles.rowWithCenter}>
              <CheckIconContainer>{isSelected && <CustomIcon name="check" />}</CheckIconContainer>
              <NameContainer>
                <CustomText font="bodyMedium" size={16}>
                  {name} ({yearGroup})
                </CustomText>
              </NameContainer>
            </View>

            <View style={genericStyles.row}>
              {sendNotes && (
                <IconContainer>
                  <SendNotesIcon width={10} />
                </IconContainer>
              )}
              {medicalNotes && (
                <IconContainer>
                  <MedicalIssuesIcon width={14} />
                </IconContainer>
              )}
              {!photographyAllowed && (
                <IconContainer>
                  <NoPhotographyIcon width={14} />
                </IconContainer>
              )}
              {!presenceStatus && consentStatus === 1 && (
                <IconContainer>
                  <NotPresentIcon width={14} />
                </IconContainer>
              )}
              {instructorNotes?.length && (
                <IconContainer>
                  <NoteFromTeacherIcon width={14} />
                </IconContainer>
              )}
              {consentInformation?.cannotRideFlag && (
                <IconContainer>
                  <RiderCannotRideIcon width={14} />
                </IconContainer>
              )}
              {hasBike === 0 && (
                <IconContainer>
                  <DontHasBikeIcon width={14} />
                </IconContainer>
              )}
            </View>
          </DetailContainer>

          <SpacerRow size={1} />

          <View>
            <ConsentIcon />
          </View>
        </Animated.View>
      </PanGestureHandler>
    </Animated.View>
  );
};

const DetailContainer = styled.Pressable<{ isSelected?: boolean }>(
  ({ theme: { colors }, isSelected }) => ({
    ...genericStyles.rowWithSB,
    flex: 1,
    backgroundColor: isSelected ? colors.secondary : colors.cornflowerBlue,
  }),
);

const NameContainer = styled.View(({ theme: { layout } }) => ({
  paddingVertical: layout.padding_x1,
  paddingHorizontal: layout.padding_x2,
}));

const IconContainer = styled.View(({ theme: { layout } }) => ({
  marginRight: layout.padding_x0_5,
}));

const ActionButtonContainer = styled(Animated.View)<{ type: 'absent' | 'present' }>(
  ({ theme: { layout }, type }) => ({
    position: 'absolute',
    right: type === 'absent' ? layout.padding_x1 : undefined,
    left: type === 'present' ? layout.padding_x1 : undefined,
    justifyContent: 'center',
    alignItems: 'center',
    ...genericStyles.rowWithCenter,
  }),
);

const CheckIconContainer = styled.View({
  width: 20,
  minHeight: 1,
  paddingLeft: 4,
});
