// libraries
import React from 'react';
import styled from 'styled-components/native';

// components
import { CustomText } from './CustomText';

// misc
import { genericStyles } from '@styles/genericStyles';

// types
export type BadgeProps = {
  count: number;
};

const SIZE = 22;

export const Badge: React.FC<BadgeProps> = ({ count }) => {
  // renders
  return (
    <Container>
      <CustomText color="white" size={12}>
        {count}
      </CustomText>
    </Container>
  );
};

const Container = styled.View(({ theme }) => ({
  ...genericStyles.jcAiCenter,
  backgroundColor: theme.colors.error,
  height: SIZE,
  width: SIZE,
  position: 'absolute',
  right: -5,
  top: -5,
  zIndex: 1000,
  borderRadius: SIZE / 2,
}));
