// libraries
import React, { useCallback, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';
import styled from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useForm } from 'react-hook-form';

// components
import {
  AnimationExpand,
  CustomIcon,
  CustomText,
  DivRow,
  Input,
  SpacerColumn,
  SpacerRow,
} from '@components/atoms';
import { DeliveryItem } from './components/DeliveryItem';

// misc
import { layout } from '@styles/layout';
import { ColorPalette } from '@styles/types';
import { TabNavigatorParamList } from '@utils/navigation';
import { useAppSelector } from '@redux/store';
import { DELIVERY_STATUS } from '@utils/deliveries';
import { flexSearch } from '@utils/text';
import { formatDateToSimple } from '@utils/date';

export const DeliveriesScreen = () => {
  // variables
  const { t } = useTranslation();
  const { navigate } = useNavigation<NativeStackNavigationProp<TabNavigatorParamList>>();
  const allDeliveries = useAppSelector(state => state.deliveries.apiData);
  const [isSearchVisible, toggleIsSearchVisible] = useReducer(value => !value, false);
  const { control, watch } = useForm<{ searchText: string }>();

  const searchText = watch('searchText');
  const deliveries = useMemo(
    () =>
      allDeliveries?.deliveryData.filter(delivery =>
        flexSearch(delivery.deliveries.hostName, searchText),
      ),
    [searchText, allDeliveries],
  );

  // returns
  const renderHeader = useCallback(
    () => (
      <>
        <Header>
          <DivRow width="100%">
            <ColorBadge color="error" />
            <SpacerRow size={0.25} />
            <CustomText font="bodyBold" size={10}>
              {t('screens.deliveries.status.notStarted')}
            </CustomText>

            <SpacerRow size={0.5} />

            <ColorBadge color="warning" />
            <SpacerRow size={0.25} />
            <CustomText font="bodyBold" size={10}>
              {t('screens.deliveries.status.inProgress')}
            </CustomText>

            <SpacerRow size={0.5} />

            {/* <ColorBadge color="success" />
            <SpacerRow size={0.25} />
            <CustomText font="bodyBold" size={10}>
              {t('screens.deliveries.status.completed')}
            </CustomText> */}
          </DivRow>

          <DivRow>
            <CustomIcon name="search" color="text" onPress={toggleIsSearchVisible} />
            {/* <SpacerRow size={1} />
            <CustomIcon type="material" name="filter-list" color="text" size={30} /> */}
          </DivRow>
        </Header>

        {isSearchVisible && (
          <AnimationExpand>
            <Input
              control={control}
              name="searchText"
              title=""
              placeHolder="Enter your search text here"
              containerStyle={{ marginBottom: layout.padding_x2 }}
            />
          </AnimationExpand>
        )}
      </>
    ),
    [isSearchVisible],
  );
  console.log('deliveries: ', deliveries);
  return (
    <Container>
      <FlatList
        data={deliveries}
        ListHeaderComponent={renderHeader}
        renderItem={({ item }) => (
          <DeliveryItem
            title={`${item.deliveries.hostName} - ${formatDateToSimple(item.deliveries.startDate)}`}
            onPress={() =>
              navigate('home', {
                screen: 'deliverySummary',
                params: { deliveryId: item.deliveries.id },
              })
            }
            status={DELIVERY_STATUS[item.deliveries.status]}
            courses={item.courses
              .map(function (item) {
                return item['courseLabelShortName'];
              })
              .join(', ')}
          />
        )}
        keyExtractor={item => item.deliveries.hostName}
        contentContainerStyle={{ padding: layout.contentPadding }}
        ItemSeparatorComponent={() => <SpacerColumn size={1} />}
      />
    </Container>
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));

const Header = styled.View(({ theme: { layout } }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  paddingBottom: layout.padding_x1,
}));

const ColorBadge = styled.View<{ color: ColorPalette }>(({ theme: { colors }, color }) => ({
  backgroundColor: colors[color],
  width: 25,
  height: 25,
  borderWidth: 2,
  borderColor: colors.mercury,
}));
