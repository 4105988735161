// libraries
import 'react-native-gesture-handler';
import '@localization/i18n';
import * as React from 'react';
import { useFonts } from 'expo-font';
import { SheetProvider } from 'react-native-actions-sheet';
import { ThemeProvider } from 'styled-components/native';
import { createNavigationContainerRef, NavigationContainer } from '@react-navigation/native';
import * as serviceWorkerRegistration from './src/serviceWorkerRegistration';
import FlashMessage from 'react-native-flash-message';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Platform } from 'react-native';
import { ConfirmModalProvider } from '@sj-distributor/react-native-confirm-modal';
import RootNavigator from '@navigation/RootNavigator';
import { PortalProvider } from '@gorhom/portal';
import { Provider } from 'react-redux';

// misc
import { theme } from '@styles/themes';
import { UserProvider } from '@context/UserProvider';
import { linking, RootStackParamList } from '@utils/navigation';
import { store } from '@redux/store';
import { genericStyles } from '@styles/genericStyles';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';

// FIXME need reanimated update, see https://github.com/software-mansion/react-native-reanimated/issues/3355
// @ts-ignore
if (typeof window !== 'undefined') {
  // @ts-ignore
  window._frameTimestamp = null;
}

export const navigationRef = createNavigationContainerRef<RootStackParamList>();

const queryClient = new QueryClient();
const App = () => {
  if (navigator.userAgent.indexOf('Firefox') != -1) {
    alert('Please use Chrome, Safari or Edge');
    return;
  }

  // variables
  const [fontsLoaded] = useFonts({
    Medel: require('./src/assets/fonts/Medel.ttf'),
    'OpenSans-Bold': require('./src/assets/fonts/OpenSans-Bold.ttf'),
    'OpenSans-Medium': require('./src/assets/fonts/OpenSans-Medium.ttf'),
    'OpenSans-Regular': require('./src/assets/fonts/OpenSans-Regular.ttf'),
    'OpenSans-Light': require('./src/assets/fonts/OpenSans-Light.ttf'),
  });

  // returns
  if (!fontsLoaded) {
    return null;
  }

  return (
    <ConfirmModalProvider>
      <PortalProvider>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <UserProvider>
              <NavigationContainer ref={navigationRef} linking={linking}>
                <SheetProvider>
                  <Provider store={store}>
                    <SafeAreaProvider>
                      <SafeAreaView style={genericStyles.fill}>
                        <RootNavigator />
                      </SafeAreaView>
                    </SafeAreaProvider>

                    {/* GLOBAL FLASH MESSAGE COMPONENT INSTANCE */}
                    <FlashMessage
                      position="top"
                      // statusBarHeight={Platform.OS === 'web' ? 0 : undefined}
                    />
                  </Provider>
                </SheetProvider>
              </NavigationContainer>
            </UserProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </PortalProvider>
    </ConfirmModalProvider>
  );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
export default App;
