// libraries
import { ViewStyle } from 'react-native';
import styled from 'styled-components/native';

interface CommonProps extends ViewStyle {
  jc?: ViewStyle['justifyContent'];
  ai?: ViewStyle['alignItems'];
  pv?: number;
  ph?: number;
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
}

const DivRow = styled.View<CommonProps>(
  ({ jc, ai, flex, height, pv, ph, pt, pb, pr, pl, theme: { layout } }) => ({
    paddingHorizontal: ph * layout.padding_x1,
    paddingVertical: pv * layout.padding_x1,
    paddingTop: pt * layout.padding_x1,
    paddingBottom: pb * layout.padding_x1,
    paddingRight: pr * layout.padding_x1,
    paddingLeft: pl * layout.padding_x1,
    flexDirection: 'row',
    justifyContent: jc,
    alignItems: ai || 'center',
    flex,
    height,
  }),
);

const DivColumn = styled.View<CommonProps>(
  ({ jc, ai, flex, height, pv, ph, pt, pb, pr, pl, theme: { layout } }) => ({
    paddingHorizontal: ph * layout.padding_x1,
    paddingVertical: pv * layout.padding_x1,
    paddingTop: pt * layout.padding_x1,
    paddingBottom: pb * layout.padding_x1,
    paddingRight: pr * layout.padding_x1,
    paddingLeft: pl * layout.padding_x1,
    flexDirection: 'column',
    justifyContent: jc,
    alignItems: ai,
    flex,
    height,
  }),
);

export { DivRow, DivColumn };
