// libraries
import { DivRow, Logo, SpacerRow } from '@components/atoms';
import { ATELogo } from '@components/atoms/ATELogo';
import React from 'react';

export const RightIcons = () => {
  // renders
  return (
    <DivRow jc="center" ai="center">
      <Logo size="small" />
      <SpacerRow size={0.5} />
      <ATELogo size="small" />
    </DivRow>
  );
};
