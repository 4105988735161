// libraries
import React, { useReducer } from 'react';
import { FlatList } from 'react-native';
import styled from 'styled-components/native';
import { RouteProp, useRoute } from '@react-navigation/native';

// components
import { CustomText, DivColumn, SmallButton, SpacerColumn } from '@components/atoms';
import { AddInstructorsNotesModal } from '@components/organisms';

// misc
import { layout } from '@styles/layout';
import { DashboardStackParamList } from '@utils/navigation';
import { useAppSelector } from '@redux/store';
import { selectInstructorNotesById, selectRiderById } from '@screens/dashboard/selectors';
import { formatDateToSimple } from '@utils/date';
import { useGetDeliveryCourseAndRiders } from '@hooks/useGetDeliveryCourseAndRiders';
import { genericStyles } from '@styles/genericStyles';

export const RiderInstructorNotesScreen = () => {
  // variables
  const {
    params: { deliveryId, riderId },
  } = useRoute<RouteProp<DashboardStackParamList, 'riderInstructorNotes'>>();
  const [isInstructorNotesVisible, toggleInstructorNotes] = useReducer(value => !value, false);
  const { course } = useGetDeliveryCourseAndRiders();
  const rider = useAppSelector(state => selectRiderById(state, deliveryId, riderId));
  const instructorNotes = useAppSelector(state =>
    selectInstructorNotesById(state, deliveryId, rider?.id),
  );

  // returns
  return (
    <Container>
      <FlatList
        data={instructorNotes}
        renderItem={({ item }) => (
          <DetailSectionContainer>
            <CustomText>
              {formatDateToSimple(item.datetime, '.', null)} - {item.instructorName}
            </CustomText>
            <CustomText>{item.note_text}</CustomText>
          </DetailSectionContainer>
        )}
        ItemSeparatorComponent={() => <SpacerColumn size={4} />}
        ListFooterComponent={() => <SpacerColumn size={6} />}
        ListHeaderComponent={() => (
          <>
            <SpacerColumn size={1.5} />

            <DivColumn jc="center" ai="center">
              <CustomText color="warning" font="bodyBold" size={24}>
                {course.courseLabelShortName} Rider Progress
              </CustomText>

              <SpacerColumn size={1.5} />
              <CustomText color="text" font="bodyBold" size={18}>
                {rider?.name}
              </CustomText>
            </DivColumn>

            <SpacerColumn size={3} />
          </>
        )}
        contentContainerStyle={{ padding: layout.padding_x2, flex: 1 }}
      />
      <Footer>
        <SmallButton title="Add" onPress={toggleInstructorNotes} />
      </Footer>

      <AddInstructorsNotesModal
        onClose={toggleInstructorNotes}
        isVisible={isInstructorNotesVisible}
        selectedRiderId={riderId}
      />
    </Container>
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));

const DetailSectionContainer = styled.View(() => ({}));
const Footer = styled.View(({ theme: { layout } }) => ({
  ...genericStyles.jcAiCenter,
  position: 'absolute',
  bottom: layout.padding_x2,
  width: '100%',
}));
