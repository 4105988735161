// libraries
import React from 'react';
import styled from 'styled-components/native';

// components
import { BigButton, DivColumn, Heading, SimpleButton, SpacerColumn } from '@components/atoms';

// misc
import { genericStyles } from '@styles/genericStyles';
import { useUser } from '@context/UserProvider';
import { useAppSelector } from '@redux/store';
import { selectSystemDataDeliveries } from '@screens/dashboard/selectors';

export const AppHelpScreen = () => {
  // variables
  const { user } = useUser();
  const systemData = useAppSelector(selectSystemDataDeliveries);

  // returns
  return (
    <Container>
      <Heading title={`Hello ${user?.first_name}, this is your admin area`} />

      <Content>
        <DivColumn>
          <SimpleButton title="Link Admin" />
          <SpacerColumn size={1} />
          <SimpleButton title="App Updates" />
          <SpacerColumn size={1} />
          <SimpleButton title="Help pages for app functions" />
        </DivColumn>

        <DivColumn>
          <BigButton text="Raise a technical issues" onPress={() => alert('coming soon...')} />
          <SpacerColumn size={4} />
          <Heading title={`Last date update: ${systemData.lastDataUpdate}`} />
          <SpacerColumn size={1} />
          <Heading title={`App Version No ${systemData.appVersion}`} />
        </DivColumn>
      </Content>
    </Container>
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));

const Content = styled.View(({ theme: { layout } }) => ({
  ...genericStyles.fill,
  ...genericStyles.jcSb,
  padding: layout.contentPadding,
}));
