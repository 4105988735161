// libraries
import React, { ReactElement, useEffect, useLayoutEffect } from 'react';
import { useTheme } from 'styled-components/native';
import { createStackNavigator } from '@react-navigation/stack';

// components
import { ChangePinScreen, ForgotPasswordScreen } from '@screens/authentication';
import { RightIcons } from '@navigation/components';

// misc
import { AccountStackParamList, RouteItem, TabNavigatorParamList } from '@utils/navigation';
import { fonts } from '@styles/fonts';
import { useUser } from '@context/UserProvider';
import { usePopulateDelivery } from '@hooks/usePopulateDelivery';
import { RouteProp, getFocusedRouteNameFromRoute, useRoute } from '@react-navigation/native';
import { MyAccountScreen } from '@screens/account';

const Stack = createStackNavigator<AccountStackParamList>();

const MyAccountItems: RouteItem<AccountStackParamList>[] = [
  {
    name: 'account',
    component: MyAccountScreen,
    title: 'My Account',
  },
  {
    name: 'changePin',
    component: ChangePinScreen,
    title: 'Change Pin',
  },
  {
    name: 'changePassword',
    component: ForgotPasswordScreen,
    title: 'Change Password',
  },
];

const MyAccountStacks: React.FC<{
  route: RouteProp<TabNavigatorParamList, 'account'>;
  navigation: any;
}> = ({ route }) => {
  // variables
  const { colors, layout } = useTheme();
  const { initLoading } = useUser();
  const { name: name2 } = useRoute();

  usePopulateDelivery();

  // returns
  if (initLoading) {
    return null;
  }

  return (
    <Stack.Navigator
      screenOptions={({ route }) => {
        return {
          headerShadowVisible: false,
          headerStyle: {
            backgroundColor: colors.secondary,
          },
          headerLeftContainerStyle: {
            paddingLeft: layout.padding_x1_5,
          },
          headerRightContainerStyle: {
            paddingRight: layout.padding_x1_5,
          },
          headerTitleStyle: {
            fontFamily: fonts.family.bodyBold,
            fontSize: 20,
          },
          headerTitleAlign: 'center',
          animationEnabled: true,
        };
      }}>
      {MyAccountItems.map(item => (
        <Stack.Screen
          key={item.name}
          name={item.name}
          component={item.component}
          options={{
            title: item.title,
            headerRight: () => <RightIcons />,
          }}
        />
      ))}
    </Stack.Navigator>
  );
};

export default MyAccountStacks;
