// libraries
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { FlatList, View } from 'react-native';
import styled from 'styled-components/native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import * as Animatable from 'react-native-animatable';

// components
import {
  CustomText,
  DivRow,
  Heading,
  SmallButton,
  SpacerColumn,
  SpacerRow,
} from '@components/atoms';
import {
  ActivitesCompleteModal,
  ActivitiesModal,
  BikeAndKitModal,
  RiderWithdrawnModal,
  SORTING_TYPE,
} from '@components/organisms';
import { RiderTrainingItem } from './components/RiderTrainingItem';
import { RiderHeaderTools } from './components/RiderHeaderTools';
import { TrainingFooterButton } from './components/TrainingFooterButton';
import { TrainingOptionModal } from './components/TrainingOptionModal';

// misc
import { layout } from '@styles/layout';
import { DashboardStackParamList } from '@utils/navigation';
import { genericStyles } from '@styles/genericStyles';
import { DeliveryDatumRider } from '@screens/dashboard/types';
import { useGetDeliveryCourseAndRiders } from '@hooks/useGetDeliveryCourseAndRiders';
import { useSubmitSavedDelivery } from '@hooks/useSubmitSavedDelivery';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectInstructorNotesByRiders } from '@screens/dashboard/selectors';
import { flexSearch } from '@utils/text';

export const TrainingScreen = () => {
  // variables
  const [trainingRider, setTrainingRider] = useState<DeliveryDatumRider>();
  const [isTrainingVisible, toggleTraining] = useReducer(
    value => !value,
    localStorage.getItem('showActivity') === 'true' ? true : false,
  );
  const [isTrainingOptionsVisible, toggleTrainingOptions] = useReducer(value => !value, false);
  const [isKitCheckVisible, toggleKitCheck] = useReducer(value => !value, false);
  const [kitCheckRider, setKitCheckRider] = useState<DeliveryDatumRider>();
  const [withdrawnRider, setWithdrawnRider] = useState<DeliveryDatumRider>();

  if (localStorage.getItem('showActivity') === 'true') {
    localStorage.removeItem('showActivity');
    if (!isTrainingVisible) {
      toggleTraining();
    }
  }

  const [showActivitiesCompleteModal, toggleActivitesCompleteModal] = useReducer(
    value => !value,
    false,
  );
  const {
    params: { deliveryId, courseId },
  } = useRoute<RouteProp<DashboardStackParamList, 'training'>>();
  const { setOptions, replace, navigate } =
    useNavigation<NativeStackNavigationProp<DashboardStackParamList>>();
  const { riders, delivery, course, riderIds } = useGetDeliveryCourseAndRiders();
  const [searchText, setSearchText] = useState('');
  const [sorting, setSorting] = useState<SORTING_TYPE>(null);

  const searchedRiders = useMemo(() => {
    const filteredRiders = riders.filter(rider => flexSearch(rider.name, searchText));

    if (sorting === 'YEAR_GROUP') {
      filteredRiders.sort((a, b) => {
        if (a.yearGroup < b.yearGroup) {
          return -1;
        }
        if (a.yearGroup > b.yearGroup) {
          return 1;
        }
        return 0;
      });
    } else if (sorting === 'A-TO-Z') {
      filteredRiders.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    }

    return filteredRiders;
  }, [searchText, riders, sorting]);

  const instructorNoters = useAppSelector(state =>
    selectInstructorNotesByRiders(state, delivery.id, riderIds),
  );

  const { submit, isLoading } = useSubmitSavedDelivery('Activites updated successfully!', {
    onSuccess: () => {
      toggleActivitesCompleteModal();
      isTrainingVisible && toggleTraining();
      setTrainingRider(null);
    },
  });

  // hooks
  useEffect(() => {
    if (course) {
      setOptions({ title: `${course.courseLabelShortName} Training` });
    }
  }, []);

  // functions
  const onCompleteActivites = () => {
    submit();
  };

  const onCompleteTraining = () => {};

  // returns
  const renderHeader = useCallback(
    () => (
      <RiderHeaderTools
        RenderHeader={() => (
          <View style={genericStyles.rowWithCenterAndSB}>
            <CustomText color="primary" size={20} font="bodyBold">
              My Riders
            </CustomText>

            <DivRow>
              <SmallButton
                title="Change"
                onPress={() =>
                  replace('allRiders', {
                    deliveryId,
                    courseId,
                  })
                }
              />
              <SpacerRow size={0.5} />
              <SmallButton
                title="Clear"
                onPress={() =>
                  replace('allRiders', {
                    deliveryId,
                    courseId,
                    isClearRiders: true,
                  })
                }
              />
            </DivRow>
          </View>
        )}
        onChangeSearchText={setSearchText}
        onSortingChange={settings => setSorting(settings['category_id'])}
      />
    ),
    [],
  );

  return (
    <Container>
      <Heading title={delivery.hostName} status={delivery.status} showSchoolIcon />

      <Content>
        <FlatList
          data={searchedRiders}
          ListHeaderComponent={renderHeader}
          renderItem={({ item }) => (
            <RiderTrainingItem
              courseShortName={course.courseLabelShortName}
              onPressTraining={() => setTrainingRider(item)}
              onPressInstructorFeedback={() =>
                navigate('instructorFeedback', {
                  deliveryId,
                  courseId,
                  riderId: item.id,
                })
              }
              onPressBikeAndKitCheck={() => setKitCheckRider(item)}
              onPressRiderWithdrawn={() => setWithdrawnRider(item)}
              onPressRidersDetails={() => navigate('riderDetail', { riderId: item.id, deliveryId })}
              {...item}
            />
          )}
          keyExtractor={item => item.id.toString()}
          ItemSeparatorComponent={() => <SpacerColumn size={1} />}
          contentContainerStyle={{
            paddingHorizontal: layout.padding_x1,
            paddingVertical: layout.padding_x2,
          }}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        />
      </Content>

      <Footer>
        <Animatable.View animation="pulse" easing="ease-in" iterationCount={5}>
          <TrainingFooterButton
            type="notes"
            onPress={() => navigate('instructorNotes', { courseId, deliveryId })}
            badgeCount={
              Object.values(instructorNoters)?.reduce((prev, cur) => [...prev, ...cur.notes], [])
                ?.length || 0
            }
          />
        </Animatable.View>
        <Animatable.View animation="pulse" easing="ease-in" iterationCount={5}>
          <TrainingFooterButton type="training" onPress={toggleTraining} />
        </Animatable.View>
        <Animatable.View animation="pulse" easing="ease-in" iterationCount={5}>
          <TrainingFooterButton
            type="survey"
            onPress={() => navigate('trainingSurvey', { courseId, deliveryId })}
          />
        </Animatable.View>
        <Animatable.View animation="pulse" easing="ease-in" iterationCount={5}>
          <TrainingFooterButton
            type="feedback"
            onPress={() => navigate('instructorFeedback', { deliveryId, courseId })}
          />
        </Animatable.View>
        <Animatable.View animation="pulse" easing="ease-in" iterationCount={5}>
          {/* <TrainingFooterButton type="check-complete" onPress={toggleTrainingOptions} /> */}
          <TrainingFooterButton
            type="check-complete"
            onPress={() => {
              navigate('home', {
                screen: 'trainingChecklist',
                params: { deliveryId: deliveryId, courseId: courseId },
              });
            }}
          />
        </Animatable.View>
      </Footer>

      <ActivitiesModal
        isVisible={isTrainingVisible || !!trainingRider}
        course={course}
        color={course.courseColour}
        selectedRider={trainingRider}
        onComplete={onCompleteActivites}
        onClose={trainingRider ? () => setTrainingRider(undefined) : toggleTraining}
        isLoading={isLoading}
      />

      <ActivitesCompleteModal
        isVisible={showActivitiesCompleteModal}
        color={course.courseColour}
        onClose={toggleActivitesCompleteModal}
        onRestartTraining={toggleTraining}
      />

      <BikeAndKitModal
        isVisible={!!kitCheckRider || isKitCheckVisible}
        onClose={() => {
          if (kitCheckRider) {
            setKitCheckRider(undefined);
          } else {
            toggleKitCheck();
          }
        }}
        riders={kitCheckRider ? [kitCheckRider] : riders}
        deliveryId={deliveryId}
      />

      <RiderWithdrawnModal
        isVisible={!!withdrawnRider}
        onClose={() => setWithdrawnRider(undefined)}
        rider={withdrawnRider}
      />

      <TrainingOptionModal
        color={course.courseColour}
        isVisible={isTrainingOptionsVisible}
        onClose={toggleTrainingOptions}
        onStartTraining={() => {
          toggleTrainingOptions();
          toggleTraining();
        }}
        onTrainingChecklist={() => {
          navigate('home', {
            screen: 'trainingChecklist',
            params: { deliveryId: deliveryId, courseId: courseId },
          });
          toggleTrainingOptions();
        }}
        onCompleteTraining={onCompleteTraining}
      />
    </Container>
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));

const Content = styled.View(() => ({
  flex: 1,
}));

const Footer = styled.View({
  ...genericStyles.rowEvenly,
});
