// libraries
import { useEffect } from 'react';
import { showMessage } from 'react-native-flash-message';
import { useNetInfo } from '@react-native-community/netinfo';

// misc
import cache from '@utils/cache';
import {
  DELIVERY_POST_SUBMIT_KEY,
  DELIVERY_SAVE_KEY,
  DELIVERY_SELECTED_RIDERS_KEY,
} from '@utils/keys';
import { DeliverySaveBody } from '@api/types';
import { useSaveDelivery } from '@api/useSaveDelivery';
import { useUser } from '@context/UserProvider';
import { useAppDispatch } from '@redux/store';
import { deliveriesSlice } from '@screens/dashboard/slice';
import { DeliveryState } from '@screens/dashboard/types';

export const useSyncApp = () => {
  // variables
  const { user } = useUser();
  const dispatch = useAppDispatch();
  const { mutate, isLoading } = useSaveDelivery(null, {
    onSuccess: () => {
      showMessage({
        message: 'App sync successfully!',
        type: 'success',
        duration: 3000,
      });
      cache.remove(DELIVERY_POST_SUBMIT_KEY);
    },
  });

  const { isConnected } = useNetInfo();

  // hooks
  useEffect(() => {
    syncPost();
  }, [isConnected]);

  useEffect(() => {
    if (user) {
      syncGet();
    }
  }, [user]);

  // functions
  const syncPost = async () => {
    const cachedDelivery = await cache.get(DELIVERY_POST_SUBMIT_KEY);

    if (isConnected && cachedDelivery) {
      mutate({ delivery: cachedDelivery as DeliverySaveBody });
      showMessage({
        message: 'You have some queue data. App sync has started!',
        type: 'info',
      });
    }
  };

  const syncGet = async () => {
    const cachedDeliveries = await cache.get(DELIVERY_SAVE_KEY);
    const selectedRiders = await cache.get(DELIVERY_SELECTED_RIDERS_KEY);

    if (cachedDeliveries) {
      dispatch(
        deliveriesSlice.actions.setSavedDeliveries(
          cachedDeliveries as DeliveryState['savedDeliveries'],
        ),
      );
    }

    if (selectedRiders) {
      dispatch(
        deliveriesSlice.actions.setSelectedRiders(
          selectedRiders as DeliveryState['selectedRiders'],
        ),
      );
    }
  };

  return { isLoading };
};
