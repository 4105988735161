import React, { useEffect } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Animated, {
  Easing,
  cancelAnimation,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';

interface AnimationRotateLoopProps {
  isRunning?: boolean;
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}

export const AnimationRotateLoop: React.FC<AnimationRotateLoopProps> = ({
  isRunning = true,
  children,
}) => {
  // variables
  const rotation = useSharedValue(0);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotateZ: `${rotation.value}deg`,
        },
      ],
    };
  }, [rotation.value]);

  // hooks
  useEffect(() => {
    if (isRunning) {
      rotation.value = withRepeat(
        withTiming(360, {
          duration: 1000,
          easing: Easing.linear,
        }),
        -1,
      );
    } else {
      cancelAnimation(rotation);
    }
    return () => cancelAnimation(rotation);
  }, [isRunning]);

  // renders
  return <Animated.View style={[animatedStyles]}>{children}</Animated.View>;
};
