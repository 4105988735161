// libraries
import { MMKV } from 'react-native-mmkv';
import CryptoJS from 'crypto-js';

// misc
import { SECRET_PIN_SALT } from '@env';
import { PIN_STORAGE_KEY } from './keys';

export const setSecretPin = (pin: string) => {
  const pinStorage = new MMKV({
    id: `pin-storage`,
  });
  try {
    pinStorage.set(PIN_STORAGE_KEY, CryptoJS.AES.encrypt(pin, SECRET_PIN_SALT).toString());
  } catch (error) {
    console.log(error);
  }
};

export const getSecretPin = () => {
  const pinStorage = new MMKV({
    id: `pin-storage`,
  });

  try {
    const bytes = CryptoJS.AES.decrypt(
      pinStorage.getString(PIN_STORAGE_KEY) || '',
      SECRET_PIN_SALT,
    );
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  } catch (error) {
    console.log(error);
  }
};

export const secureStorage = (() => {
  try {
    return new MMKV({
      id: `api-storage`,
    });
  } catch (error) {
    console.log(error);
  }
})();
