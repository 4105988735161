// libraries
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { FlatList, StyleSheet } from 'react-native';
import { showMessage } from 'react-native-flash-message';

import { useSubmitSavedDelivery } from '@hooks/useSubmitSavedDelivery';

// components
import {
  CustomIcon,
  CustomPressable,
  CustomText,
  DivColumn,
  SmallButton,
  SpacerColumn,
} from '@components/atoms';
import { CollapsableSection, CustomModal } from '@components/molecules';

// misc
import { layout } from '@styles/layout';
import { genericStyles } from '@styles/genericStyles';
import { ActivityOutcome, Course, DeliveryDatumRider } from '@screens/dashboard/types';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { deliveriesSlice } from '@screens/dashboard/slice';
import { Activity } from '@api/types';
import { useGetDeliveryCourseAndRiders } from '@hooks/useGetDeliveryCourseAndRiders';
import { selectSavedRiders } from '@screens/dashboard/selectors';

// types
type OutcomeStateType = {
  [riderId: string]: {
    [activityId: number]: number;
  };
};

export type ActivitiesModalProps = {
  isVisible: boolean;
  isLoading: boolean;
  onClose(): void;
  color: string;
  course: Course;
  selectedRider?: DeliveryDatumRider;
  onComplete(): void;
};

type MultipleActivityListType = {
  activityId: string;
  activityLabel: string;
  activities: MultipleActivityType[];
};

type MultipleActivityType = {
  rider: DeliveryDatumRider;
  outcomes: ActivityOutcome[];
};

export const ALL_RIDERS_ID = 0;

export const ActivitiesModal: React.FC<ActivitiesModalProps> = ({
  isVisible,
  onClose,
  isLoading,
  color,
  selectedRider,
  onComplete,
}) => {
  // variables
  const { course, delivery, riders, riderIds } = useGetDeliveryCourseAndRiders();
  const { colors } = useTheme();
  const dispatch = useAppDispatch();
  const backgroundColor = colors.white;
  const [outcomeData, setOutcomeData] = useState<OutcomeStateType>({});
  const [openedTabId, setOpenedTabId] = useState<number | string>(0);
  const [isLoadNext, setIsLoadNext] = useState<boolean>(false);
  const { submit } = useSubmitSavedDelivery('Activites updated successfully!', {
    onSuccess: () => {
      setCurrentActivityIndex(prev => prev + 1);
      setIsLoadNext(false);
    },
  });
  const modifedRiders = useMemo(() => {
    if (riders?.length) {
      const allRider: DeliveryDatumRider = {
        ...riders[0],
        id: ALL_RIDERS_ID,
        name: 'All Riders',
      };

      return [allRider, ...riders];
    }
    return [];
  }, [course, riders]);

  const data = useMemo(() => {
    const tempData: MultipleActivityListType[] = course.courseActivities.map(activity => {
      return {
        activityId: activity.id,
        activityLabel: activity.activityLabel,
        activities: modifedRiders.map(rider => ({
          rider,
          outcomes: activity.activityOutcomes,
        })),
      };
    });

    return tempData;
  }, [course, modifedRiders]);

  const savedRiders = useAppSelector(state =>
    selectSavedRiders(state, delivery.id, [...(riderIds || []), ALL_RIDERS_ID]),
  );
  const [currentActivityIndex, setCurrentActivityIndex] = useState(0);
  const currentActivity = data[currentActivityIndex];

  // hooks
  useEffect(() => {
    if (Object.values(outcomeData)?.length) {
      for (const [riderId, outcome] of Object.entries(outcomeData)) {
        const outcomes: Activity[] = [];
        for (const [activityId, outcomeId] of Object.entries(outcome)) {
          outcomes.push({ id: activityId, outcome: outcomeId });
        }

        dispatch(
          deliveriesSlice.actions.setSavedRiderCourse({
            riderId: parseInt(riderId),
            courseId: course.id,
            deliveryId: delivery.id,
            activities: outcomes,
          }),
        );
      }
    }
  }, [JSON.stringify(outcomeData)]);

  useEffect(() => {
    if (Object.values(outcomeData)?.length === 0) {
      populateData();
    }
  }, [savedRiders]);

  useEffect(() => {
    setCurrentActivityIndex(0);
  }, [isVisible]);

  // functions
  const populateData = () => {
    const outcomes: OutcomeStateType = {};

    savedRiders?.forEach(rider => {
      const selectedCourse = rider.courses?.find(c => c.id === course.id);
      if (selectedCourse && selectedCourse.activities?.length) {
        const out = {
          ...(outcomes[rider.rider_id] || {}),
          ...selectedCourse.activities.reduce(
            (prev, cur) => ({ ...prev, [cur.id]: cur.outcome }),
            {},
          ),
        };
        outcomes[rider.rider_id] = out;
      }
    });

    if (Object.values(outcomes)?.length !== 0) {
      setOutcomeData(outcomes);
    }
  };

  const onPressOutcome = (activityId: string, riderId: number, outcomeId: number) => {
    if (riderId === ALL_RIDERS_ID) {
      onPressAllRiders(activityId, outcomeId);
      return;
    }

    const tempActivityData = { ...outcomeData };
    tempActivityData[riderId] = {
      ...(tempActivityData[riderId] || {}),
      [activityId]: outcomeId,
    };

    setOutcomeData({ ...tempActivityData });
  };

  const onPressAllRiders = (activityId: string, outcomeId: number) => {
    const tempActivityData = { ...outcomeData };
    modifedRiders.forEach(r => {
      tempActivityData[r.id] = {
        ...(tempActivityData[r.id] || {}),
        [activityId]: outcomeId,
      };
    });

    setOutcomeData(tempActivityData);
  };

  const onPressSkip = () => {
    if (currentActivityIndex < data.length - 1) {
      setCurrentActivityIndex(prev => prev + 1);
    } else {
      onComplete();
    }
  };

  const onPressNext = () => {
    // todo: fix not allowing  it
    if (Object.keys(outcomeData || {}).length === modifedRiders.length) {
      setOpenedTabId(0);
      setIsLoadNext(true);
      submit();
    } else {
      showMessage({
        type: 'danger',
        message: 'Please select all the riders outcome first',
      });
    }
  };

  const onPressComplete = () => {
    onComplete();
  };

  // renders
  const ItemButton = ({
    title,
    onPress,
    isChecked,
  }: {
    title: string;
    onPress?(): void;
    isChecked: boolean;
  }) => (
    <CustomPressable
      onPress={onPress}
      pressableStyle={{
        ...styles.button,
        backgroundColor: isChecked ? color : backgroundColor,
        borderColor: color,
      }}>
      <CustomText textAlign="center" font="bodyBold">
        {title}
      </CustomText>

      {isChecked && (
        <IconContainer>
          <CustomIcon name="check" />
        </IconContainer>
      )}
    </CustomPressable>
  );

  const Header = useCallback(
    () => (
      <DivColumn jc="center" ai="center">
        {!selectedRider && (
          <>
            <SpacerColumn size={0.5} />
            <CustomText>
              {currentActivityIndex + 1} of {data?.length}
            </CustomText>
          </>
        )}
        <SpacerColumn size={0.5} />
        <CustomText font="bodyBold" size={16}>
          {selectedRider
            ? selectedRider.name
            : 'Task: ' + (currentActivity ? currentActivity.activityLabel : '')}
        </CustomText>

        <SpacerColumn size={2} />
      </DivColumn>
    ),
    [currentActivity, currentActivityIndex, selectedRider],
  );

  return (
    <CustomModal
      isVisible={isVisible}
      onClose={onClose}
      backdropColor={color}
      containerColor={backgroundColor}
      backdropOpacity={1}
      onBackdropPress={() => null}
      title={`${course.courseLabelShortName} Activities`}>
      <Container>
        <SpacerColumn size={2} />

        {selectedRider ? (
          <>
            <FlatList
              data={course.courseActivities}
              extraData={outcomeData}
              renderItem={({ item }) => (
                <CollapsableSection
                  title={item.activityLabel}
                  backgroundColor={color}
                  headerStyle={{ borderRadius: 6 }}
                  onPress={() => {
                    setOpenedTabId(item.id);
                  }}
                  isExpandedByDefault={item.id === openedTabId}>
                  <SpacerColumn size={1} />
                  {item.activityOutcomes.map(outcome => (
                    <ItemButton
                      key={outcome.id}
                      title={outcome.grade}
                      onPress={() => {
                        onPressOutcome(item.id, selectedRider.id, outcome.id);
                      }}
                      isChecked={
                        (outcomeData[selectedRider.id] &&
                          outcomeData[selectedRider.id][item.id] === outcome.id) ||
                        false
                      }
                    />
                  ))}
                </CollapsableSection>
              )}
              showsVerticalScrollIndicator={false}
              keyExtractor={item => item.id.toString()}
              ItemSeparatorComponent={() => <SpacerColumn size={1} />}
              contentContainerStyle={genericStyles.fill}
              ListHeaderComponent={Header}
            />

            <Footer>
              <DivColumn />
              <SmallButton onPress={onPressComplete} title={'Save'} />
            </Footer>
          </>
        ) : (
          <>
            <FlatList
              data={currentActivity?.activities}
              extraData={outcomeData}
              renderItem={({ item }) => (
                <CollapsableSection
                  title={item.rider.name}
                  backgroundColor={color}
                  headerStyle={{ borderRadius: 6 }}
                  onPress={() => {
                    setOpenedTabId(item.rider.id);
                  }}
                  isExpandedByDefault={item.rider.id === openedTabId}>
                  <SpacerColumn size={1} />
                  {item.outcomes.map(outcome => (
                    <ItemButton
                      key={outcome.id}
                      title={outcome.grade}
                      onPress={() => {
                        onPressOutcome(currentActivity?.activityId, item.rider.id, outcome.id);
                      }}
                      isChecked={
                        (outcomeData[item.rider?.id] &&
                          outcomeData[item.rider?.id][currentActivity?.activityId] ===
                            outcome.id) ||
                        false
                      }
                    />
                  ))}
                </CollapsableSection>
              )}
              showsVerticalScrollIndicator={false}
              keyExtractor={item => item.rider.id.toString()}
              ItemSeparatorComponent={() => <SpacerColumn size={1} />}
              contentContainerStyle={genericStyles.fill}
              ListHeaderComponent={Header}
            />

            <Footer>
              {currentActivityIndex ? (
                <SmallButton
                  onPress={() => setCurrentActivityIndex(currentActivityIndex - 1)}
                  title="Prev"
                />
              ) : (
                <DivColumn />
              )}
              <SmallButton onPress={onPressSkip} title="Skip" />
              <SmallButton
                onPress={currentActivityIndex === data.length - 1 ? onPressComplete : onPressNext}
                title={currentActivityIndex === data.length - 1 ? 'Complete' : 'Next'}
                isLoading={isLoading || isLoadNext}
              />
            </Footer>
          </>
        )}
      </Container>
    </CustomModal>
  );
};

const Container = styled.View(({ theme: { layout } }) => ({
  ...genericStyles.fill,
  paddingHorizontal: layout.padding_x1,
}));

const IconContainer = styled.View(({ theme: { layout } }) => ({
  position: 'absolute',
  right: layout.padding_x1,
}));

const Footer = styled.View(({ theme: { layout } }) => ({
  ...genericStyles.rowWithCenterAndSB,
  bottom: layout.padding_x1,
}));

const styles = StyleSheet.create({
  button: {
    borderRadius: 6,
    borderWidth: 1,
    marginVertical: layout.padding_x0_5,
    padding: layout.padding_x1,
  },
});
