// libraries
import React, { useState, useEffect } from 'react';
import styled from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import { Switch, Text } from 'react-native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useNetInfo } from '@react-native-community/netinfo';
import AsyncStorage from '@react-native-async-storage/async-storage';

// components
import { Heading, SimpleButton, SpacerColumn, DivRow } from '@components/atoms';

// misc
import { isOnline } from '@utils/connect';
import { AccountStackParamList } from '@utils/navigation';
import { useUser } from '@context/UserProvider';
import { useSubmitAllDeliveries } from '@hooks/useSubmitAllDeliveries';
import { useGetNews } from '@api/useGetNews';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { useGetInstructorDeliveries } from '@api/useGetInstructorDeliveries';
import { deliveriesSlice } from '../dashboard/slice';
import { APP_VERSION_KEY } from '@utils/keys';
import { clearCache } from '@utils/reload';
import alert from '@utils/alert';
import { showMessage } from 'react-native-flash-message';

export const MyAccountScreen = () => {
  // variables
  const { navigate } = useNavigation<NativeStackNavigationProp<AccountStackParamList>>();
  const { user, resetAuth } = useUser();
  const { isConnected } = useNetInfo();
  const [isRefreshInProgress, setIsRefreshInProgress] = useState(false);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const [isConnectSwitchOn, setIsConnectSwitchOn] = useState(localStorage.isConnect !== 'false');
  const { submit } = useSubmitAllDeliveries({
    onSuccess: () => {
      resetAuth();
      localStorage.clear();
    },
  });

  const { submitOfflineData } = useSubmitAllDeliveries({
    onStart: () => {
      setIsRefreshLoading(true);
    },
    onSuccess: () => {
      refetchNews();
      refetchDeliveries();
    },
  });

  const { data, isFetching: isFetchingNews, refetch: refetchNews } = useGetNews();
  const dispatch = useAppDispatch();
  const { isFetching: isFetchingDeliveries, refetch: refetchDeliveries } =
    useGetInstructorDeliveries({
      onSuccess: async data => {
        const currentAppVersion = await AsyncStorage.getItem(APP_VERSION_KEY);

        if (!currentAppVersion) {
          await AsyncStorage.setItem(APP_VERSION_KEY, data.SystemData.appVersion);
        } else if (data.SystemData.appVersion !== currentAppVersion) {
          alert(
            'App Update Available!',
            'New app version is available, we recommend to update it right away.',
            [
              {
                text: 'Update',
                onPress: async () => {
                  await AsyncStorage.setItem(APP_VERSION_KEY, data.SystemData.appVersion);
                  clearCache(true);
                  location.reload();
                },
              },
              {
                text: 'Cancel',
                onPress: () => console.log('Cancel Pressed'),
                style: 'cancel',
              },
            ],
          );
        }

        dispatch(deliveriesSlice.actions.setDeliveries({ data }));
      },
    });

  useEffect(() => {
    if (isFetchingDeliveries && isFetchingNews && isRefreshLoading) {
      setIsRefreshInProgress(true);
    } else if (isRefreshInProgress && !isFetchingDeliveries && !isFetchingNews) {
      setIsRefreshLoading(false);
      dispatch(deliveriesSlice.actions.clearSavedDeliveries());
      showMessage({
        type: 'info',
        message:
          'All your saved data of deliveries has been pushed to server and cleared from local storage.',
        duration: 3000,
      });
    }
  }, [isFetchingDeliveries, isFetchingNews, isRefreshInProgress, isRefreshLoading]);
  const toggleSwitch = () => {
    setIsConnectSwitchOn(previousState => !previousState);
    setTimeout(() => {
      localStorage.setItem('isConnect', '' + !isConnectSwitchOn);
    }, 100);
  };

  useEffect(() => {
    if (isConnectSwitchOn) {
      submitOfflineData();
    }
  }, [isConnectSwitchOn]);

  const onLogoutPress = () => {
    alert(
      'Are you sure?',
      'If you want to login in again quickly using your pin then simply close your browser window. If you want to do a complete logout and purge encrypted data from your device then click OK. You will need to 2FA authenticate upon re-login.',
      [
        {
          text: 'Logout',
          onPress: async () => {
            submit();
          },
        },
        {
          text: 'Cancel',
          style: 'cancel',
        },
      ],
    );
  };

  // returns
  return (
    <Container>
      <Heading title={`Hello ${user?.first_name}, this is your admin area`} />
      <Content>
        <SimpleButton title="Change Password" onPress={() => navigate('changePassword')} />
        <SpacerColumn size={1} />
        <SimpleButton
          title="Change Pin"
          onPress={() => {
            navigate('changePin');
          }}
        />
        <SpacerColumn size={1} />
        {/* {isOnline() && isConnected && <SimpleButton title="Log Out" onPress={onLogoutPress} />} */}
        {isConnected && <SimpleButton title="Log Out" onPress={onLogoutPress} />}
        <DivRow style={{ margin: '1em', marginLeft: 'auto', marginRight: 'auto' }}>
          <Text>Offline</Text>
          <Switch
            trackColor={{ false: '#767577', true: '#81b0ff' }}
            thumbColor={isConnectSwitchOn ? '#f5dd4b' : '#f4f3f4'}
            ios_backgroundColor="#3e3e3e"
            onValueChange={toggleSwitch}
            value={isConnectSwitchOn}
            style={{ marginLeft: '1em', marginRight: '1em' }}
          />
          <Text>Online</Text>
        </DivRow>
      </Content>
    </Container>
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));

const Content = styled.View(({ theme: { layout } }) => ({
  padding: layout.contentPadding,
}));
