// libraries
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { SectionList, StyleSheet } from 'react-native';
import styled from 'styled-components/native';
import { useForm } from 'react-hook-form';
import { RouteProp, useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';

// components
import { CustomText, DivColumn, SmallButton, SpacerColumn, SpacerRow } from '@components/atoms';
import { Checkbox, SelectInput } from '@components/molecules';
import { AddInstructorFeedbackModal } from '@components/organisms';

// misc
import { genericStyles } from '@styles/genericStyles';
import { layout } from '@styles/layout';
import { useGetDeliveryCourseAndRiders } from '@hooks/useGetDeliveryCourseAndRiders';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { deliveriesSlice } from '@screens/dashboard/slice';
import { InstructorFeedback } from '@api/types';
import { useUser } from '@context/UserProvider';
import { selectSavedDeliveryById } from '@screens/dashboard/selectors';
import { addSuffix, removeSuffix } from '@utils/text';
import { useSubmitSavedDelivery } from '@hooks/useSubmitSavedDelivery';
import { DashboardStackParamList } from '@utils/navigation';
import { CustomIcon } from '@components/atoms/CustomIcon';
import alert from '@utils/alert';

export type InstructoryFeedbackFormType = {
  riderId: string;
  feedbacks: { [id: string]: boolean };
  notes: string;
};

export const InstructorFeedbackScreen = ({ navigation }) => {
  // variables
  const buttonRef = useRef();
  const [showDownArrow, setShowDownArrow] = useState(true);
  const [saveFeedbackPress, setSaveFeedbackPress] = useState(false);
  const saveFeedbackPressRef = React.useRef(saveFeedbackPress);
  const refillRef = useRef<NodeJS.Timeout>(null);
  const dispatch = useAppDispatch();
  const [isInstructorFeedbackVisible, toggleIsInstructorFeedbackVisible] = useReducer(
    value => !value,
    false,
  );
  const { submit, isLoading } = useSubmitSavedDelivery('Instructor feedback updated successfully!');
  const { course, riders, delivery } = useGetDeliveryCourseAndRiders();
  const { user } = useUser();
  const savedDelivery = useAppSelector(state => selectSavedDeliveryById(state, delivery?.id));
  const {
    params: { riderId: selectedRiderId },
  } = useRoute<RouteProp<DashboardStackParamList, 'instructorFeedback'>>();

  const { control, setValue, watch, handleSubmit, getValues, trigger } =
    useForm<InstructoryFeedbackFormType>({
      mode: 'all',
      defaultValues: { riderId: selectedRiderId?.toString() || null },
    });
  const riderId = watch('riderId');
  const notes = watch('notes');
  const feedbacks = watch('feedbacks');
  const selectInputRiders = useMemo(() => {
    const allRiders = [{ label: 'All Riders', value: 'allrider' }];
    const rider = riders.map(r => ({ label: r.name, value: r.id.toString() }));
    return allRiders.concat(rider);
  }, [riders]);
  const feedbackData = useMemo(
    () =>
      course.instructorFeedback.map(feed => ({
        title: feed.headingLabel,
        data: feed.paragraphs.map(para => ({
          ...para,
          headingId: feed.headingId,
        })),
      })),
    [],
  );

  // hooks
  useEffect(() => {
    refillRef?.current && clearTimeout(refillRef.current);
    refillRef.current = setTimeout(() => {
      refill();
    }, 1000);
  }, [notes, JSON.stringify(feedbacks)]);

  useEffect(() => {
    setTimeout(() => {
      onScrollEnd();
    }, 1000);
  }, []);

  useEffect(() => {
    setValue('notes', '');
    setValue(
      'feedbacks',
      feedbackData.reduce(
        (prev, feed) => ({
          ...prev,
          ...feed.data.reduce(
            (prev2, back) => ({ ...prev2, [addSuffix(back.paragraphId)]: false }),
            {},
          ),
        }),
        {},
      ),
    );

    const instructorNotes = savedDelivery?.riders
      .find(rider => rider.rider_id === parseInt(riderId))
      ?.courses?.find(c => c.id === course.id)?.instructor_feedback;

    if (instructorNotes) {
      setValue('notes', instructorNotes.notes);
      instructorNotes?.paragraphs &&
        setValue(
          'feedbacks',
          (JSON.parse(instructorNotes.paragraphs) as string[])?.reduce(
            (prev, pragraphId) => ({ ...prev, [addSuffix(pragraphId)]: true }),
            {},
          ),
        );
    }
  }, [riderId]);

  // handle back button
  React.useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      const riderId = control._formValues.riderId;
      const note = control._formValues.notes;
      const feedback = control._formValues.feedbacks;
      const isAnyFeedbackTrue = Object.values(feedback).some(o => o === true);

      if (riderId !== null && (note !== '' || isAnyFeedbackTrue) && !saveFeedbackPressRef.current) {
        alert('Instructor Feedback', 'You have unsaved data, would you like to save it now?', [
          {
            text: 'Ok',
            onPress: () => {
              handleSubmit(onSubmit)();
            },
          },
          {
            text: 'Cancel',
            onPress: () => {},
            style: 'cancel',
          },
        ]);
      }
    });
    return unsubscribe;
  }, [navigation]);

  // functions
  const onSubmit = () => {
    saveFeedbackPressRef.current = true;
    setSaveFeedbackPress(true);
    submit();
  };

  const refill = () => {
    saveFeedbackPressRef.current = false;
    setSaveFeedbackPress(false);
    const paragraphs: string[] = [];

    const data = getValues();
    for (const [id, value] of Object.entries(data?.feedbacks || {})) {
      if (value) {
        paragraphs.push(removeSuffix(id));
      }
    }

    if (!riderId || !paragraphs) {
      return;
    }

    const instructorFeedback: InstructorFeedback = {
      instructor_id: user?.id,
      notes: data?.notes || '',
      paragraphs: JSON.stringify(paragraphs) || '',
    };

    if (riderId === 'allrider') {
      riders.map(rider => {
        const instructorNotes = savedDelivery?.riders
          .find(r => r.rider_id === rider.id)
          ?.courses?.find(c => c.id === course.id)?.instructor_feedback;

        const newFeedBack = { ...instructorNotes };
        if (instructorNotes && instructorNotes.paragraphs) {
          const oldFeedback = JSON.parse(instructorNotes.paragraphs) as string[];
          const paragraph = [...new Set([...oldFeedback, ...paragraphs])];
          newFeedBack.paragraphs = JSON.stringify(paragraph);
        }
        dispatch(
          deliveriesSlice.actions.setSavedRiderCourse({
            riderId: rider.id,
            deliveryId: delivery.id,
            courseId: course.id,
            feedback: newFeedBack.paragraphs ? newFeedBack : instructorFeedback,
          }),
        );
      });
    } else {
      dispatch(
        deliveriesSlice.actions.setSavedRiderCourse({
          riderId: parseInt(riderId),
          deliveryId: delivery.id,
          courseId: course.id,
          feedback: instructorFeedback,
        }),
      );
    }
  };

  const onScrollEnd = () => {
    const position = buttonRef.current?.getBoundingClientRect();
    if (position && position.y + 90 > window.innerHeight) {
      setShowDownArrow(true);
    } else {
      setShowDownArrow(false);
    }
  };

  // returns
  return (
    <Container>
      <SpacerColumn size={2} />
      <DivColumn style={styles.riderContainer}>
        <SelectInput
          name="riderId"
          control={control}
          data={selectInputRiders}
          onChangeValue={setValue}
          onChange={trigger}
          title=""
          placeHolder="Choose Rider"
          sheetTitle="Select rider"
          rules={{ required: true }}
        />
      </DivColumn>

      <SectionList
        sections={feedbackData}
        keyExtractor={(item, index) => item.headingId.toString() + index}
        renderSectionHeader={({ section }) => (
          <HeadingText font="bodyBold">{section.title}</HeadingText>
        )}
        onScroll={onScrollEnd}
        renderItem={({ item }) => (
          <Checkbox
            control={control}
            name={`feedbacks.${addSuffix(item.paragraphId)}`}
            text={item.paragraphText}
            defaultValue={false}
            containerStyle={styles.checkboxContainer}
            rules={{
              validate: () => {
                const values = Object.values(getValues('feedbacks')).filter(value => value);
                const notes = getValues('notes');
                if (values?.length === 0 && (notes === null || notes.length === 0)) {
                  return 'Check at least one feedback or add a note to continue';
                }
                return true;
              },
            }}
            errorStyle={{ fontSize: 10, textAlign: 'center' }}
            onChange={trigger}
          />
        )}
        ListFooterComponent={() => (
          <>
            <div ref={buttonRef} style={{ width: 'fit-content' }}>
              {' '}
            </div>
          </>
        )}
      />
      {showDownArrow && (
        <CustomText textAlign="right" font="bodyBold">
          Swipe down
          <CustomIcon
            name="chevron-down"
            color={'text'}
            size={20}
            style={{
              marginLeft: '5px',
              borderRadius: '50%',
              marginRight: '15px',
            }}
          />
        </CustomText>
      )}
      <Footer>
        <SmallButton
          title="Add Notes"
          disabled={riderId === 'allrider'}
          pressableStyle={genericStyles.fill}
          onPress={toggleIsInstructorFeedbackVisible}
        />
        <SpacerRow size={2} />
        <SmallButton
          title="Save Feedback"
          pressableStyle={genericStyles.fill}
          onPress={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </Footer>

      <AddInstructorFeedbackModal
        isVisible={isInstructorFeedbackVisible}
        control={control}
        rider={riders.find(r => r.id === parseInt(riderId))}
        onClose={toggleIsInstructorFeedbackVisible}
        onSubmit={handleSubmit(onSubmit)}
      />
    </Container>
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));

const HeadingText = styled(CustomText)(({ theme: { colors, layout } }) => ({
  backgroundColor: colors.primaryBackground,
  borderColor: colors.mercury,
  borderBottomWidth: 1,
  borderTopWidth: 1,
  paddingHorizontal: layout.padding_x2,
  paddingVertical: layout.padding_x1,
}));

const Footer = styled.View(({ theme: { layout } }) => ({
  ...genericStyles.rowWithCenterAndSB,
  paddingHorizontal: layout.padding_x2,
  paddingVertical: layout.padding_x1,
}));

const styles = StyleSheet.create({
  checkboxContainer: {
    ...genericStyles.rowWithSB,
    marginHorizontal: layout.padding_x2,
  },
  riderContainer: {
    width: '100%',
    paddingHorizontal: layout.padding_x2,
    paddingBottom: layout.padding_x1,
  },
});
