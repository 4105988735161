// libraries
import { showMessage } from 'react-native-flash-message';
import NetInfo, { useNetInfo } from '@react-native-community/netinfo';
// import { useNetInfo } from '@react-native-community/netinfo';

// misc
import { useSaveDelivery } from '@api/useSaveDelivery';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectSavedDeliveryById } from '@screens/dashboard/selectors';
import { useGetDeliveryCourseAndRiders } from './useGetDeliveryCourseAndRiders';
import { isOnline } from '@utils/connect';
import { DELIVERY_POST_SUBMIT_KEY } from '@utils/keys';
import cache from '@utils/cache';
import { deliveriesSlice } from '@screens/dashboard/slice';

export const useSubmitSavedDelivery = (
  successMessage: string,
  options?: { onSuccess?: () => void },
) => {
  const { delivery } = useGetDeliveryCourseAndRiders();
  const savedDelivery = useAppSelector(state => selectSavedDeliveryById(state, delivery?.id));
  const { isConnected } = useNetInfo();
  const dispatch = useAppDispatch();

  const { mutate, isLoading } = useSaveDelivery(savedDelivery, {
    onSuccess: () => {
      showMessage({
        message: successMessage,
        type: 'success',
        duration: 5000,
      });
      cache.remove(DELIVERY_POST_SUBMIT_KEY);
      options?.onSuccess && options.onSuccess();
      dispatch(deliveriesSlice.actions.clearCharacteristics({ deliveryId: savedDelivery.id }));
    },
  });

  const submit = async () => {
    NetInfo.fetch().then(state => {
      // if (state.isConnected) {
      if (isOnline() && state.isConnected) {
        mutate({ delivery: savedDelivery });
      } else {
        cache.store(DELIVERY_POST_SUBMIT_KEY, savedDelivery);

        showMessage({
          type: 'warning',
          message:
            "We have added your submit request to queue currently, it'll sync to server when you have working internet connection.",
          duration: 5000,
        });

        options?.onSuccess && options.onSuccess();
      }
    });
  };

  return { submit, isLoading };
};
