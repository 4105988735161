// libraries
import moment from 'moment';

// misc
import { secureStorage } from './secure-storage';
import { decrypt, encrypt } from './encryption';

const prefix = 'cache';
const expiryInDays = 30;

type ItemType = {
  value: object | string;
  timeStamp: number;
};

const store = async (key: string, value: ItemType['value']) => {
  const item: ItemType = {
    value,
    timeStamp: Date.now(),
  };

  try {
    secureStorage.set(prefix + key, encrypt(item));
  } catch (err) {
    console.log(err);
  }
};

const isExpired = (item: ItemType) => {
  const now = moment(Date.now());
  const storedTime = moment(item.timeStamp);
  return now.diff(storedTime, 'days') > expiryInDays;
};

const get = async (key: string) => {
  try {
    const value = secureStorage.getString(prefix + key);
    if (!value) {
      return null;
    }
    const item: ItemType = decrypt(value);

    if (!item) return null;

    if (isExpired(item)) {
      secureStorage.delete(prefix + key);
      return null;
    }

    return item.value;
  } catch (err) {
    console.log(key, err);
  }
};

const remove = async (key: string) => {
  try {
    secureStorage.delete(prefix + key);
  } catch (err) {
    console.log(err);
  }
};

export default { store, get, remove };
