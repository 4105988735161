// libraries
import { CustomText, SpacerColumn } from '@components/atoms';
import { CustomModal } from '@components/molecules';
import React from 'react';
import styled from 'styled-components/native';

// misc
import chrome1 from '@assets/images/chrome-1.jpg';
import chrome2 from '@assets/images/chrome-2.jpeg';
import { Image } from 'react-native';

// types
export type ChromeInstallInstructionModalProps = {
  isVisible: boolean;
  onClose(): void;
};

export const ChromeInstallInstructionModal: React.FC<ChromeInstallInstructionModalProps> = ({
  isVisible,
  onClose,
}) => {
  // variables

  // functions

  // renders
  return (
    <CustomModal isVisible={isVisible} onClose={onClose}>
      <Content>
        <CustomText font="bodyBold" textAlign="center" size={16}>
          Please follow the steps below to install the app on your phone
        </CustomText>
        <SpacerColumn size={4} />
        <CustomText size={14}>
          Step 1: Click on 3 dots icon available on your navigation bar.
        </CustomText>
        <SpacerColumn size={2} />
        <Image source={chrome1} style={{ height: 30, aspectRatio: 1 }} />
        <SpacerColumn size={4} />
        <CustomText size={14}>
          Step 2: A menu will appear, Now click on Add To Home Screen button. Done.
        </CustomText>
        <SpacerColumn size={2} />
        <Image source={chrome2} style={{ height: 100, aspectRatio: 1 }} />
      </Content>
    </CustomModal>
  );
};

const Content = styled.View(({ theme: { layout } }) => ({
  marginTop: layout.padding_x2,
  width: '80%',
  alignSelf: 'center',
}));
