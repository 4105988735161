// misc
import request from '@utils/request';
import { ServerResponse } from './types';

export const useGetCourseTrainingChecklist = async (courseId: number) => {
  try {
    const data = await request<ServerResponse<any>>({
      method: 'get',
      url: `/training_checklist/course/` + courseId,
    });
    return data.data;
  } catch (error) {
    console.log('err', error);
  }
};
