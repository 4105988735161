// libraries
import React from 'react';
import styled from 'styled-components/native';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

// components
import {
  BigButton,
  CustomPressable,
  CustomText,
  Input,
  SpacerColumn,
  Heading,
} from '@components/atoms';

// misc
import { LoginFormType, ResetPasswordFormType } from './types';
import { LoginRequest } from '@api/useLogin';
import { useFormError } from '@hooks/useFormError';
import { useAppNavigation } from '@utils/navigation';
import { genericStyles } from '@styles/genericStyles';
import { useResetPassword } from '@api/useResetPassword';

export const ForgotPasswordScreen = () => {
  // variables
  const { control, handleSubmit, setError, reset } = useForm<LoginFormType>({
    mode: 'all',
  });
  const { goBack } = useAppNavigation();

  const { t } = useTranslation();
  const { mutate, isLoading, error } = useResetPassword({
    onSuccess: data => {
      showMessage({ message: data?.message, type: 'warning' });
      reset();
      goBack();
    },
  });
  useFormError<LoginFormType, LoginRequest>(error, setError, reset);

  // functions
  const onPressSubmit = ({ email }: ResetPasswordFormType) => {
    mutate({ email });
  };

  // returns
  return (
    <>
      <Container contentContainerStyle={genericStyles.fill}>
        <Heading title={t('screens.signIn.note')} />

        <Content>
          <CustomText font="bodyBold">
            Enter your email below, you will be sent a link to your email (if we have it on record)
            to change your password.
          </CustomText>
          <SpacerColumn size={3} />

          <Input<LoginFormType>
            control={control}
            name="email"
            title="Instructor Email"
            placeHolder="jon.doe@example.com"
            rules={{ required: true }}
          />

          <SpacerColumn size={4} />
          <BigButton text="Submit" onPress={handleSubmit(onPressSubmit)} isLoading={isLoading} />

          <SpacerColumn size={2} />
          <CustomPressable onPress={goBack} style={genericStyles.selfCenter}>
            <CustomText color="primary" font="bodyBold">
              Go Back
            </CustomText>
          </CustomPressable>

          <SpacerColumn size={2} />
          <CustomText color="primary" textAlign="center" font="bodyMedium">
            Changing your password will also change your access to link
          </CustomText>

          <SpacerColumn size={2} />
        </Content>
      </Container>
    </>
  );
};

const Container = styled.ScrollView(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
}));

const Content = styled.View(({ theme: { layout } }) => ({
  padding: layout.padding_x4,
  flex: 1,
}));

const Footer = styled.View(({ theme: { colors } }) => ({
  height: 70,
  width: '100%',
  backgroundColor: colors.secondary,
  justifyContent: 'center',
  alignItems: 'center',
}));
