// libraries
import React from 'react';
import styled from 'styled-components/native';

// components
import { CustomPressable, CustomPressableProps } from './CustomPressable';
import { CustomText } from './CustomText';

// misc
import { genericStyles } from '@styles/genericStyles';
import { Loading } from './Loading';

export interface SmallButtonProps extends CustomPressableProps {
  title: string;
  isLoading?: boolean;
}

export const SmallButton = ({ title, isLoading, disabled, ...restProps }: SmallButtonProps) => {
  // renders
  return (
    <Button
      pressableStyle={genericStyles.selfCenter}
      disabled={disabled || isLoading}
      {...restProps}>
      {isLoading ? (
        <Loading color="white" />
      ) : (
        <CustomText color={disabled ? 'gray' : 'black'} font="bodyBold">
          {title}
        </CustomText>
      )}
    </Button>
  );
};

const Button = styled(CustomPressable)(({ theme: { colors, layout }, disabled }) => ({
  ...genericStyles.jcAiCenter,
  backgroundColor: colors.primary,
  paddingVertical: layout.padding_x0_5,
  paddingHorizontal: layout.padding_x1,
  borderRadius: 20,
  opacity: disabled ? 0.5 : 1,
}));
