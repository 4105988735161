// libraries
import React from 'react';
import { FlatList } from 'react-native';

// components
import { CustomText, DivColumn, SpacerColumn } from '@components/atoms';

// misc
import { genericStyles } from '@styles/genericStyles';

// types
export type DeliveryDetailTableItemProps = {
  title: string;
  data: string[];
};

export const DeliveryDetailTableItem = ({ title, data }: DeliveryDetailTableItemProps) => {
  // variables

  // renders
  return (
    <DivColumn jc="center" ai="center">
      <CustomText font="bodyBold" style={genericStyles.underline}>
        {title}
      </CustomText>
      <SpacerColumn size={1.5} />

      <FlatList
        data={data}
        renderItem={({ item }) => <CustomText>{item}</CustomText>}
        keyExtractor={item => item.toString()}
        ItemSeparatorComponent={() => <SpacerColumn size={1} />}
        showsVerticalScrollIndicator={false}
      />
    </DivColumn>
  );
};
