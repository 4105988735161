// libraries
import React, { useMemo, useReducer, useState } from 'react';
import styled from 'styled-components/native';
import { FlatList, StyleSheet, View } from 'react-native';

// components
import { CustomPressable, CustomText, DivRow, SpacerColumn, SpacerRow } from '@components/atoms';
import { CustomModal } from './CustomModal';

// misc
import { theme } from '@styles/themes';
import { genericStyles } from '@styles/genericStyles';
import { RIDER_ICONS } from '@utils/deliveries';

// types
export type RiderKeysProps = {};

export const RiderKeys: React.FC<RiderKeysProps> = () => {
  // variables
  const [visibleDescription, setVisibleDescription] = useState('');
  const [isKeysModalVisible, toggleKeysModal] = useReducer(value => !value, false);

  // functions
  const toggleDescription = () => setVisibleDescription('');

  // renders
  const SelectedIcon = useMemo(() => {
    if (visibleDescription) return RIDER_ICONS.find(i => i.description === visibleDescription).Icon;
    return () => null;
  }, [visibleDescription]);

  return (
    <DivRow>
      <FlatList
        data={RIDER_ICONS}
        ListHeaderComponent={() => (
          <CustomPressable onPress={toggleKeysModal}>
            <KeyText style={{ color: 'black', fontWeight: 'bold' }}>View Key:</KeyText>
          </CustomPressable>
        )}
        horizontal
        keyExtractor={item => item.description.toString()}
        renderItem={({ item: { Icon, size } }) => (
          <CustomPressable onPress={toggleKeysModal}>
            <Icon width={size || 18} />
          </CustomPressable>
        )}
        ItemSeparatorComponent={() => <SpacerRow size={0.5} />}
        showsHorizontalScrollIndicator={false}
        scrollEnabled={false}
        contentContainerStyle={genericStyles.jcAiCenter}
      />

      <CustomModal
        isVisible={!!visibleDescription}
        onClose={toggleDescription}
        containerStyle={styles.modalContainer}>
        <View style={genericStyles.rowWithCenter}>
          <SelectedIcon width={20} />
          <SpacerRow size={0.5} />
          <CustomText>{visibleDescription}</CustomText>
        </View>
      </CustomModal>

      <CustomModal
        isVisible={!!visibleDescription}
        onClose={toggleDescription}
        containerStyle={styles.modalContainer}>
        <View style={genericStyles.rowWithCenter}>
          <SelectedIcon width={20} />
          <SpacerRow size={0.5} />
          <CustomText>{visibleDescription}</CustomText>
        </View>
      </CustomModal>

      <CustomModal
        isVisible={isKeysModalVisible}
        onClose={toggleKeysModal}
        containerStyle={styles.modalContainer}>
        <FlatList
          data={RIDER_ICONS}
          renderItem={({ item: icon }) => (
            <View key={icon.description} style={genericStyles.rowWithCenter}>
              {icon.Icon({ width: 20 })}
              <SpacerRow size={0.75} />
              <CustomText>{icon.description}</CustomText>
            </View>
          )}
          keyExtractor={item => item.description}
          ItemSeparatorComponent={() => <SpacerColumn size={0.5} />}
        />
      </CustomModal>
    </DivRow>
  );
};

const KeyText = styled(CustomText)(({ theme: { colors, layout } }) => ({
  backgroundColor: colors.primary,
  color: 'white',
  borderRadius: 4,
  padding: layout.padding_x0_5,
  marginRight: layout.padding_x1,
}));

const styles = StyleSheet.create({
  description: {
    color: theme.colors.white,
    fontSize: 12,
  },
  modalContainer: {
    padding: 20,
    paddingTop: 0,
    height: 'auto',
  },
});
