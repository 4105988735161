// libraries
import React from 'react';
import styled from 'styled-components/native';
import { RouteProp, useRoute } from '@react-navigation/native';
import { FlatList } from 'react-native';

// components
import {
  CustomText,
  DivColumn,
  DivRow,
  Divider,
  Heading,
  SpacerColumn,
  SpacerRow,
} from '@components/atoms';
import { DeliveryDetailSection, DeliveryDetailTableItem } from './components';
import { FullScreenLoader } from '@components/molecules';

// misc
import { genericStyles } from '@styles/genericStyles';
import { DashboardStackParamList } from '@utils/navigation';
import { useAppSelector } from '@redux/store';
import { selectDeliveryById } from './selectors';

export const DeliveryDetailsScreen = () => {
  // variables
  const {
    params: { id },
  } = useRoute<RouteProp<DashboardStackParamList, 'deliveryDetail'>>();
  const delivery = useAppSelector(state => selectDeliveryById(state, id));

  // hooks

  // functions

  // returns
  if (!delivery) {
    return <FullScreenLoader />;
  }

  return (
    <Container contentContainerStyle={genericStyles.fill}>
      <Heading showSchoolIcon title={delivery.hostName} status={delivery.status} />

      <Content>
        <DeliveryDetailSection>
          <DivRow>
            <CustomText font="bodyBold">Start Date:</CustomText>
            <SpacerRow size={1} />
            <CustomText font="bodyBold">{delivery.startDate}</CustomText>
          </DivRow>
          <DivRow>
            <CustomText font="bodyBold">Consent Cut Off Date:</CustomText>
            <SpacerRow size={1} />
            <CustomText font="bodyBold">{delivery.consentCutOffDate}</CustomText>
          </DivRow>

          <SpacerColumn size={2} />

          <DivRow jc="space-between">
            <DeliveryDetailTableItem
              title="Year(s)"
              data={delivery.courseSummary.map(course => course.yearGroup)}
            />
            <DeliveryDetailTableItem
              title="Course"
              data={delivery.courseSummary.map(course => course.courseLabel)}
            />
            <DeliveryDetailTableItem
              title="Booked"
              data={delivery.courseSummary.map(course => course.bookedAllocation.toString())}
            />
          </DivRow>
        </DeliveryDetailSection>

        <DeliveryDetailSection title="Instructors">
          <DivRow>
            <DivColumn>
              {delivery.instructors.map(item => (
                <CustomText key={item.id}>{item.instructorName}</CustomText>
              ))}
            </DivColumn>
            <SpacerRow size={1} />
            <DivColumn>
              {delivery.instructors.map(item => (
                <CustomText key={item.id}>{item.phoneNumber}</CustomText>
              ))}
            </DivColumn>
          </DivRow>
        </DeliveryDetailSection>

        <DeliveryDetailSection title="Notes from Training Provider">
          <CustomText>{delivery.training_provider_notes}</CustomText>
        </DeliveryDetailSection>

        <DeliveryDetailSection title="Venue">

          <ContentContainer>
            <CustomText font="bodyBold">Venue Name: </CustomText>
            <CustomText style={{ marginLeft: delivery.venue.venueName.length < 40 ? 20 : 0 }}>{delivery.venue.venueName}</CustomText>
          </ContentContainer>

          <DivRow>
            <DivColumn>
              <CustomText font="bodyBold">Address:</CustomText>
              <CustomText font="bodyBold">PostCode:</CustomText>
              {delivery.venue.venuePhoneNumber && (
                <CustomText font="bodyBold">Phone Number:</CustomText>
              )}
            </DivColumn>
            <SpacerRow size={1} />
            <DivColumn>
              <CustomText>{delivery.venue.venueAddress}</CustomText>
              <CustomText>{delivery.venue.venuePostcode}</CustomText>
              {delivery.venue.venuePhoneNumber && (
                <CustomText>{delivery.venue.venuePhoneNumber}</CustomText>
              )}
            </DivColumn>
          </DivRow>
        </DeliveryDetailSection>

        <DeliveryDetailSection title="Venue Contacts">
          <FlatList
            showsVerticalScrollIndicator={false}
            data={delivery.venue.venueContact}
            renderItem={({ item }) => (
              <DivRow>
                <DivColumn>
                  <CustomText font="bodyBold">Title:</CustomText>
                  <CustomText font="bodyBold">Name:</CustomText>
                  <CustomText font="bodyBold">Tel:</CustomText>
                  <CustomText font="bodyBold">Safeguarding Lead:</CustomText>
                </DivColumn>
                <SpacerRow size={1} />
                <DivColumn>
                  <CustomText>{item.title}</CustomText>
                  <CustomText>{item.venueContactName}</CustomText>
                  <CustomText>{item.venueContactPhoneNumber}</CustomText>
                  <CustomText>{delivery.is_safeguard_lead}</CustomText>
                </DivColumn>
              </DivRow>
            )}
            keyExtractor={item => item.venueContactName}
            ItemSeparatorComponent={() => (
              <>
                <SpacerColumn size={1} />
                <Divider color="mercury" />
                <SpacerColumn size={1} />
              </>
            )}
          />
        </DeliveryDetailSection>
      </Content>
    </Container>
  );
};

const Container = styled.ScrollView(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
}));

const Content = styled.ScrollView(() => ({
  flex: 1,
}));

const ContentContainer = styled.View(({ theme: { layout } }) => ({
  ...genericStyles.rowWithWrapCenter,
}));