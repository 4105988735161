// librarires
import { RouteProp, useRoute } from '@react-navigation/native';

// misc
import { useAppSelector } from '@redux/store';
import {
  selectCourseById,
  selectDeliveryById,
  selectSelectedRiders,
  selectSelectedRidersIds,
} from '@screens/dashboard/selectors';
import { DashboardStackParamList } from '@utils/navigation';

export const useGetDeliveryCourseAndRiders = <
  T extends keyof Pick<
    DashboardStackParamList,
    | 'courseInfo'
    | 'allRiders'
    | 'training'
    | 'trainingSurvey'
    | 'instructorFeedback'
    | 'trainingChecklist'
  >,
>() => {
  // variables
  const {
    params: { deliveryId, courseId },
  } = useRoute<RouteProp<DashboardStackParamList, T>>();

  const delivery = useAppSelector(state => selectDeliveryById(state, deliveryId));
  const course = useAppSelector(state => selectCourseById(state, deliveryId, courseId));
  const riders = useAppSelector(state => selectSelectedRiders(state, deliveryId, courseId));
  const riderIds = useAppSelector(state => selectSelectedRidersIds(state, deliveryId, courseId));

  // returns
  return { delivery, course, riderIds, riders };
};
