// libraries
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/native';
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
  useController,
} from 'react-hook-form';
import { TextStyle, ViewStyle } from 'react-native';

// components
import { CustomIcon, CustomText, DivRow, ErrorText, SpacerRow } from '@components/atoms';

// styles
import { genericStyles } from '@styles/genericStyles';
import { layout } from '@styles/layout';

//types
interface PointCounterProps<T extends FieldValues> {
  text: string;
  control: Control<T>;
  name: Path<T>;
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  defaultValue?: PathValue<T, Path<T>>;
  containerStyle?: ViewStyle;
  errorStyle?: TextStyle;
  borderColor?: string;
  backgroundColor?: string;
  onChange?: () => void;
}

export const PointCounter = <T extends FieldValues>({
  text,
  name,
  control,
  rules,
  defaultValue,
  containerStyle,
  errorStyle,
  borderColor,
  backgroundColor,
  onChange,
}: PointCounterProps<T>): React.ReactElement => {
  // variables
  const { field, fieldState } = useController<T>({
    name,
    control,
    defaultValue,
    rules,
  });
  const [currentValue, setCurrentValue] = useState(0);
  const firstMountRef = useRef(false);

  useEffect(() => {
    if (firstMountRef.current) {
      field.onChange(currentValue.toString());
      onChange && onChange();
    } else {
      firstMountRef.current = true;
    }
  }, [currentValue]);

  useEffect(() => {
    if (field.value === '0') {
      setCurrentValue(0);
    }
  }, [field.value]);

  useEffect(() => {
    if (parseInt(field.value) && currentValue === 0) {
      setCurrentValue(field.value);
    }
  }, [field.value]);

  const decreaseValue = () => {
    setCurrentValue(prev => prev - 1);
  };

  const increaseValue = () => {
    setCurrentValue(prev => prev + 1);
  };

  // renders
  return (
    <Container style={containerStyle}>
      <ButtonContainer borderColor={borderColor} backgroundColor={backgroundColor}>
        <CustomText size={16} font="bodyBold" color={fieldState.error?.type ? 'error' : 'text'}>
          {text}
        </CustomText>

        <DivRow ai="center" width="30%">
          <CustomIcon
            type="fontawesome"
            name="minus-circle"
            color={fieldState.error?.type ? 'error' : 'text'}
            isDisabled={currentValue === 0}
            onPress={decreaseValue}
            size={30}
          />
          <SpacerRow size={1} />

          <CustomInput borderColor={borderColor} value={field.value} editable={false} />

          <SpacerRow size={1} />
          <CustomIcon
            type="fontawesome"
            name="plus-circle"
            color={fieldState.error?.type ? 'error' : 'text'}
            onPress={increaseValue}
            size={30}
          />
        </DivRow>
      </ButtonContainer>

      <ErrorText style={[errorStyle, { marginBottom: layout.padding_x1 }]}>
        {fieldState.error?.message || ''}
      </ErrorText>
    </Container>
  );
};

const Container = styled.View(() => ({}));

type StyledProps = {
  borderColor?: string;
  backgroundColor?: string;
};

const ButtonContainer = styled.View<StyledProps>(
  ({ theme: { layout }, borderColor, backgroundColor }) => ({
    ...genericStyles.rowWithCenterAndSB,
    padding: layout.padding_x1,
    paddingHorizontal: layout.padding_x4,
    borderColor,
    backgroundColor,
    borderRadius: 10,
    marginBottom: layout.padding_x1,
    borderWidth: borderColor ? 1 : 0,
  }),
);

const CustomInput = styled.TextInput<StyledProps>(({ borderColor }) => ({
  borderColor,
  borderWidth: 1,
  height: 40,
  width: 80,
  fontSize: 20,
  borderRadius: 10,
  textAlign: 'center',
  ...genericStyles.jcAiCenter,
}));
