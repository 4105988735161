// misc
import { layout } from './layout';

/**
 * to add transparency to an hexa color, see this
 * https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
 */

export const theme = {
  colors: {
    // main
    primary: '#FE9C1B',
    get primary50() {
      return this.primary + 80;
    },
    secondary: '#D7F6D0',
    primaryBackground: '#FFFFFF',
    success: '#78D64B',
    error: '#F9423A',
    warning: '#FF9E1C',
    gray: '#808080',
    silver: '#A5A5A5',
    white: '#FFFFFF',
    alto: '#CECECE',
    snuf: '#D6CDE4',
    porcelain: '#F4F5F6',
    mineShaft: '#1F1F1F',
    boulder: '#7A7474',
    cornflowerBlue: '#D7F7FA',
    mercury: '#E6E6E6',
    bridalHeath: '#FFFDF9',
    tequila: '#FFE6C2',
    cosmos: '#FFCDCD',
    blueRomance: '#D7F7D0',
    blue: 'blue',
    black: '#000000',
    // other
    text: '#000',
    get text50() {
      return this.text + 80;
    },
    transparent: 'transparent',
  },
  layout: { ...layout },
};
