import { showMessage } from 'react-native-flash-message';
import { DETAULT_PROCESS_ERROR } from '../utils/errors';
import { ServerResponse } from '@api/types';
import { useUser } from '@context/UserProvider';

type ErrorHandlerType = {
  title?: string;
  error: Error | ServerResponse<unknown>;
  callback?: () => void;
};

export const useErrorHandler = () => {
  // variables
  const { resetAuth } = useUser();

  // functions
  const triggerError = ({ title, error, callback }: ErrorHandlerType) => {
    let errorText = DETAULT_PROCESS_ERROR;

    if (typeof error === 'string') {
      errorText = error;
    } else if (error && 'message' in error) {
      errorText = error.message;
    }

    if (error && 'code' in error && error.code === 401) {
      resetAuth();
    }

    callback && callback();

    showMessage({
      message: title || errorText,
      description: title ? errorText : undefined,
      type: 'danger',
    });
  };

  return { triggerError };
};
