// libraries
import { useMutation } from 'react-query';

// misc
import { useErrorHandler } from '@hooks/useErrorHandler';
import request from '@utils/request';
import { ServerResponse, User } from './types';
import { useUser } from '@context/UserProvider';

export type VerifyCodeResponse = { user: User; token: string };

export interface VerifyCodeRequest {
  code: string;
}

export const useVerifyCode = ({
  onSuccess,
}: {
  onSuccess?: (res: VerifyCodeResponse) => void;
  onError?: (res: ServerResponse<unknown>) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();
  const { setAuthUser } = useUser();

  // request
  const query = useMutation<
    VerifyCodeResponse,
    ServerResponse<unknown> | undefined,
    VerifyCodeRequest
  >(['VerifyCode'], async ({ code }: VerifyCodeRequest) => {
    try {
      const data = await request<ServerResponse<VerifyCodeResponse>>({
        method: 'post',
        url: `/verifyCode`,
        data: { code },
      });

      await setAuthUser(data.data.user, data.data.token);
      onSuccess && onSuccess(data.data);
      return data.data;
    } catch (error) {
      triggerError({ error });
    }
  });

  // return
  return query;
};
