// libraries
import { useQuery } from 'react-query';

// misc
import { useErrorHandler } from '@hooks/useErrorHandler';
import request from '@utils/request';
import { ServerResponse } from './types';
import { DeliveriesDataType } from '@screens/dashboard/types';
import { useUser } from '@context/UserProvider';

type GetInstructorResponse = DeliveriesDataType;

export const useGetInstructorDeliveries = ({
  onSuccess,
}: {
  onSuccess?: (data: DeliveriesDataType) => void;
} = {}) => {
  // variables
  const { triggerError } = useErrorHandler();
  const { user } = useUser();

  // request
  const query = useQuery<GetInstructorResponse>(
    [`instructor-deliveries`],
    async () => {
      try {
        const data = await request<ServerResponse<GetInstructorResponse>>({
          method: 'get',
          url: `/deliveries/instructor/` + user?.id,
        });

        onSuccess(data.data);
        return data.data;
      } catch (error) {
        console.log('err', error);

        triggerError({ error });
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  // return
  return query;
};
