// libraries
import React, { useCallback } from 'react';
import { FlatList, ListRenderItemInfo } from 'react-native';
import styled from 'styled-components/native';
import { RouteProp, useRoute } from '@react-navigation/native';

// components
import { CustomText, Heading, SpacerColumn } from '@components/atoms';
import { FullScreenLoader } from '@components/molecules';

// misc
import { layout } from '@styles/layout';
import { selectDeliveryById, selectDeliveryRiders } from '@screens/dashboard/selectors';
import { DashboardStackParamList } from '@utils/navigation';
import { useAppSelector } from '@redux/store';
import { DeliveryDatumRider } from '@screens/dashboard/types';

export const RiderIssuesScreen = () => {
  // variables
  const {
    params: { deliveryId, riderIds },
  } = useRoute<RouteProp<DashboardStackParamList, 'riderIssues'>>();

  let riders = useAppSelector(state => selectDeliveryRiders(state, deliveryId, riderIds));
  const delivery = useAppSelector(state => selectDeliveryById(state, deliveryId));
  riders = riders?.filter(r => r.teacherNotes || r.sendNotes || r.medicalNotes);

  // returns
  if (!riders) {
    return <FullScreenLoader />;
  }

  const DetailSection = useCallback(
    ({ title, value }: { title: string; value: string }) =>
      value ? (
        <>
          <CustomText color="primary" font="bodyBold">
            {title}
          </CustomText>
          <SpacerColumn size={1} />
          <CustomText>{value}</CustomText>
          <SpacerColumn size={2} />
        </>
      ) : null,
    [],
  );

  const renderItem = useCallback(
    ({ item: rider }: ListRenderItemInfo<DeliveryDatumRider>) => (
      <ItemContainer>
        <CustomText textAlign="center" font="bodyBold" size={24}>
          {rider.name}
        </CustomText>
        <SpacerColumn size={2} />
        {rider.teacherNotes && (
          <DetailSection title="Teacher Notes:" value={rider.teacherNotes} />
        )}
        {rider.sendNotes && (
          <DetailSection title="SEND Information (Parent):" value={rider.sendNotes} />
        )}
        {rider.medicalNotes && (
          <DetailSection title="Medical Information:" value={rider.medicalNotes} />
        )}
      </ItemContainer>
    ),
    [],
  );

  return (
    <Container>
      <Heading title={delivery.hostName} status={delivery.status} showSchoolIcon />

      <Content>
        <FlatList
          data={riders}
          renderItem={renderItem}
          keyExtractor={(_, index) => index.toString()}
          contentContainerStyle={{
            paddingHorizontal: layout.padding_x1,
            paddingVertical: layout.padding_x2,
          }}
        />
      </Content>
    </Container>
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));

const Content = styled.View(() => ({
  flex: 1,
}));

const ItemContainer = styled.View({});
