/* eslint-disable @typescript-eslint/no-empty-function */
// libraries
import React, { createContext, useContext, useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

// misc
import { User } from '@api/types';
import {
  APP_AUTH_TOKEN,
  APP_IS_LOGGED_IN_KEY,
  APP_USER,
  DELIVERY_POST_SUBMIT_KEY,
  DELIVERY_SAVE_KEY,
  DELIVERY_SELECTED_RIDERS_KEY,
} from '@utils/keys';
import cache from '@utils/cache';

// types
interface DefaultValue {
  user?: User | undefined;
  setAuthUser: (user: User, token: string) => Promise<boolean>;
  resetAuth: () => void;
  getAuthUser: (user?: { id: number; token: string } | undefined) => Promise<boolean>;
  token: string;
  isLoading: boolean;
  initLoading: boolean;
  isUnauthorized: boolean;
  isRememberMeChoosen: boolean;
  setIsRememberMeChoosen: (rememberMe: boolean) => void;
  setIsAuthComplete: (rememberMe: boolean) => void;
  cacheAuth: () => void;
  isUserLoggedIn: boolean;
  isAuthComplete: boolean;
  oneTimeAuth: () => void;
}

const defaultValue: DefaultValue = {
  setAuthUser: () => Promise.resolve(true),
  token: '',
  resetAuth: () => {},
  isLoading: false,
  getAuthUser: () => Promise.resolve(true),
  isUnauthorized: false,
  initLoading: true,
  isRememberMeChoosen: true,
  setIsRememberMeChoosen: () => {},
  setIsAuthComplete: () => {},
  cacheAuth: () => {},
  isUserLoggedIn: false,
  isAuthComplete: false,
  oneTimeAuth: () => {},
};

// context
const UserContext = createContext(defaultValue);

export const UserProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  // user variable
  const [user, setUser] = useState<User>();
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [isRememberMeChoosen, setIsRememberMeChoosen] = useState(true);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isAuthComplete, setIsAuthComplete] = useState(false);

  // hooks
  useEffect(() => {
    updateLoggedInUser();
  }, []);

  // functions
  const updateLoggedInUser = async () => {
    const isLoggedIn = await AsyncStorage.getItem(APP_IS_LOGGED_IN_KEY);
    const isLoggedIn2 = isLoggedIn ? (JSON.parse(isLoggedIn) as boolean) : false;

    setIsUserLoggedIn(isLoggedIn2);
  };

  const getAuthUser: DefaultValue['getAuthUser'] = async () => {
    try {
      setInitLoading(false);

      if (isUserLoggedIn) {
        setIsLoading(true);
        const user = await cache.get(APP_USER);
        const token = await cache.get(APP_AUTH_TOKEN);
        return setAuthUser(user as User, token as string);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }

    setInitLoading(false);
  };

  const setAuthUser: DefaultValue['setAuthUser'] = async (user, token) => {
    try {
      setUser(user);
      setToken(token);
      setIsLoading(false);
      setInitLoading(false);

      return Promise.resolve(true);
    } catch (error) {
      console.log('test', error);

      return Promise.reject(error);
    }
  };

  const cacheAuth = async () => {
    try {
      cache.store(APP_AUTH_TOKEN, token);
      cache.store(APP_USER, user);

      await AsyncStorage.setItem(APP_IS_LOGGED_IN_KEY, JSON.stringify(true));
      setIsAuthComplete(true);
    } catch (e) {
      console.log('test', e);
    }
  };

  const oneTimeAuth = async () => {
    try {
      cache.store(APP_AUTH_TOKEN, token);

      setIsAuthComplete(true);
    } catch (e) {
      console.log('test', e);
    }
  };

  const resetAuth = async () => {
    await cache.remove(APP_AUTH_TOKEN);
    await AsyncStorage.removeItem(APP_IS_LOGGED_IN_KEY);
    await cache.remove(APP_USER);
    await cache.remove(DELIVERY_SAVE_KEY);
    await cache.remove(DELIVERY_POST_SUBMIT_KEY);
    await cache.remove(DELIVERY_SELECTED_RIDERS_KEY);
    // cache.remove()

    setIsRememberMeChoosen(true);
    setIsAuthComplete(false);
    setIsUserLoggedIn(false);
    setUser(undefined);
    setToken('');
  };

  // user provider
  return (
    <UserContext.Provider
      value={{
        user,
        setAuthUser,
        token,
        resetAuth,
        getAuthUser,
        isLoading,
        isUnauthorized: !user,
        initLoading,
        setIsRememberMeChoosen,
        isRememberMeChoosen,
        cacheAuth,
        isUserLoggedIn,
        isAuthComplete,
        setIsAuthComplete,
        oneTimeAuth,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
