// libraries
import React, { ReactElement, useMemo } from 'react';
import { useTheme } from 'styled-components/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

// components
import { CustomIcon, IconNames } from '@components/atoms';
import { AppHelpScreen } from '@screens/help';
import DashboardStacks from './stacks/DashboardStacks';
import { DeliveriesScreen } from '@screens/dashboard';

// types
import { RouteItem, TabNavigatorParamList, AuthStackParamList } from '@utils/navigation';
import { fonts } from '@styles/fonts';
import { useUser } from '@context/UserProvider';
import MyAccountStacks from './stacks/MyAccountStacks';
import { RouteProp } from '@react-navigation/native';
import { AuthPinScreen, AuthPinMode } from '@screens/authentication/AuthPinScreen';
import alert from '@utils/alert';

//Misc
import { PILOT_MODE } from '@env';

const Tab = createBottomTabNavigator<TabNavigatorParamList>();

const TabNavigator: React.FC<{
  route: RouteProp<TabNavigatorParamList, 'account'>;
  navigation: any;
}> = ({ navigation }): ReactElement => {
  // variables
  const { colors, layout } = useTheme();
  const { initLoading, resetAuth } = useUser();
  const { navigate } = useNavigation<NativeStackNavigationProp<AuthStackParamList>>();

  const isPilotMode = PILOT_MODE === 'true'; // Assuming PILOT_MODE is correctly imported from @env

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('tabPress', e => {
      // Prevent default behavior
      e.preventDefault();
      // Do something manually
      // ...
    });

    return unsubscribe;
  }, [navigation]);

  const TabItems: RouteItem<TabNavigatorParamList>[] = useMemo(
    () => [
      {
        name: 'home',
        component: DashboardStacks,
        header: () => null,
      },
      {
        name: 'account',
        component: MyAccountStacks,
        header: () => null,
      },
      {
        name: 'myDeliveries',
        component: DeliveriesScreen,
        title: 'My Deliveries',
        onPress: resetAuth,
      },
      {
        name: 'help',
        component: AppHelpScreen,
        title: 'App Help',
      },
      {
        name: 'logout',
        component: AuthPinScreen,
      },
    ],
    [],
  );

  // hooks
  // useLayoutEffect(() => {
  //   const name = getFocusedRouteNameFromRoute(route);
  // }, [navigation, route]);

  const onLogoutPress = () => {
    sessionStorage.setItem('LockScreenUrl', window.location.href);
    navigate('authPin', { type: AuthPinMode.SET });
  };

  // returns
  if (initLoading) {
    return null;
  }

  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        headerShadowVisible: false,
        headerStyle: {
          backgroundColor: isPilotMode ? colors.error : colors.secondary,
        },
        headerLeftContainerStyle: {
          paddingLeft: layout.padding_x1_5,
        },
        headerRightContainerStyle: {
          paddingRight: layout.padding_x1_5,
        },
        headerTitleStyle: {
          fontFamily: fonts.family.bodyBold,
          fontSize: 20,
        },
        tabBarStyle: { backgroundColor: isPilotMode ? colors.error : colors.secondary },
        headerTitleAlign: 'center',
        tabBarLabel: () => null,
        tabBarIcon: ({ focused }) => {
          let iconName: IconNames;
          let size = 30;
          let onPress: () => void | undefined;

          switch (route.name) {
            case 'home':
              iconName = 'home';
              break;

            case 'help':
              iconName = 'help-circle';
              break;

            case 'myDeliveries':
              iconName = 'calendar';
              size = 40;
              break;

            case 'logout':
              iconName = 'lock';
              onPress = onLogoutPress;
              break;

            default:
              iconName = 'user';
              break;
          }

          // You can return any component that you like here!
          return (
            <CustomIcon
              name={iconName}
              size={size}
              color={focused ? 'primary' : 'boulder'}
              onPress={onPress}
            />
          );
        },
      })}>
      {TabItems.map(item => (
        <Tab.Screen
          key={item.name}
          name={item.name}
          component={item.component}
          options={{
            title: item.title,
            // headerRight: () => <RightIcons />,
            header: item.header,
          }}
        />
      ))}
    </Tab.Navigator>
  );
};

export default TabNavigator;
