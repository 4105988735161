// libraries
import { showMessage } from 'react-native-flash-message';
import NetInfo, { useNetInfo } from '@react-native-community/netinfo';

// misc
import { useSaveDelivery } from '@api/useSaveDelivery';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectSavedDeliveries } from '@screens/dashboard/selectors';
import { isOnline } from '@utils/connect';
import { DELIVERY_POST_SUBMIT_KEY } from '@utils/keys';
import cache from '@utils/cache';
import { deliveriesSlice } from '@screens/dashboard/slice';

export const useSubmitAllDeliveries = (options?: {
  onStart?: () => void;
  onSuccess?: () => void;
}) => {
  const savedDeliveries = useAppSelector(selectSavedDeliveries);
  const { isConnected } = useNetInfo();
  const dispatch = useAppDispatch();

  const { mutateAsync, isLoading } = useSaveDelivery(null, {
    onSuccess: () => {
      cache.remove(DELIVERY_POST_SUBMIT_KEY);
      options?.onSuccess && options.onSuccess();
    },
  });

  const submit = async () => {
    if (isOnline() && isConnected) {
      try {
        options?.onStart && options?.onStart();
        const allRequests = [];

        Object.values(savedDeliveries).forEach(delivery => {
          allRequests.push(mutateAsync({ delivery }));
        });

        await Promise.all(allRequests);

        cache.remove(DELIVERY_POST_SUBMIT_KEY);
        options?.onSuccess && options.onSuccess();
        Object.values(savedDeliveries).forEach(delivery => {
          dispatch(deliveriesSlice.actions.clearCharacteristics({ deliveryId: delivery.id }));
        });
      } catch (error) {
        console.log('saveIssues', error);
      }
    } else {
      showMessage({
        type: 'warning',
        message: "You don't have working internet connection. Please connect to internet first.",
        duration: 5000,
      });

      options?.onSuccess && options.onSuccess();
    }
  };

  const submitOfflineData = async () => {
    NetInfo.fetch().then(async state => {
      if (state.isConnected) {
        try {
          options?.onStart && options?.onStart();
          const allRequests = [];

          Object.values(savedDeliveries).forEach(delivery => {
            allRequests.push(mutateAsync({ delivery }));
          });

          await Promise.all(allRequests);

          cache.remove(DELIVERY_POST_SUBMIT_KEY);
          options?.onSuccess && options.onSuccess();
          Object.values(savedDeliveries).forEach(delivery => {
            dispatch(deliveriesSlice.actions.clearCharacteristics({ deliveryId: delivery.id }));
          });
        } catch (error) {
          console.log('saveIssues', error);
        }
      } else {
        showMessage({
          type: 'warning',
          message: "You don't have working internet connection. Please connect to internet first.",
          duration: 5000,
        });

        options?.onSuccess && options.onSuccess();
      }
    });
  };

  return { submit, submitOfflineData, isLoading };
};
