// libraries
import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components/native';
import { createStackNavigator } from '@react-navigation/stack';

// components
import {
  CourseInfoScreen,
  DashboardScreen,
  DeliveriesScreen,
  DeliveryDetailsScreen,
  DeliverySummaryScreen,
  NewsScreen,
} from '@screens/dashboard';
import { RightIcons } from '@navigation/components';
import {
  AllRiderScreen,
  InstructorFeedbackScreen,
  RiderDetailsScreen,
  RiderIssuesScreen,
} from '@screens/rider';
import { TrainingScreen } from '@screens/rider/TrainingScreen';
import { TrainingSurveyScreen } from '@screens/training';
import { CharacteristicsScreen } from '@screens/dashboard/CharacteristicsScreen';
import { InstructorNotesScreen } from '@screens/notes';
import { RiderInstructorNotesScreen } from '@screens/notes/RiderInstructorNotesScreen';
import { TrainingChecklist } from '@screens/rider/TrainingChecklist';

// misc
import { DashboardStackParamList, RouteItem } from '@utils/navigation';
import { fonts } from '@styles/fonts';
import { useUser } from '@context/UserProvider';
import { usePopulateDelivery } from '@hooks/usePopulateDelivery';
import { useSyncApp } from '@hooks/useSyncApp';
import { PILOT_MODE } from '@env';

const Stack = createStackNavigator<DashboardStackParamList>();

export const DashboardItems: RouteItem<DashboardStackParamList>[] = [
  {
    name: 'dashboard',
    component: DashboardScreen,
    title: 'My Dashboard',
  },
  {
    name: 'news',
    component: NewsScreen,
    title: 'News',
  },
  {
    name: 'deliveries',
    component: DeliveriesScreen,
    title: 'Training Deliveries',
  },
  {
    name: 'deliverySummary',
    component: DeliverySummaryScreen,
    title: 'Delivery Summary',
  },
  {
    name: 'deliveryDetail',
    component: DeliveryDetailsScreen,
    title: 'Delivery Details',
  },
  {
    name: 'riderIssues',
    component: RiderIssuesScreen,
    title: 'Rider Summary',
  },
  {
    name: 'allRiders',
    component: AllRiderScreen,
    title: 'All Riders',
  },
  {
    name: 'riderDetail',
    component: RiderDetailsScreen,
    title: 'Rider Details',
  },
  {
    name: 'courseInfo',
    component: CourseInfoScreen,
    title: 'Course Info',
  },
  {
    name: 'training',
    component: TrainingScreen,
    title: 'Training',
  },
  {
    name: 'trainingChecklist',
    component: TrainingChecklist,
    title: 'Training Checklist',
  },
  {
    name: 'trainingSurvey',
    component: TrainingSurveyScreen,
    title: 'Training Survey',
  },
  {
    name: 'instructorFeedback',
    component: InstructorFeedbackScreen,
    title: 'Instructor Feedback',
  },
  {
    name: 'characteristics',
    component: CharacteristicsScreen,
    title: 'Characteristics',
  },
  {
    name: 'instructorNotes',
    component: InstructorNotesScreen,
    title: 'Instructor Notes',
  },
  {
    name: 'riderInstructorNotes',
    component: RiderInstructorNotesScreen,
    title: 'Instructor Notes',
  },
];

const DashboardStacks = (): ReactElement => {
  // variables
  const { colors, layout } = useTheme();
  const { initLoading } = useUser();

  usePopulateDelivery();
  useSyncApp();

  // Check if PILOT_MODE is enabled and update titles accordingly
  const isPilotMode = PILOT_MODE === 'true'; // Assuming PILOT_MODE is correctly imported from @env
  const UpdatedDashboardItems = DashboardItems.map(item => ({
    ...item,
    title: isPilotMode ? `${item.title} - Training` : item.title,
  }));

  // returns
  if (initLoading) {
    return null;
  }

  return (
    <Stack.Navigator
      screenOptions={{
        headerShadowVisible: false,
        headerStyle: {
          backgroundColor: isPilotMode ? colors.error : colors.secondary,
        },
        headerLeftContainerStyle: {
          paddingLeft: layout.padding_x1_5,
        },
        headerRightContainerStyle: {
          paddingRight: layout.padding_x1_5,
        },
        headerTitleStyle: {
          fontFamily: fonts.family.bodyBold,
          fontSize: 20,
        },
        headerTitleAlign: 'center',
        animationEnabled: true,
      }}>
      {UpdatedDashboardItems.map(item => (
        <Stack.Screen
          key={item.name}
          name={item.name}
          component={item.component}
          options={{
            title: item.title,
            headerRight: () => <RightIcons />,
          }}
        />
      ))}
    </Stack.Navigator>
  );
};

export default DashboardStacks;
