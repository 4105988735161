import { DeliveryStatusType } from '@screens/dashboard/types';
import ConsentNotReceivedIcon from '@assets/icons/consent-not-received.svg';
import ConsentReceivedIcon from '@assets/icons/consent-received.svg';
import ConsentWithdrawnIcon from '@assets/icons/consent-withdrawn.svg';
import CycleCrossIcon from '@assets/icons/cycle-cross.svg';
import MedicalIssuesIcon from '@assets/icons/medical-issues.svg';
import NoPhotographyIcon from '@assets/icons/no-photography.svg';
import RiderCannotRideIcon from '@assets/icons/rider-cannot-ride.svg';
import NoteFromTeacherIcon from '@assets/icons/note-from-teacher.svg';
import NotPresentIcon from '@assets/icons/not-present.svg';
import SendNotesIcon from '@assets/icons/send-notes.svg';
import CIcon from '@assets/icons/C.svg';
import FIcon from '@assets/icons/F.svg';
import SIcon from '@assets/icons/S.svg';
import OIcon from '@assets/icons/O.svg';
import PIcon from '@assets/icons/P.svg';
import AIcon from '@assets/icons/A.svg';
import NIcon from '@assets/icons/N.svg';
import RedXIcon from '@assets/icons/red-x.svg';
import GreenTickIcon from '@assets/icons/green-tick.svg';

export const DELIVERY_STATUS: { [key: number]: DeliveryStatusType } = {
  0: 'not_started',
  1: 'in_progress',
  2: 'complete',
};

export const RIDER_ICONS = [
  {
    description: 'Parent/Guardian consent received',
    Icon: ConsentReceivedIcon,
  },
  {
    description: 'Parent/Guardian consent not received',
    Icon: ConsentNotReceivedIcon,
  },
  {
    description: 'Parent/Guardian consent withdrawn',
    Icon: ConsentWithdrawnIcon,
  },
  {
    description: 'Expected but not present',
    Icon: NotPresentIcon,
    size: 22,
  },
  {
    description: 'SEND notes to be aware of',
    Icon: SendNotesIcon,
    size: 12,
  },
  {
    description: 'Potential medical issues to be aware of',
    Icon: MedicalIssuesIcon,
  },
  {
    description: 'Note from Teacher or Parent',
    Icon: NoteFromTeacherIcon,
  },
  {
    description: 'No photography allowed',
    Icon: NoPhotographyIcon,
    size: 22,
  },
  {
    description: 'Rider cannot ride',
    Icon: RiderCannotRideIcon,
    size: 20,
  },
  {
    description: 'Rider has no bike',
    Icon: CycleCrossIcon,
    size: 20,
  },
];

export const CHECKLIST_ICONS = [
  {
    description: 'Course complete',
    Icon: CIcon,
  },
  {
    description: 'Feedback complete',
    Icon: FIcon,
  },
  {
    description: 'Survey complete',
    Icon: SIcon,
  },
  {
    description: 'On my own',
    Icon: OIcon,
  },
  {
    description: 'With practice',
    Icon: PIcon,
  },
  {
    description: 'With assistance',
    Icon: AIcon,
  },
  {
    description: 'Not seen',
    Icon: NIcon,
  },
  {
    description: 'No',
    Icon: RedXIcon,
  },
  {
    description: 'Yes',
    Icon: GreenTickIcon,
  },
];
