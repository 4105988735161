// libraries
import React, { useMemo } from 'react';
import styled from 'styled-components/native';
import { FlatList } from 'react-native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

// components
import {
  CustomIcon,
  CustomText,
  DivRow,
  Heading,
  SpacerColumn,
  SpacerRow,
} from '@components/atoms';
import { MenuItem } from './components';
import { FullScreenLoader } from '@components/molecules';

// misc
import EventDetailsIcon from '@assets/icons/event-details.svg';
import InstructorNotesIcon from '@assets/icons/instructor-notes.svg';
import RiderIssuesIcon from '@assets/icons/rider-Issues.svg';
import RegisterIcon from '@assets/icons/register.svg';
import { genericStyles } from '@styles/genericStyles';
import { layout } from '@styles/layout';
import { DashboardStackParamList, TabNavigatorParamList } from '@utils/navigation';
import { useAppSelector } from '@redux/store';
import {
  selectDeliveryRiders,
  selectInstructorNotesByRiders,
  selectSelectedRidersIds,
} from './selectors';
import { useGetDeliveryCourseAndRiders } from '@hooks/useGetDeliveryCourseAndRiders';

export const CourseInfoScreen = () => {
  // variables
  const { navigate } = useNavigation<NativeStackNavigationProp<TabNavigatorParamList>>();
  const {
    params: { deliveryId, courseId },
  } = useRoute<RouteProp<DashboardStackParamList, 'courseInfo'>>();
  const { delivery, course, riderIds } = useGetDeliveryCourseAndRiders();
  const courseRidersIds = useAppSelector(state =>
    selectSelectedRidersIds(state, deliveryId, courseId),
  );
  const courseRiderIds = course?.riders?.reduce((acc, rider) => [...acc, rider?.rider_id], []);
  const instructorNoters = useAppSelector(state =>
    selectInstructorNotesByRiders(state, delivery.id, riderIds),
  );
  const badgeRiderIssues = useAppSelector(state =>
    selectDeliveryRiders(state, deliveryId, courseRiderIds),
  );

  const menuData = useMemo(() => {
    const details = [
      {
        Icon: EventDetailsIcon,
        text: 'Venue & Instructor Contacts',
        onPress: () => navigate('home', { screen: 'deliveryDetail', params: { id: deliveryId } }),
      },
      {
        Icon: RegisterIcon,
        text: courseRidersIds?.length ? `View Riders` : 'Register/Select Rider',
        onPress: () =>
          navigate('home', {
            screen: courseRidersIds?.length ? 'training' : 'allRiders',
            params: { deliveryId, courseId },
          }),
      },
      {
        Icon: RiderIssuesIcon,
        text: 'Rider Summary Report',
        onPress: () =>
          navigate('home', {
            screen: 'riderIssues',
            params: {
              deliveryId,
              riderIds: badgeRiderIssues?.reduce((acc, rider) => [...acc, rider?.id], []) || [],
            },
          }),
        badgeCount: badgeRiderIssues?.filter(r => r.sendNotes || r.medicalNotes).length,
      },
      {
        Icon: InstructorNotesIcon,
        text: 'Instructor Notes',
        badgeCount:
          Object.values(instructorNoters)?.reduce((prev, cur) => [...prev, ...cur.notes], [])
            ?.length || 0,
        onPress: () =>
          navigate('home', {
            screen: 'instructorNotes',
            params: { deliveryId: deliveryId, courseId: course.id },
          }),
      },
    ];

    return details;
  }, [delivery, courseRidersIds, instructorNoters]);
  // hooks

  // functions

  // returns
  if (!delivery) {
    return <FullScreenLoader />;
  }

  return (
    <Container>
      <Heading showSchoolIcon title={delivery.hostName} status={delivery.status} />

      <FlatList
        data={menuData}
        keyExtractor={item => item.text}
        renderItem={({ item }) => <MenuItem {...item} />}
        numColumns={2}
        ListHeaderComponent={() => (
          <Content>
            <DivRow style={genericStyles.w100}>
              <DivRow style={genericStyles.fill}>
                <CustomIcon name="groups" type="material" size={30} color="text" />
                <SpacerRow size={0.5} />
                <CustomText font="bodyBold" size={20}>
                  {delivery.riderAllocation}
                </CustomText>
              </DivRow>

              <DivRow style={genericStyles.fill}>
                <CustomIcon name="bicycle-outline" type="ionic" size={30} color="text" />
                <SpacerRow size={0.5} />
                <CustomText font="bodyBold" size={16}>
                  {course.courseLabelShortName}
                </CustomText>
              </DivRow>
            </DivRow>

            <SpacerColumn size={2} />

            <DivRow>
              <CustomIcon name="school" type="material" color="text" size={30} />
              <SpacerRow size={0.5} />
              <CustomText size={14}>
                {delivery.instructors.reduce(
                  (prev, cur, index) =>
                    index === delivery.instructors.length - 1
                      ? cur.instructorName + prev
                      : prev + ', ' + cur.instructorName,
                  '',
                )}
              </CustomText>
            </DivRow>
          </Content>
        )}
        ItemSeparatorComponent={() => <SpacerColumn size={2} />}
        ListFooterComponent={() => <SpacerColumn size={3} />}
        contentContainerStyle={{
          justifyContent: 'space-between',
          padding: layout.padding_x2,
        }}
        columnWrapperStyle={{ justifyContent: 'space-between' }}
      />
    </Container>
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));

const Content = styled.View(({ theme: { layout } }) => ({
  paddingBottom: layout.padding_x2,
}));
