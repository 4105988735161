import { User } from '@api/types';
import { DEMO_FULL_NAME } from './fakes';
import { ID_SUFFIX } from './keys';

export const reduceToTen = (num: number) => (num > 10 ? num - (num % 10) : num);
export const getFullName = (user: User) =>
  user ? `${user?.first_name} ${user?.last_name}` : DEMO_FULL_NAME;
export const addSuffix = (text: string | number) => `${text + ID_SUFFIX}`;
export const removeSuffix = (text: string) => text.replace(ID_SUFFIX, '');
export const flexSearch = (string: string, substring: string) => {
  const letters = [...string.toLowerCase()];
  if (!substring || !string) {
    return true;
  }

  return [...substring.toLowerCase()].every(x => {
    const index = letters.indexOf(x);
    if (~index) {
      letters.splice(index, 1);
      return true;
    }
  });
};
