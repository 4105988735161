// libraries
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/native';
import PinView, { PinViewProps } from 'react-native-pin-view';
import { useTheme } from 'styled-components/native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { showMessage } from 'react-native-flash-message';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';

// components
import { CustomIcon, CustomText } from '@components/atoms';

// misc
import { fonts } from '@styles/fonts';
import { getSecretPin, setSecretPin } from '@utils/secure-storage';
import { useUser } from '@context/UserProvider';
import { AuthStackParamList } from '@utils/navigation';
import { PIN_SET_TIME } from '@utils/keys';
import { PIN_EXPIRE_DAYS } from '@utils/encryption';
import { PILOT_MODE } from '@env';

export enum AuthPinMode {
  SET = 'set',
  ENTER = 'enter',
}

// todo: fix ts error for this file
export const AuthPinScreen = () => {
  // variables
  const pinView = useRef<PinViewProps>(null);
  const { colors, layout } = useTheme();
  const [pin, setPin] = useState('');
  const {
    params: { type },
  } = useRoute<RouteProp<AuthStackParamList, 'authPin'>>();
  const { setOptions } = useNavigation();
  const {
    cacheAuth,
    isRememberMeChoosen,
    getAuthUser,
    setIsAuthComplete,
    isUserLoggedIn,
    resetAuth,
    oneTimeAuth,
  } = useUser();
  const { navigate } = useNavigation<NativeStackNavigationProp<AuthStackParamList>>();

  const isPilotMode = PILOT_MODE === 'true'; // Assuming PILOT_MODE is correctly imported from @env

  // hooks
  useEffect(() => {
    if (pin.length === 4) updateOrCheckPin();
  }, [pin]);

  useEffect(() => {
    if (!isUserLoggedIn && type === AuthPinMode.ENTER) {
      navigate('signIn');
    }
  }, [isUserLoggedIn]);

  useEffect(() => {
    setOptions({ title: type === AuthPinMode.SET ? ( isPilotMode ? 'Set Pin - Training' : 'Set Pin' ) : ( isPilotMode ? 'Enter Pin - Training' : 'Enter Pin' ) });

    if (type === AuthPinMode.ENTER) {
      checkIfPinExpired();
    }
  }, []);

  // functions
  const updateOrCheckPin = async () => {
    if (type === AuthPinMode.SET) {
      setSecretPin(pin);
      if (isRememberMeChoosen) {
        await AsyncStorage.setItem(PIN_SET_TIME, moment().toISOString());
        cacheAuth();
      } else {
        oneTimeAuth();
      }
    }

    if (type === AuthPinMode.ENTER) {
      const currentPin = getSecretPin();
      if (currentPin === pin) {
        await getAuthUser();
        setIsAuthComplete(true);
      } else {
        showMessage({
          message: 'Pin is Incorrect!',
          type: 'danger',
          duration: 2000
        });
        pinView.current?.clearAll();
      }
    }
  };

  const checkIfPinExpired = async () => {
    const pinSetTimestamp = await AsyncStorage.getItem(PIN_SET_TIME);
    if (!pinSetTimestamp) {
      expirePin();
    } else {
      const currentDate = moment();
      const setDate = moment(pinSetTimestamp);

      if (currentDate.diff(setDate, 'days') > PIN_EXPIRE_DAYS) {
        expirePin();
      }
    }
  };

  const expirePin = () => {
    resetAuth();
    showMessage({
      message: 'Your pin session has expired, login again to continue.',
      type: 'warning',
    });
  };

  return (
    <PinView
      ref={pinView}
      pinLength={4}
      style={{
        backgroundColor: colors.primaryBackground,
        flex: 1,
      }}
      onValueChange={setPin}
      inputAreaStyle={{
        marginVertical: 40,
      }}
      inputViewEmptyStyle={{
        backgroundColor: colors.transparent,
        borderColor: colors.text,
        borderWidth: 1,
      }}
      inputViewFilledStyle={{
        backgroundColor: colors.text,
      }}
      inputSize={30}
      buttonViewStyle={{
        backgroundColor: colors.transparent,
        borderColor: colors.text,
        borderWidth: 1,
        marginBottom: layout.padding_x2,
      }}
      buttonTextStyle={{
        borderColor: colors.text,
        fontFamily: fonts.family.bodyLight,
      }}
      onButtonPress={key => {
        key === 'custom_left' && resetAuth();
        key === 'custom_right' && pinView.current?.clear();
      }}
      customLeftButton={<CustomText>Login</CustomText>}
      customRightButton={<CustomIcon color="text" name="delete" size={40} />}
    />
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));
