import React from 'react';
import {
  LinkingOptions,
  NavigatorScreenParams,
  RouteProp,
  useNavigation,
} from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { BottomTabBarButtonProps } from '@react-navigation/bottom-tabs';
import { AuthPinMode } from '@screens/authentication/AuthPinScreen';
import { LoginRequest } from '@api/useLogin';

export type RouteItem<T = RootStackParamList> = {
  name: keyof T;
  component: React.ComponentType;
  tabBarButton?: (props: BottomTabBarButtonProps) => React.ReactNode;
  header?: () => React.ReactNode;
  title?: string;
  forRole?: 'only-guest' | 'only-loggedin-user';
};

export type AuthStackParamList = {
  signIn: undefined;
  authorise: LoginRequest;
  forgotPassword: undefined;
  authPin: { type: AuthPinMode };
};

export type DashboardStackParamList = {
  dashboard: undefined;
  news: { id?: number };
  deliveries: undefined;
  deliverySummary: { deliveryId: number };
  courseInfo: { deliveryId: number; courseId: number };
  deliveryDetail: { id: number };
  riderIssues: { deliveryId: number; riderIds?: number[] };
  allRiders: { deliveryId: number; courseId?: number; isClearRiders?: boolean };
  riderDetail: { deliveryId: number; riderId: number };
  training: { deliveryId: number; courseId?: number };
  trainingSurvey: { deliveryId: number; courseId?: number };
  instructorFeedback: { deliveryId: number; courseId?: number; riderId?: number };
  characteristics: { deliveryId: number; courseId?: number };
  instructorNotes: { deliveryId: number; courseId?: number };
  riderInstructorNotes: { deliveryId: number; courseId?: number; riderId?: number };
  trainingChecklist: { deliveryId: number; courseId?: number };
};

export type AccountStackParamList = {
  account: undefined;
  changePassword: undefined;
  changePin: undefined;
};

export type TabNavigatorParamList = {
  home: NavigatorScreenParams<DashboardStackParamList>;
  account: undefined;
  help: undefined;
  myDeliveries: undefined;
  logout: undefined;
};

export type RootStackParamList = {
  auth: NavigatorScreenParams<AuthStackParamList>;
  authorised: NavigatorScreenParams<TabNavigatorParamList>;
};

export type AppNavigationProp = NativeStackNavigationProp<RootStackParamList>;

export type ScreenFC<T extends keyof RootStackParamList> = React.FC<{
  navigation: NativeStackNavigationProp<RootStackParamList, T>;
  route: RouteProp<RootStackParamList, T>;
}>;

export const useAppNavigation = () => useNavigation<AppNavigationProp>();

export const linking: LinkingOptions<RootStackParamList> = {
  config: {
    screens: {},
  },
  prefixes: [],
};
