// libraries
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// import { useGetDeliveryCourseAndRiders } from '@hooks/useGetDeliveryCourseAndRiders';
import { useAppSelector } from '@redux/store';
import { selectSelectedRiders } from '@screens/dashboard/selectors';

// misc
import {
  DeliveriesDataType,
  DeliveryDatumRider,
  DeliveryState,
  StateGeneralInstructorNote,
  StateInstructorNote,
} from './types';
import {
  Activity,
  Characteristic,
  DeliverySaveBodyCourse,
  InstructorFeedback,
  InstructorNote,
  Rider,
  RiderCourse,
  TrainingSurvey,
} from '@api/types';
import cache from '@utils/cache';
import { DELIVERY_SELECTED_RIDERS_KEY } from '@utils/keys';

export const initialState: DeliveryState = {
  apiData: null,
  structuredData: null,
  characteristics: [],
  generalInstructorNotes: [],
  instructorNotes: {},
  selectedRiders: {},
  savedDeliveries: {},
  riders: {},
};

type allSubTypesOfUnionType<T> = T extends any ? T[keyof T] : never;

export const deliveriesSlice = createSlice({
  name: 'deliveries',
  initialState,
  reducers: {
    setDeliveries(state, action: PayloadAction<{ data: DeliveriesDataType }>) {
      state.apiData = action.payload.data;
      const structuredData: DeliveryState['structuredData'] = {} as DeliveryState['structuredData'];
      const instructorNotes: DeliveryState['instructorNotes'] = {};
      const generalInstructorNotes: DeliveryState['generalInstructorNotes'] = {};
      action.payload.data.deliveryData.forEach(({ deliveries, ...data }) => {
        structuredData[deliveries.id] = {
          ...deliveries,
          ...data,
        };
      });

      const allRiders: { [riderId: number]: DeliveryDatumRider } = {};
      action.payload.data.deliveryData.forEach(({ deliveries, riders }) => {
        const notes: { [instructorId: number]: StateInstructorNote } = {};
        const generalNotes: { [instructorId: number]: StateGeneralInstructorNote } = {};
        riders.forEach(rider => {
          rider?.instructorNotes?.forEach(instructor => {
            instructor.notes.forEach(note => {
              notes[instructor.instructorId] = {
                ...instructor,
                notes: [
                  ...(notes[instructor.instructorId]?.notes || []),
                  { riderId: rider.id, ...note },
                ],
              };
            });
          });

          allRiders[rider.id] = rider;
        });

        instructorNotes[deliveries.id] = notes;
        deliveries?.instructor_general_notes?.forEach(instructor => {
          instructor.notes.forEach(note => {
            generalNotes[instructor.instructorId] = {
              ...instructor,
              notes: [...(generalNotes[instructor.instructorId]?.notes || []), { ...note }],
            };
          });
        });
        generalInstructorNotes[deliveries.id] = generalNotes;
      });

      state.riders = allRiders;

      state.structuredData = structuredData;
      state.instructorNotes = instructorNotes;

      state.generalInstructorNotes = generalInstructorNotes;
      state.characteristics = action.payload.data.lookupData.characteristics;
    },
    setSelectedRidersIds(
      state,
      action: PayloadAction<{ ridersIds: number[]; deliveryId: number; courseId: number }>,
    ) {
      const { deliveryId, courseId, ridersIds } = action.payload;
      const selectedRiders = {
        ...state.selectedRiders,
        [deliveryId]: {
          ...state.selectedRiders[deliveryId],
          [courseId]: state.structuredData[deliveryId].riders.filter(rider =>
            ridersIds.includes(rider.id),
          ),
        },
      };

      state.selectedRiders = selectedRiders;
      cache.store(DELIVERY_SELECTED_RIDERS_KEY, selectedRiders);
    },
    setSelectedRiders(state, action: PayloadAction<DeliveryState['selectedRiders']>) {
      state.selectedRiders = action.payload;
    },

    setDeliveryCharaceteristics(
      state,
      action: PayloadAction<{
        characteristics: Characteristic[];
        deliveryId: number;
        courseId: number;
      }>,
    ) {
      const { deliveryId, characteristics, courseId } = action.payload;
      const deliveries = {
        ...state.savedDeliveries,
      };

      const currentCourse = state.savedDeliveries[deliveryId]?.riderCharacteristics?.find(
        course => course.id === courseId,
      );

      if (currentCourse) {
        deliveries[deliveryId].riderCharacteristics = [
          ...deliveries[deliveryId].riderCharacteristics.filter(c => c.id !== courseId),
          { ...currentCourse, characteristics },
        ];
      } else {
        deliveries[deliveryId] = {
          ...(deliveries[deliveryId] || { id: deliveryId, riders: [], courses: [] }),
          riderCharacteristics: [
            ...(deliveries[deliveryId]?.riderCharacteristics || []),
            { id: courseId, characteristics },
          ],
        };
      }

      state.savedDeliveries = deliveries;
    },
    setSaveDeliveryCourse(
      state,
      action: PayloadAction<{
        surveyQuestions?: TrainingSurvey[];
        deliveryId: number;
        courseId: number;
      }>,
    ) {
      const { deliveryId, surveyQuestions, courseId } = action.payload;
      const deliveries = {
        ...state.savedDeliveries,
      };

      const currentCourse = state.savedDeliveries[deliveryId]?.courses?.find(
        course => course.id === courseId,
      );

      const riders = state.selectedRiders[deliveryId][courseId]?.map(r => r.id);

      const modifyDeliveries = (
        key: keyof DeliverySaveBodyCourse,
        value: allSubTypesOfUnionType<DeliverySaveBodyCourse>,
      ) => {
        if (currentCourse) {
          deliveries[deliveryId].courses = [
            ...deliveries[deliveryId].courses.filter(c => c.id !== courseId),
            { ...currentCourse, training_survey: [], training_survey_riders: riders, [key]: value },
          ];
        } else {
          deliveries[deliveryId] = {
            ...(deliveries[deliveryId] || { id: deliveryId, riders: [], riderCharacteristics: [] }),
            courses: [
              ...(deliveries[deliveryId]?.courses || []),
              { id: courseId, training_survey: [], training_survey_riders: riders, [key]: value },
            ],
          };
        }
      };

      if (surveyQuestions !== undefined) {
        modifyDeliveries('training_survey', surveyQuestions);
      }

      state.savedDeliveries = deliveries;
    },

    setGeneralInstructorNotes(
      state,
      action: PayloadAction<{
        generalInstructorNotes?: InstructorNote[];
        deliveryId: number;
      }>,
    ) {
      const { generalInstructorNotes, deliveryId } = action.payload;
      const deliveries = {
        ...state.savedDeliveries,
      };
      const generalNotes = state.savedDeliveries[deliveryId]?.instructor_general_notes?.find(
        instructor => instructor.instructor_id === generalInstructorNotes[0]?.instructor_id,
      );
      if (generalNotes) {
        deliveries[deliveryId].instructor_general_notes = [
          ...deliveries[deliveryId].instructor_general_notes.filter(
            i => i.instructor_id !== generalNotes.instructor_id,
          ),
          { ...generalInstructorNotes[0] },
        ];
      } else {
        if (Object.keys(deliveries).length === 0) {
          deliveries[deliveryId] = {
            ...(deliveries[deliveryId] || {
              id: deliveryId,
              riders: [],
              riderCharacteristics: [],
              courses: [],
            }),
            instructor_general_notes: generalInstructorNotes,
          };
        } else {
          if (deliveries[deliveryId]){
            deliveries[deliveryId].instructor_general_notes = generalInstructorNotes;
          }
        }
      }

      state.generalInstructorNotes[deliveryId][generalInstructorNotes[0].instructor_id] = {
        ...generalInstructorNotes[0],
      };
      state.savedDeliveries = deliveries;
    },

    setSaveDeliveryRider(
      state,
      action: PayloadAction<{
        withdrawalText?: string;
        attended?: number;
        kitCheckText?: string;
        instructorNotes?: InstructorNote[];
        deliveryId: number;
        riderId: number;
      }>,
    ) {
      const { deliveryId, kitCheckText, riderId, attended, instructorNotes, withdrawalText } =
        action.payload;
      const deliveries = {
        ...state.savedDeliveries,
      };

      const currentRider = deliveries[deliveryId]?.riders?.find(
        course => course.rider_id === riderId,
      );

      const filterdRiders = deliveries[deliveryId]?.riders?.filter(c => c.rider_id !== riderId);

      const modifyDeliveries = (key: keyof Rider, value: allSubTypesOfUnionType<Rider>) => {
        if (currentRider) {
          deliveries[deliveryId].riders = [
            ...filterdRiders,
            {
              ...currentRider,
              [key]: value,
              withdrawn: key === 'withdrawal_reason' && value ? 1 : currentRider.withdrawn,
            },
          ];
        } else {
          deliveries[deliveryId] = {
            ...(deliveries[deliveryId] || {
              id: deliveryId,
              courses: [],
              riderCharacteristics: [],
            }),
            riders: [
              ...(deliveries[deliveryId]?.riders || []),
              {
                ...{
                  rider_id: riderId,
                  kitCheckNotes: '',
                  attended: 1,
                  courses: [],
                  instructor_notes: [],
                  withdrawn: key === 'withdrawal_reason' && value ? 1 : null,
                  withdrawal_reason: '',
                },
                [key]: value,
              },
            ],
          };
        }
      };

      if (kitCheckText) {
        modifyDeliveries('kitCheckNotes', kitCheckText);
      }

      if (attended !== undefined) {
        modifyDeliveries('attended', attended);
      }

      if (instructorNotes) {
        modifyDeliveries('instructor_notes', instructorNotes);
      }

      if (withdrawalText) {
        modifyDeliveries('withdrawal_reason', withdrawalText);
      }

      state.savedDeliveries = deliveries;
    },
    setDeliveryCompleteFlag(state, action: PayloadAction<{ deliveryId }>) {
      state.savedDeliveries[action.payload.deliveryId] = {
        delivery_complete: 2,
        ...(state.savedDeliveries[action.payload.deliveryId] || {
          id: action.payload.deliveryId,
          riderCharacteristics: [],
          riders: [],
          courses: [],
        }),
      };
    },
    setInstuctorNotes(state, action: PayloadAction<DeliveryState['instructorNotes']>) {
      state.instructorNotes = action.payload;
    },
    setSavedRiderCourse(
      state,
      action: PayloadAction<{
        withdrawalText?: string;
        feedback?: InstructorFeedback;
        activities?: Activity[];
        checkComplete?: boolean;
        deliveryId: number;
        courseId: number;
        riderId: number;
      }>,
    ) {
      const { deliveryId, feedback, riderId, courseId, activities, checkComplete, withdrawalText } =
        action.payload;

      const deliveries = {
        ...state.savedDeliveries,
      };

      const currentRider = state.savedDeliveries[deliveryId]?.riders?.find(
        rider => rider.rider_id === riderId,
      );
      const modifyDeliveries = (
        key: keyof RiderCourse,
        value: allSubTypesOfUnionType<RiderCourse>,
      ) => {
        const course = {
          id: courseId,
          withdrawal_reason: '',
          withdrawn: null,
          activities: [],
          instructor_feedback: {
            instructor_id: undefined,
            paragraphs: '',
            notes: '',
          },
          [key]: value,
        };

        if (currentRider) {
          const currentCourse = currentRider.courses.find(c => c.id === courseId);
          deliveries[deliveryId].riders = [
            ...deliveries[deliveryId].riders.filter(c => c.rider_id !== riderId),
            {
              ...currentRider,
              courses: [
                ...currentRider.courses.filter(c => c.id !== courseId),
                currentCourse
                  ? {
                      ...currentCourse,
                      [key]: value,
                      withdrawn: key === 'withdrawal_reason' && value ? 1 : currentCourse.withdrawn,
                    }
                  : course,
              ],
            },
          ];
        } else if (riderId) {
          deliveries[deliveryId] = {
            ...(deliveries[deliveryId] || {
              id: deliveryId,
              courses: [],
              riderCharacteristics: [],
            }),
            riders: [
              ...(deliveries[deliveryId]?.riders || []),
              {
                rider_id: riderId,
                courses: [course],
                instructor_notes: [],
                kitCheckNotes: '',
                attended: 1,
                withdrawn: null,
                withdrawal_reason: '',
              },
            ],
          };
        }
      };

      if (feedback) {
        modifyDeliveries('instructor_feedback', feedback);
      }

      if (activities) {
        modifyDeliveries('activities', activities);
      }

      if (checkComplete) {
        modifyDeliveries('complete', 1);
      }

      if (withdrawalText) {
        modifyDeliveries('withdrawal_reason', withdrawalText);
      }

      state.savedDeliveries = deliveries;
    },
    setSavedDeliveries(state, action: PayloadAction<DeliveryState['savedDeliveries']>) {
      state.savedDeliveries = action.payload;
    },
    clearSavedDeliveries(state) {
      state.savedDeliveries = {};
    },
    clearCharacteristics(state, action: PayloadAction<{ deliveryId: number }>) {
      if (state?.savedDeliveries[action.payload.deliveryId]?.riderCharacteristics)
        state.savedDeliveries[action.payload.deliveryId].riderCharacteristics = [];
    },
    clearRiders(state, action: PayloadAction<{ deliveryId: number }>) {
      if (state?.savedDeliveries[action.payload.deliveryId]?.riders)
        state.savedDeliveries[action.payload.deliveryId].riders = [];
    },
    updatePresenceStatusOfRider(
      state,
      action: PayloadAction<{
        delivery;
        deliveryId;
        riderId: number;
        presenceValue: number;
      }>,
    ) {
      const apiData = JSON.parse(JSON.stringify(state.apiData));
      const deliveryData = apiData.deliveryData;
      const deliveryIndex = deliveryData.findIndex(
        deliveryObj => deliveryObj.deliveries.id === action.payload.deliveryId,
      );
      const riders = deliveryData[deliveryIndex].riders;
      const riderIndex = riders.findIndex(rider => rider.id === action.payload.riderId);
      apiData.deliveryData[deliveryIndex].riders[riderIndex].presenceStatus =
        action.payload.presenceValue;
      deliveriesSlice.caseReducers.setDeliveries(state, {
        payload: { data: apiData },
        type: '',
      });
    },
  },
});
