// libraries
import React from 'react';
import { FlatList } from 'react-native';
import styled from 'styled-components/native';

// components
import { CustomText, DivColumn, SpacerColumn } from '@components/atoms';

// misc
import { layout } from '@styles/layout';

export const SpecificRiderScreen = () => {
  // variables

  // hooks

  // functions

  // returns
  return (
    <Container>
      <SpacerColumn size={1.5} />

      <DivColumn jc="center" ai="center">
        <CustomText color="warning" font="bodyBold" size={24}>
          L2 Rider Progress
        </CustomText>

        <SpacerColumn size={1.5} />
        <CustomText color="text" font="bodyBold" size={18}>
          Julie Smith
        </CustomText>
      </DivColumn>

      <SpacerColumn size={3} />

      <FlatList
        data={[{}, {}, {}]}
        renderItem={() => (
          <DetailSectionContainer>
            <CustomText>05.05.2023 - Julie Smith</CustomText>
            <CustomText>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque at tempor
              libero. Pellentesque suscipit libero sed leo auctor gravida. Etiam ultrices dictum
              enim ut consequat. Maecenas in pretium odio, viverra vulputate felis.
            </CustomText>
          </DetailSectionContainer>
        )}
        ItemSeparatorComponent={() => <SpacerColumn size={4} />}
        contentContainerStyle={{ padding: layout.padding_x2, flex: 1 }}
      />
    </Container>
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));

const DetailSectionContainer = styled.View(() => ({}));
