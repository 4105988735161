// libraries
import React, { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View, Text } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

// components
import { CollapsableSection } from '@components/molecules';
import {
  CustomIcon,
  CustomPressable,
  CustomText,
  Heading,
  SmallButton,
  SpacerColumn,
  SpacerRow,
} from '@components/atoms';
import { AddInstructorsNotesModal } from '@components/organisms';

// misc
import { DashboardStackParamList } from '@utils/navigation';
import { genericStyles } from '@styles/genericStyles';
import { useAppSelector } from '@redux/store';
import {
  selectAllRiders,
  selectGeneralInstructorNotes,
  selectInstructorNotesByRiders,
} from '@screens/dashboard/selectors';
import { formatDateToSimple } from '@utils/date';
import { useGetDeliveryCourseAndRiders } from '@hooks/useGetDeliveryCourseAndRiders';
import { useUser } from '@context/UserProvider';
import { StateInstructorNote } from '@screens/dashboard/types';
import moment from 'moment';

export const InstructorNotesScreen = () => {
  // variables
  const { t } = useTranslation();
  const {
    params: { deliveryId, courseId },
  } = useRoute<RouteProp<DashboardStackParamList, 'instructorNotes'>>();
  const [isInstructorNotesVisible, toggleInstructorNotes] = useReducer(value => !value, false);
  const riders = useAppSelector(selectAllRiders);
  const { user } = useUser();
  const { riderIds, delivery } = useGetDeliveryCourseAndRiders();
  const instructorNotes = useAppSelector(state =>
    selectInstructorNotesByRiders(state, deliveryId, courseId ? riderIds : null),
  );
  const instructorGeneralNotes = useAppSelector(state =>
    selectGeneralInstructorNotes(state, delivery.id),
  );

  const instructorNotesById = { ...instructorNotes[user.id] };
  let allInstructorNotes: any[];
  if (instructorGeneralNotes || instructorNotesById) {
    const savedGenralInstructorNote = instructorGeneralNotes[user.id];

    const allNotes =
      savedGenralInstructorNote && instructorNotesById && instructorNotesById.notes
        ? savedGenralInstructorNote.notes.concat(instructorNotesById.notes)
        : savedGenralInstructorNote
        ? savedGenralInstructorNote.notes
        : instructorNotesById && instructorNotesById.notes
        ? instructorNotesById.notes
        : [];
    allInstructorNotes = allNotes.slice().sort((left, right) => {
      return moment(right.datetime).diff(moment(left.datetime));
    });
  }

  const { layout } = useTheme();
  const { navigate } = useNavigation<NativeStackNavigationProp<DashboardStackParamList>>();

  // hooks

  // functions

  // returns
  return (
    <Container>
      <Heading title={delivery.hostName} status={delivery.status} showSchoolIcon />

      <SpacerColumn size={1} />

      <NotesContainer>
        <CustomIcon name="info" size={24} color="text" />
        <SpacerRow size={1} />
        <CustomText font="bodyMedium">{t('screens.notes.instructorNotes')}</CustomText>
      </NotesContainer>

      <SpacerColumn size={1} />

      <Content>
        <ScrollView contentContainerStyle={{ paddingHorizontal: layout.padding_x1 }}>
          {Object.values(
            Object.keys(instructorNotes).length ? instructorNotes : instructorGeneralNotes,
          ).map((ins, insIndex) => (
            <View key={'insIndex' + ins.instructorId + insIndex}>
              <CollapsableSection
                title={`${ins.instructorName} - ${formatDateToSimple(
                  ins.notes[Object.keys(instructorNotes).length ? 0 : allInstructorNotes.length - 1]
                    ?.datetime,
                  '/',
                  null,
                )}`}>
                {ins.instructorId !== user.id
                  ? ins.notes.map((note, insNoteIndex) => (
                      <DetailSectionContainer
                        key={'insNoteIndex' + note.riderId + insNoteIndex}
                        onPress={
                          courseId &&
                          (() =>
                            navigate('riderInstructorNotes', {
                              deliveryId,
                              courseId,
                              riderId: note.riderId,
                            }))
                        }>
                        <Text>
                          {formatDateToSimple(note.datetime, '.', null)} -{' '}
                          {riders[note.riderId] ? riders[note.riderId].name : 'General Notes'}
                          <CustomText style={{ color: 'blue' }}>
                            {courseId ? ' (more)' : ''}
                          </CustomText>
                        </Text>

                        <CustomText>{note.note_text}</CustomText>
                      </DetailSectionContainer>
                    ))
                  : allInstructorNotes.map((note, noteIndex) => (
                      <DetailSectionContainer
                        key={'noteIndex' + noteIndex}
                        onPress={
                          courseId &&
                          note.riderId &&
                          (() =>
                            navigate('riderInstructorNotes', {
                              deliveryId,
                              courseId,
                              riderId: note.riderId,
                            }))
                        }>
                        <Text>
                          {formatDateToSimple(note.datetime, '.', null)} -{' '}
                          {note.riderId ? riders[note.riderId].name : 'General'}
                          <CustomText style={{ color: 'blue' }}>
                            {note.riderId && courseId ? ' (more)' : ''}
                          </CustomText>
                        </Text>
                        <CustomText>{note.note_text}</CustomText>
                      </DetailSectionContainer>
                    ))}
              </CollapsableSection>
              <SpacerColumn size={2} />
            </View>
          ))}
        </ScrollView>

        {/* {!!courseId && ( */}
        <Footer>
          <SmallButton title={t('screens.notes.newNote')} onPress={toggleInstructorNotes} />
        </Footer>
        {/* )} */}

        <AddInstructorsNotesModal
          onClose={toggleInstructorNotes}
          isVisible={isInstructorNotesVisible}
        />
      </Content>
    </Container>
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));

const Content = styled.View(() => ({
  flex: 1,
}));

const DetailSectionContainer = styled(CustomPressable)(({ theme: { layout } }) => ({
  padding: layout.padding_x2,
}));

const NotesContainer = styled.View(({ theme: { colors, layout } }) => ({
  backgroundColor: colors.warning,
  padding: layout.padding_x1,
  ...genericStyles.row,
}));

const Footer = styled.View(({ theme: { layout } }) => ({
  ...genericStyles.jcAiCenter,
  position: 'absolute',
  bottom: layout.padding_x2,
  width: '100%',
}));
