// libraries
import { useEffect } from 'react';

// misc
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectInstructorNotes, selectSavedDeliveries } from '@screens/dashboard/selectors';
import { DeliveryState } from '@screens/dashboard/types';
import { DeliverySaveBody } from '@api/types';
import { useUser } from '@context/UserProvider';
import { deliveriesSlice } from '@screens/dashboard/slice';
import cache from '@utils/cache';
import { DELIVERY_SAVE_KEY } from '@utils/keys';

export const usePopulateDelivery = () => {
  const { user } = useUser();

  const instructorNotes = useAppSelector(selectInstructorNotes);
  const savedDeliveries = useAppSelector(selectSavedDeliveries);
  const dispatch = useAppDispatch();

  useEffect(() => {
    //todo: add one time only
    const updatedNotes: DeliveryState['instructorNotes'] = {};
    for (const [deliveryId, notes] of Object.entries(instructorNotes || {})) {
      const saveDel: DeliverySaveBody = savedDeliveries[deliveryId];
      const notesCopy = { ...notes };

      if (saveDel) {
        const instructorDates: string[] = [];
        Object.values(notes).forEach(val =>
          val.notes.forEach(note => instructorDates.push(note.datetime + note.note_text)),
        );

        updatedNotes[deliveryId] = { ...notes };
        saveDel.riders.forEach(rider => {
          if (rider.instructor_notes[0]) {
            rider.instructor_notes[0].notes.forEach(note => {
              if (!instructorDates.includes(note.datetime + note.note_text)) {
                notesCopy[user?.id] = {
                  instructorId: user?.id,
                  instructorName: `${user?.first_name} ${user?.last_name}`,
                  notes: [
                    ...(notes[user?.id]?.notes || []),
                    { riderId: rider.rider_id, note_text: note.note_text, datetime: note.datetime },
                  ],
                };
              }
            });
          }
        });
      }

      updatedNotes[deliveryId] = notesCopy;
    }

    dispatch(deliveriesSlice.actions.setInstuctorNotes(updatedNotes));

    store();
  }, [JSON.stringify(savedDeliveries)]);

  const store = async () => {
    if (Object.keys(savedDeliveries || {}).length)
      await cache.store(DELIVERY_SAVE_KEY, savedDeliveries);
  };
};
