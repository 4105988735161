// libraries
import React, { useMemo, useRef } from 'react';
import styled from 'styled-components/native';
import { ActionSheetRef } from 'react-native-actions-sheet';
import { FieldValues, Path, PathValue, UseFormSetValue, useWatch } from 'react-hook-form';
import { View, ViewStyle } from 'react-native';

// components
import {
  CustomIcon,
  CustomText,
  Input,
  InputProps,
  RadioButton,
  SpacerColumn,
} from '@components/atoms';
import { CustomActionSheet } from './CustomActionSheet';

// styles
import { layout } from '@styles/layout';

export type SelectInputDataType<T> = {
  label: string;
  value: PathValue<T, Path<T>>;
}[];

export interface SelectInputProps<T extends FieldValues> extends InputProps<T> {
  data: SelectInputDataType<T>;
  onChangeValue: UseFormSetValue<T>;
  onChange?: () => void;
  sheetTitle: string;
  containerStyle?: ViewStyle;
}

export const SelectInput = <T extends FieldValues>({
  data,
  onChangeValue,
  onChange,
  sheetTitle,
  containerStyle,
  ...restProps
}: SelectInputProps<T>) => {
  // variables
  const actionSheetRef = useRef<ActionSheetRef>(null);
  const value = useWatch({ control: restProps.control, name: restProps.name });
  const displayValue = useMemo(() => data.find(d => d.value === value)?.label || '', [value, data]);

  // returns
  return (
    <Container onPress={() => actionSheetRef.current.show()}>
      <Input
        inputContainerStyle={containerStyle}
        editable={false}
        displayValue={displayValue}
        {...restProps}>
        <CustomIcon name="chevron-down" size={12} color="text" />
      </Input>

      <CustomActionSheet ref={actionSheetRef}>
        <View
          style={{
            padding: layout.padding_x3,
          }}>
          <CustomText size={20} font="bodyMedium">
            {sheetTitle}
          </CustomText>

          <SpacerColumn size={3} />
          {data.map(item => (
            <View
              key={item.value}
              style={{
                paddingBottom: layout.padding_x2,
              }}>
              <RadioButton
                onPress={() => {
                  onChangeValue(restProps.name, item.value);
                  onChange && onChange();
                  actionSheetRef.current.hide();
                }}
                label={item.label}
                value={item.value === value}
              />
            </View>
          ))}
        </View>
      </CustomActionSheet>
    </Container>
  );
};

const Container = styled.Pressable({});
