// libraries
import React from 'react';
import { Control } from 'react-hook-form';
import styled from 'styled-components/native';

// components
import { CustomModal } from '@components/molecules';
import { CustomText, Input, SmallButton, SpacerColumn } from '@components/atoms';

// misc
import { genericStyles } from '@styles/genericStyles';
import { DeliveryDatumRider } from '@screens/dashboard/types';
import { InstructoryFeedbackFormType } from '@screens/rider';
import alert from '@utils/alert';

// types
export type AddInstructorFeedbackModalProps = {
  isVisible: boolean;
  onClose?(): void;
  rider: DeliveryDatumRider;
  onSubmit: () => void;
  control: Control<InstructoryFeedbackFormType>;
  isLoading?: boolean;
};

export const AddInstructorFeedbackModal: React.FC<AddInstructorFeedbackModalProps> = ({
  isVisible,
  onClose,
  rider,
  control,
  onSubmit,
  isLoading,
}) => {

  // handle form submission
  const handleSubmitForm = () => {
    onSubmit(); // Call onSubmit function
    onClose(); // Close the modal after form submission
  };

  // Handle close button of popup.
  const handleClose = () => {
    const note = control._formValues.notes;
    if (note !== '') {
      alert('Instructor Feedback', 'You have unsaved data, would you like to save it now?', [
        {
          text: 'Ok',
          onPress: () => {
            onSubmit();
            onClose();
          },
        },
        {
          text: 'Cancel',
          onPress: () => onClose(),
          style: 'cancel',
        },
      ]);
    } else {
      onClose();
    }
  };

  // renders
  return (
    <CustomModal isVisible={isVisible} title="Instructor Feedback" onClose={handleClose}>
      <Content>
        <CustomText textAlign="center" font="bodyBold">
          {rider?.name}
        </CustomText>
        <SpacerColumn size={2} />
        <CustomText textAlign="center" font="bodyBold">
          Please enter your instructor feedback below.
          This feedback will be emailed to the parent and child once you mark the course as complete
        </CustomText>
        <SpacerColumn size={2} />

        <Input
          title="Notes"
          fontSize={14}
          name="notes"
          control={control}
          multiline
          numberOfLines={30}
          containerStyle={{ flex: 1 }}
        />
        <SpacerColumn size={3} />

        <SmallButton title="Add to record" onPress={handleSubmitForm} isLoading={isLoading} />
        <SpacerColumn size={1} />
      </Content>
    </CustomModal>
  );
};

const Content = styled.View(({ theme: { layout } }) => ({
  ...genericStyles.fill,
  padding: layout.padding_x2,
}));
