// libraries
import React, { useCallback } from 'react';
import styled from 'styled-components/native';

// components
import { CustomText } from './CustomText';
import { SpacerRow } from './Spacer';

// misc
import { ColorPalette } from '@styles/types';
import { genericStyles } from '@styles/genericStyles';
import SchoolIcon from '@assets/images/school.svg';
import { DELIVERY_STATUS } from '@utils/deliveries';

// types

export type HeadingProps = {
  title: string;
  status?: keyof typeof DELIVERY_STATUS;
  showSchoolIcon?: boolean;
  LeftComponent?: () => React.ReactElement;
};

export const Heading = ({ title, status, showSchoolIcon, LeftComponent }: HeadingProps) => {
  // variables

  // renders
  const RenderBadge = useCallback(() => {
    switch (status) {
      case 2:
        return <ColorBadge color="success" />;
      case 0:
        return <ColorBadge color="error" />;
      default:
        return <ColorBadge color="warning" />;
    }
  }, []);

  return (
    <Container>
      <ContentContainer>
        {showSchoolIcon && <SchoolIcon height={30} />}
        {LeftComponent && <LeftComponent />}
        <SpacerRow size={0.5} />

        <CustomText
          size={16}
          font="bodyBold"
          textAlign={showSchoolIcon ? 'left' : 'center'}
          style={{
            flex: 1,
            overflow: 'auto',
            height: title.length > 50 ? '40px' : 'auto',
          }}
          // style={genericStyles.fill}
        >
          {title}
        </CustomText>
      </ContentContainer>

      {showSchoolIcon && <RenderBadge />}
    </Container>
  );
};

const Container = styled.Pressable(({ theme: { colors } }) => ({
  backgroundColor: '#ECECEC', // colors.alto,
  height: 40,
  alignItems: 'center',
  ...genericStyles.rowWithCenterAndSB,
}));

const ContentContainer = styled.View(({ theme: { layout } }) => ({
  flex: 1,
  paddingHorizontal: layout.padding_x1,
  ...genericStyles.rowWithCenter,
}));

const ColorBadge = styled.View<{ color: ColorPalette }>(({ theme: { colors }, color }) => ({
  backgroundColor: colors[color],
  width: 15,
  height: '100%',
}));
