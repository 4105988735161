// libraries
import { ColorPalette } from '@styles/types';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components/native';

// components
import { CustomPressable, CustomText, SpacerColumn, CustomPressableProps } from '@components/atoms';

// misc
import NotesIcon from '@assets/icons/event-details.svg';
import SurveyIcon from '@assets/icons/instructor-notes.svg';
import FeedbackIcon from '@assets/icons/feedback.svg';
import CycleIcon from '@assets/icons/cycle.svg';
import ChecklistIcon from '@assets/icons/checklist.svg';
import { genericStyles } from '@styles/genericStyles';
import { layout } from '@styles/layout';
import { Badge } from '@components/atoms/Badge';

// types
export interface TrainingFooterButtonProps extends CustomPressableProps {
  type: 'notes' | 'survey' | 'feedback' | 'training' | 'check-complete';
  badgeCount?: number;
}

const ICON_SIZE = 24;

export const TrainingFooterButton: React.FC<TrainingFooterButtonProps> = ({
  type,
  badgeCount,
  ...restProps
}) => {
  // variables
  const color = useMemo((): ColorPalette => {
    switch (type) {
      case 'notes':
        return 'cornflowerBlue';

      case 'survey':
        return 'blueRomance';

      case 'feedback':
        return 'tequila';

      default:
        return 'cosmos';
    }
  }, [type]);

  const name = useMemo(() => {
    switch (type) {
      case 'notes':
        return 'Notes';

      case 'survey':
        return 'Survey';

      case 'feedback':
        return 'Feedback';

      case 'check-complete':
        return 'Check & Complete';
      default:
        return 'Training';
    }
  }, [type]);

  // functions

  // renders
  const renderIcon = useCallback(() => {
    switch (type) {
      case 'notes':
        return <NotesIcon width={ICON_SIZE} />;

      case 'survey':
        return <SurveyIcon width={ICON_SIZE} />;

      case 'feedback':
        return <FeedbackIcon width={ICON_SIZE} />;
      case 'check-complete':
        return <ChecklistIcon width={ICON_SIZE} />;
      default:
        return <CycleIcon width={ICON_SIZE} />;
    }
  }, [type]);

  return (
    <Container {...restProps}>
      <IconContainer color={color}>{renderIcon()}</IconContainer>
      <SpacerColumn size={0.5} />
      <CustomText color="primary" font="bodyBold" style={{ width: '5em', textAlign: 'center' }}>
        {name}
      </CustomText>
      {!!badgeCount && <Badge count={badgeCount} />}
    </Container>
  );
};

const Container = styled(CustomPressable)({
  ...genericStyles.aiCenter,
  paddingVertical: layout.padding_x1_5,
});

const IconContainer = styled.View<{ color: ColorPalette }>(({ theme: { colors }, color }) => ({
  ...genericStyles.jcAiCenter,
  height: 60,
  width: 60,
  backgroundColor: colors[color],
  borderRadius: 10,
}));
