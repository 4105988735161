// libraries
import React, { ReactElement, useLayoutEffect } from 'react';
import { useTheme } from 'styled-components/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';

// components
import AuthStacks from './stacks/AuthStacks';
import TabNavigator from './TabNavigator';
import { SplashScreen } from '@screens/authentication';

// types
import { RootStackParamList, RouteItem } from '@utils/navigation';
import { fonts } from '@styles/fonts';
import { useUser } from '@context/UserProvider';
import { useFilterRoutes } from '@hooks/useFilterRoutes';

const RootItems: RouteItem<RootStackParamList>[] = [
  {
    name: 'auth',
    component: AuthStacks,
    forRole: 'only-guest',
  },
  {
    name: 'authorised',
    component: TabNavigator,
    forRole: 'only-loggedin-user',
  },
];

const Stack = createStackNavigator<RootStackParamList>();

const RootNavigator = (): ReactElement => {
  // variables
  const { colors } = useTheme();
  const { getAuthUser, initLoading } = useUser();
  const modifyStack = useFilterRoutes(RootItems);
  const { t } = useTranslation();

  // hooks
  useLayoutEffect(() => {
    getAuthUser();
  }, []);

  // returns
  if (initLoading) {
    return <SplashScreen />;
  }

  return (
    <Stack.Navigator
      screenOptions={{
        headerShadowVisible: false,
        headerStyle: {
          backgroundColor: colors.primaryBackground,
        },
        headerTitleStyle: {
          fontFamily: fonts.family.heading,
          fontSize: 28,
        },
        animationEnabled: true,
        headerShown: false,
        gestureEnabled: false,
      }}
      initialRouteName="auth">
      {modifyStack.map(item => (
        <Stack.Screen
          key={item.name}
          name={item.name}
          component={item.component}
          options={{
            title: t(`navigation.${item.name}` as string),
          }}
        />
      ))}
    </Stack.Navigator>
  );
};

export default RootNavigator;
