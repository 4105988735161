// libraries
import React, { ReactNode } from 'react';
import Modal, { ModalProps } from 'react-native-modal';
import styled from 'styled-components/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

// components
import { CustomIcon, CustomText, SpacerColumn } from '@components/atoms';

// styles
import { genericStyles } from '@styles/genericStyles';
import { layout } from '@styles/layout';
import { theme } from '@styles/themes';

export interface CustomModalProps {
  isVisible: boolean;
  children: ReactNode;
  onClose: () => void;
  isDarkBackground?: boolean;
  hideClose?: boolean;
  headerContainerStyle?: StyleProp<ViewStyle>;
  renderHeader?: () => ReactNode;
  animationIn?: ModalProps['animationIn'];
  animationOut?: ModalProps['animationOut'];
  backdropColor?: string;
  containerColor?: string;
  containerStyle?: ViewStyle;
  title?: string;
  onBackdropPress?(): void;
  backdropOpacity?: number;
}

export const CustomModal = ({
  isVisible,
  children,
  onClose,
  isDarkBackground = false,
  renderHeader,
  headerContainerStyle,
  animationIn,
  animationOut,
  backdropColor = theme.colors.text,
  containerColor,
  containerStyle,
  title,
  onBackdropPress,
  backdropOpacity,
}: CustomModalProps) => {
  // variables
  const { top } = useSafeAreaInsets();

  // renders
  return (
    <Modal
      style={styles.container}
      isVisible={isVisible}
      onBackdropPress={onBackdropPress || onClose}
      animationIn={animationIn || 'fadeIn'}
      animationOut={animationOut || 'fadeOut'}
      hideModalContentWhileAnimating
      backdropTransitionOutTiming={0}
      onBackButtonPress={onClose}
      backdropOpacity={backdropOpacity || 0.8}
      backdropColor={backdropColor}>
      <Container color={containerColor} style={containerStyle}>
        <HeaderContainer top={top} onPress={onClose} style={headerContainerStyle}>
          {renderHeader !== undefined ? (
            renderHeader()
          ) : (
            <CustomIcon size={25} name="x" color={isDarkBackground ? 'white' : 'primary'} />
          )}
        </HeaderContainer>
        <SpacerColumn size={3} />
        <CustomText color="primary" size={20} font="bodyBold" textAlign="center">
          {title}
        </CustomText>
        {children}
      </Container>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, justifyContent: 'center', margin: 0, marginHorizontal: layout.padding_x1 },
});

const Container = styled.View<{ color?: string }>(({ color, theme: { colors } }) => ({
  backgroundColor: color || colors.white,
  height: '80%',
  borderRadius: 10,
}));

const HeaderContainer = styled.Pressable<{ top: number }>(({ theme: { layout }, top }) => ({
  ...genericStyles.positionAbsolute,
  top: layout.padding_x1,
  right: layout.padding_x1,
  zIndex: 2,
}));
