// libraries
import React, { ReactElement, ReactNode } from 'react';
import { ActivityIndicator, TextStyle } from 'react-native';
import styled, { useTheme } from 'styled-components/native';

// components
import { CustomPressable } from './CustomPressable';

// styles
import { fonts } from '@styles/fonts';
import { ColorPalette } from '@styles/types';

// types
interface BigButtonProps extends ContainerProps {
  text: string;
  onPress: () => void;
  isLoading?: boolean;
  leftIcon?: ReactNode;
  titleStyle?: TextStyle;
}

export const BigButton = ({
  text,
  onPress,
  outline = false,
  isLoading = false,
  isDisabled = false,
  leftIcon,
  titleStyle,
  color,
  width,
  minWidth,
}: BigButtonProps): ReactElement => {
  // variables
  const theme = useTheme();
  const colorHex = theme.colors[color];

  // renders
  return (
    <Container
      color={colorHex}
      onPress={onPress}
      isDisabled={isLoading || isDisabled}
      outline={outline}
      width={width}
      minWidth={minWidth}>
      {!!leftIcon === true && <Icon>{leftIcon}</Icon>}
      {isLoading ? (
        <ActivityIndicator
          color={outline ? colorHex || theme.colors.primary : theme.colors.primaryBackground}
          animating={isLoading}
        />
      ) : (
        <Title outline={outline} style={titleStyle} color={colorHex}>
          {text}
        </Title>
      )}
    </Container>
  );
};

interface ContainerProps {
  outline?: boolean;
  isDisabled?: boolean;
  color?: ColorPalette;
  width?: number | string;
  minWidth?: number | string;
}

const Container = styled(CustomPressable)<ContainerProps>(
  ({ outline, color, width, minWidth, theme: { colors, layout }, isDisabled }) => ({
    background: outline ? colors.white : color || colors.primary,
    minHeight: layout.buttons.height,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderWidth: outline ? 1 : 0,
    borderColor: outline ? color || colors.primary : colors.transparent,
    alignSelf: 'stretch',
    opacity: isDisabled ? 0.4 : 1,
    borderRadius: 50,
    width,
    minWidth,
    paddingLeft: layout.padding,
    paddingRight: layout.padding,
  }),
);

const Title = styled.Text<Omit<ContainerProps, 'isDisabled'>>(
  ({ outline, color, theme: { colors } }) => ({
    color: outline ? color || colors.primary : colors.primaryBackground,
    fontFamily: fonts.family.bodyMedium,
    fontSize: 18,
    textAlign: 'center',
  }),
);

const Icon = styled.View(({ theme: { layout } }) => ({
  marginRight: layout.padding_x1_5,
}));
