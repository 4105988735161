// libraries
import React, { useEffect, useReducer } from 'react';
import { View } from 'react-native';
import { useForm } from 'react-hook-form';

// componenets
import { AnimationExpand, CustomIcon, Input, SpacerColumn, SpacerRow } from '@components/atoms';
import { RiderKeys } from '@components/molecules';

// misc
import { genericStyles } from '@styles/genericStyles';
import { SortingModal, SortingModalProps } from '@components/organisms';

// types
export interface RiderHeaderToolsProps extends Pick<SortingModalProps, 'onSortingChange'> {
  RenderHeader: () => React.ReactElement;
  onChangeSearchText: (text: string) => void;
}

export const RiderHeaderTools: React.FC<RiderHeaderToolsProps> = ({
  RenderHeader,
  onChangeSearchText,
  onSortingChange,
}) => {
  // variables
  const [isSortingVisible, toggleIsSortingVisible] = useReducer(value => !value, false);
  const [isSearchVisible, toggleIsSearchVisible] = useReducer(value => !value, false);
  const { control, watch } = useForm<{ searchText: string }>();

  const searchText = watch('searchText');

  // hooks
  useEffect(() => {
    onChangeSearchText(searchText);
  }, [searchText]);

  // renders
  return (
    <View>
      <View style={genericStyles.rowWithSB}>
        <RiderKeys />
        <View style={genericStyles.rowWithCenter}>
          <CustomIcon
            name="search"
            color={isSearchVisible ? 'primary' : 'text'}
            onPress={toggleIsSearchVisible}
          />
          <SpacerRow size={1} />
          <CustomIcon name="filter" color="text" onPress={toggleIsSortingVisible} />
        </View>
      </View>
      {isSearchVisible && (
        <AnimationExpand>
          <Input
            control={control}
            name="searchText"
            title=""
            placeHolder="Enter your search text here"
          />
        </AnimationExpand>
      )}
      <SpacerColumn size={1} />
      <RenderHeader />
      <SpacerColumn size={1} />

      <SortingModal
        onSortingChange={onSortingChange}
        isVisible={isSortingVisible}
        onClose={toggleIsSortingVisible}
      />
    </View>
  );
};
