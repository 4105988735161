// libraries
import React, { useEffect, useReducer } from 'react';
import styled from 'styled-components/native';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useNavigation } from '@react-navigation/native';
import { Linking, Platform, Pressable } from 'react-native';

// components
import {
  BigButton,
  CustomIcon,
  CustomPressable,
  CustomText,
  Input,
  SpacerColumn,
  Heading,
  DivRow,
} from '@components/atoms';
import { Checkbox } from '@components/molecules';
// import {
//   SafariInstallInstructionModal,
//   ChromeInstallInstructionModal,
// } from '@components/organisms';

// misc
import { LoginFormType } from './types';
import { LoginRequest, useLogin } from '@api/useLogin';
import { useFormError } from '@hooks/useFormError';
import { AuthStackParamList } from '@utils/navigation';
import { genericStyles } from '@styles/genericStyles';
import { useUser } from '@context/UserProvider';
import { PRIVACY_POLICY_LINK } from '@env';
import { PILOT_MODE } from '@env';

export const SignInScreen = () => {
  // variables
  const { control, handleSubmit, setError, reset, getValues, setValue } = useForm<LoginFormType>({
    mode: 'all',
  });
  useForm({
    defaultValues: {
      remeberMe: true,
    },
  });
  // const [isChromeInstructionsVisible, toggleChromeInstructions] = useReducer(
  //   value => !value,
  //   false,
  // );
  // const [isSafariInstructionsVisible, toggleSafariInstructions] = useReducer(
  //   value => !value,
  //   false,
  // );

  const { navigate } = useNavigation<NativeStackNavigationProp<AuthStackParamList>>();
  const { t } = useTranslation();
  const { mutate, isLoading, error } = useLogin({
    onSuccess: () => {
      navigate('authorise', getValues());
      reset();
    },
  });
  const { setIsRememberMeChoosen, isRememberMeChoosen } = useUser();
  useFormError<LoginFormType, LoginRequest>(error, setError, reset);
  const [isVisiblePassword, toggleIsVisiblePassword] = useReducer(value => !value, false);

  // hooks
  // useEffect(() => {
  //   if (Platform.OS === 'web') {
  //     const isChrome = window.navigator.userAgent.toLocaleLowerCase().includes('chrome/');
  //
  //     if (isChrome) {
  //       toggleChromeInstructions();
  //     } else {
  //       toggleSafariInstructions();
  //     }
  //   }
  // }, []);

  // functions
  const onPressSubmit = ({ email, password, remeberMe }: LoginFormType) => {
    mutate({ email, password });
    setIsRememberMeChoosen(remeberMe === undefined ? isRememberMeChoosen : remeberMe);
  };

  // returns
  return (
    <>
      <Container contentContainerStyle={genericStyles.fill}>
        <Heading title={t('screens.signIn.note')} />

        <Content>
          <Input<LoginFormType>
            control={control}
            name="email"
            title="Instructor Email"
            placeHolder="jon.doe@example.com"
            rules={{ required: true }}
          />
          <SpacerColumn size={2.5} />
          <Input<LoginFormType>
            control={control}
            name="password"
            title={t('common.password')}
            placeHolder={'******'}
            secureTextEntry={!isVisiblePassword}
            rules={{ required: true }}>
            <CustomIcon
              name={isVisiblePassword ? 'eye' : 'eye-off'}
              onPress={toggleIsVisiblePassword}
            />
          </Input>
          <SpacerColumn size={1} />

          <Checkbox
            control={control}
            name="remeberMe"
            text={t('screens.signIn.rememberMe')}
            value={true}
            errorStyle={{ fontSize: 10, textAlign: 'center' }}
          />
          <SpacerColumn size={1} />

          <DivRow>
            <CustomText>
              By logging in to the Bikeability App, you agree to the terms and conditions of use which can be found in the Bikeability Trust Privacy Policy:
              <CustomPressable
                onPress={PRIVACY_POLICY_LINK && (() => Linking.openURL(PRIVACY_POLICY_LINK))}>
                <CustomText color="blue"> Privacy Policy | Bikeability</CustomText>
              </CustomPressable>
            </CustomText>
          </DivRow>
          {/* <Checkbox
            control={control}
            name="agreeTandC"
            text={
              <DivRow>
                <CustomText>
                  By logging in to the Bikeability App, you agree to the terms and conditions of use which can be found in the Bikeability Trust Privacy Policy:
                </CustomText>
                <Pressable
                  onPress={PRIVACY_POLICY_LINK && (() => Linking.openURL(PRIVACY_POLICY_LINK))}>
                  <CustomText color="blue">Privacy Policy | Bikeability</CustomText>
                </Pressable>
              </DivRow>
            }
            defaultValue={false}
            rules={{ required: 'Please agree to terms and conditions to continue' }}
            errorStyle={{ fontSize: 10, textAlign: 'center' }}
          /> */}
          <SpacerColumn size={1.75} />

          <CustomPressable onPress={() => navigate('forgotPassword')}>
            <CustomText color="primary">Forgot Password?</CustomText>
          </CustomPressable>
          <SpacerColumn size={4} />

          <BigButton
            text={t('common.step', { number: 2 })}
            onPress={handleSubmit(onPressSubmit)}
            isLoading={isLoading}
          />
          <SpacerColumn size={4} />

          <CustomText textAlign="center" style={{ width: '70%', alignSelf: 'center' }}>
            After login, you will receive a text to the mobile number stored on your Link account to authenticate access.
          </CustomText>
          <SpacerColumn size={2} />
        </Content>
      </Container>
      {/* <Footer>
        <CustomIcon type="feather" name="help-circle" color="boulder" size={34} />
      </Footer> */}
      {/*<ChromeInstallInstructionModal*/}
      {/*  // isVisible={isChromeInstructionsVisible}*/}
      {/*  isVisible={false}*/}
      {/*  onClose={toggleChromeInstructions}*/}
      {/*/>*/}
      {/*<SafariInstallInstructionModal*/}
      {/*  isVisible={isSafariInstructionsVisible}*/}
      {/*  onClose={toggleSafariInstructions}*/}
      {/*/>*/}
    </>
  );
};

const Container = styled.ScrollView(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
}));

const Content = styled.View(({ theme: { layout } }) => ({
  padding: layout.padding_x4,
  flex: 1,
}));

const isPilotMode = PILOT_MODE === 'true'; // Assuming PILOT_MODE is correctly imported from @env

const Footer = styled.View(({ theme: { colors } }) => ({
  height: 70,
  width: '100%',
  backgroundColor: isPilotMode ? colors.error : colors.secondary,
  justifyContent: 'center',
  alignItems: 'center',
}));
