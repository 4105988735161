// libraries
import { CustomModal } from '@components/molecules';
import React from 'react';
import styled, { useTheme } from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

// components
import { CustomPressable, CustomText, SpacerColumn } from '@components/atoms';

// misc
import { genericStyles } from '@styles/genericStyles';
import { DashboardStackParamList } from '@utils/navigation';
import { useGetDeliveryCourseAndRiders } from '@hooks/useGetDeliveryCourseAndRiders';

// types
export type ActivitesCompleteModalProps = {
  isVisible: boolean;
  onClose(): void;
  color: string;
  onRestartTraining(): void;
};

export const ActivitesCompleteModal: React.FC<ActivitesCompleteModalProps> = ({
  isVisible,
  onClose,
  color,
  onRestartTraining,
}) => {
  // variables
  const { colors } = useTheme();
  const backgroundColor = colors.white;
  const { navigate } = useNavigation<NativeStackNavigationProp<DashboardStackParamList>>();
  const { delivery, course } = useGetDeliveryCourseAndRiders();

  // functions
  const goToSurvey = () => {
    onClose();
    navigate('trainingSurvey', { deliveryId: delivery.id, courseId: course.id });
  };

  const goToFeeback = () => {
    onClose();
    navigate('instructorFeedback', { deliveryId: delivery.id, courseId: course.id });
  };

  const goToDeliverySummary = () => {
    onClose();
    navigate('deliverySummary', { deliveryId: delivery.id });
  };

  const goToTrainingChecklist = () => {
    onClose();
    navigate('home', {
      screen: 'trainingChecklist',
      params: { deliveryId: delivery.id, courseId: course.id },
    });
  };

  // renders
  return (
    <CustomModal
      isVisible={isVisible}
      onClose={onClose}
      backdropColor={color}
      backdropOpacity={1}
      containerColor={backgroundColor}
      title="Activites Complete">
      <Content>
        <CustomText size={14} font="bodyBold" textAlign="center">
          You have completed the outcomes required for this group of riders.
        </CustomText>
        <SpacerColumn size={3} />
        <CustomText size={16} font="bodyBold" textAlign="center">
          What would you like to do next?
        </CustomText>
        <SpacerColumn size={4} />

        <AButton onPress={goToFeeback}>
          <CustomText style={{ color: 'white' }} font="bodyBold">
            GIVE RIDER FEEDBACK
          </CustomText>
        </AButton>
        <SpacerColumn size={1.5} />

        <AButton onPress={goToSurvey}>
          <CustomText style={{ color: 'white' }} font="bodyBold">
            GO TO SURVEY
          </CustomText>
        </AButton>
        <SpacerColumn size={1.5} />

        <AButton
          onPress={() => {
            onClose();
            onRestartTraining();
          }}>
          <CustomText style={{ color: 'white' }} font="bodyBold">
            RESTART THIS TRAINING
          </CustomText>
        </AButton>
        <SpacerColumn size={1.5} />

        <AButton onPress={goToDeliverySummary}>
          <CustomText style={{ color: 'white' }} font="bodyBold">
            BACK TO DELIVERY SUMMARY
          </CustomText>
        </AButton>
        <SpacerColumn size={1.5} />

        <AButton onPress={goToTrainingChecklist}>
          <CustomText style={{ color: 'white' }} font="bodyBold">
            SET TRAINING COMPLETE
          </CustomText>
        </AButton>
      </Content>
    </CustomModal>
  );
};

const Content = styled.View(({ theme: { layout } }) => ({
  marginTop: layout.padding_x5,
  width: '80%',
  alignSelf: 'center',
  ...genericStyles.jcAiCenter,
}));

const AButton = styled(CustomPressable)(({ theme: { layout, colors } }) => ({
  ...genericStyles.jcAiCenter,
  width: layout.window.width * 0.7,
  backgroundColor: colors.primary,
  borderRadius: 10,
  padding: layout.padding_x1,
}));
