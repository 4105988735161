// libraries
import { useMutation } from 'react-query';

// misc
import { useErrorHandler } from '@hooks/useErrorHandler';
import request from '@utils/request';
import { DeliverySaveBody, ServerResponse } from './types';
import { ALL_RIDERS_ID } from '@components/organisms';
import { useUser } from "@context/UserProvider";

export type SaveDeliveryResponse = ServerResponse<null>;

export interface SaveDeliveryRequest {
  delivery: DeliverySaveBody;
}

export const useSaveDelivery = (
  savedDelivery,
  {
    onError,
    onSuccess,
  }: {
    onSuccess?: (res: SaveDeliveryResponse) => void;
    onError?: (res: ServerResponse<unknown>) => void;
  } = {},
) => {
  // variables
  const { triggerError } = useErrorHandler();

  const { user } = useUser();

  // request
  const query = useMutation<
    SaveDeliveryResponse,
    ServerResponse<unknown> | undefined,
    SaveDeliveryRequest
  >(['SaveDelivery'], async ({ delivery }: SaveDeliveryRequest) => {
    try {
      // const modifiedDelivery = { ...delivery };
      const modifiedDelivery = savedDelivery ? { ...savedDelivery } : { ...delivery };
      modifiedDelivery.riders = modifiedDelivery.riders.filter(
        rider => rider.rider_id !== ALL_RIDERS_ID,
      );
      const responseData = await request<ServerResponse<null>>({
        method: 'post',
        url: `/deliveries/outcome`,
        data: { instructor: user.id, appTimestamp: Date.now(), delivery: modifiedDelivery },
      });
      onSuccess(responseData);
      return responseData;
    } catch (error) {
      triggerError({ error });
      onError && onError(error);
    }
  });

  // return
  return query;
};
