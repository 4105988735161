// libraries
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components/native';
import { RouteProp, useRoute } from '@react-navigation/native';

// components
import { CustomText, DivColumn, DivRow, Heading, SpacerColumn, SpacerRow } from '@components/atoms';
import { FullScreenLoader } from '@components/molecules';

// misc
import { genericStyles } from '@styles/genericStyles';
import { useAppSelector } from '@redux/store';
import { DashboardStackParamList } from '@utils/navigation';
import {
  selectDeliveryById,
  selectRiderById,
  selectSavedRider,
} from '@screens/dashboard/selectors';
import { layout } from '@styles/layout';

export const RiderDetailsScreen = () => {
  // variables
  const {
    params: { deliveryId, riderId },
  } = useRoute<RouteProp<DashboardStackParamList, 'riderDetail'>>();
  const rider = useAppSelector(state => selectRiderById(state, deliveryId, riderId));
  const savedRider = useAppSelector(state => selectSavedRider(state, deliveryId, riderId));
  const delivery = useAppSelector(state => selectDeliveryById(state, deliveryId));

  const absentStatusText = useMemo(() => {
    if (savedRider?.attended !== undefined) {
      return savedRider.attended === 1 ? 'Present' : 'Absent';
    }

    return rider.presenceStatus === 1 ? 'Present' : 'Absent';
  }, [savedRider?.attended]);

  const consentText = useMemo(() => {
    switch (rider.consentStatus) {
      case 0:
        return 'Withdrawn';

      case 1:
        return 'Received';

      default:
        return 'Not Received';
    }
  }, []);

  // returns
  if (!rider) {
    return <FullScreenLoader />;
  }

  const RenderItem = useCallback(
    ({ title, description }: { title: string; description: string }) => (
      <ItemContainer>
        <CustomText color="text" font="bodyBold">
          {title}
        </CustomText>
        <SpacerRow size={1} />
        <CustomText>{description}</CustomText>
      </ItemContainer>
    ),
    [],
  );

  const DetailSection = useCallback(
    ({ title, value }: { title: string; value: string }) =>
      value ? (
        <>
          <CustomText color="primary">{title}</CustomText>
          <SpacerColumn size={1} />
          <CustomText>{value}</CustomText>
          <SpacerColumn size={2} />
        </>
      ) : null,
    [],
  );

  return (
    <Container>
      <Heading title={delivery.hostName} status={delivery.status} showSchoolIcon />

      <Content
        contentContainerStyle={{
          padding: layout.contentPadding,
        }}>
        <SpacerColumn size={1} />
        <CustomText textAlign="center" font="bodyBold" size={24} color="primary">
          {rider.name}
        </CustomText>
        <SpacerColumn size={2} />

        <RenderItem title="Year Group:" description={rider.yearGroup} />
        <RenderItem title="Parent/Guardian:" description={rider.parentGuardianName} />
        <RenderItem title="Contact Number:" description={rider.contactNumber} />
        <RenderItem title="Emergency Contact:" description={rider.emergencyContact} />
        <RenderItem title="Emergency Contact No:" description={rider.emergencyContactNumber} />
        <RenderItem title="Relationship:" description={rider.relationship} />
        <RenderItem title="Consent:" description={consentText} />
        <RenderItem title="Absent Status:" description={absentStatusText} />
        <RenderItem title="Has Bike:" description={rider.hasBike === 1 ? 'Yes' : 'No'} />
        <RenderItem
          title="Photography:"
          description={rider.photographyAllowed === 1 ? 'Yes' : 'No'}
        />
        <RenderItem
          title="Can ride a cycle:"
          description={rider.consentInformation.cannotRideFlag === true ? 'No' : 'Yes'}
        />

        <SpacerColumn size={1} />
        {rider.sendNotes && <DetailSection title="Send:" value={rider.sendNotes} />}
        {rider.medicalNotes && <DetailSection title="Medical Notes:" value={rider.medicalNotes} />}
        {rider.teacherNotes && <DetailSection title="Teacher Notes:" value={rider.teacherNotes} />}

        {Object.keys(rider?.consentInformation?.data)?.length > 0 && (
          <DivColumn flex={1}>
            <CustomText color="primary">Consent Information:</CustomText>
            <SpacerColumn size={1} />

            {Object.keys(rider?.consentInformation?.data).map(key => (
              <DivRow flex={1} key={key} ai="flex-start" jc="flex-start">
                <CustomText font="bodyBold">{key}:</CustomText>
                <SpacerRow size={2} />
                <DivColumn flex={1}>
                  {rider?.consentInformation?.data[key].map(value => (
                    <>
                      <CustomText key={value}>{value}</CustomText>
                      <SpacerColumn size={1} />
                    </>
                  ))}
                </DivColumn>
              </DivRow>
            ))}
            <SpacerColumn size={2} />
          </DivColumn>
        )}
      </Content>
    </Container>
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));

const Content = styled.ScrollView(() => ({}));

const ItemContainer = styled.View(({ theme: { layout } }) => ({
  ...genericStyles.rowWithCenter,
  marginBottom: layout.padding_x1,
}));
