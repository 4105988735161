// libraries
import { showMessage } from 'react-native-flash-message';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FlatList, ListRenderItemInfo } from 'react-native';
import styled from 'styled-components/native';
import { useForm } from 'react-hook-form';

// components
import { CustomText, SmallButton, SpacerColumn } from '@components/atoms';
import { PointCounter } from '@components/molecules';

// misc
import { layout } from '@styles/layout';
import { store as myStore } from '@redux/store';
import { useAppNavigation } from '@utils/navigation';
import { SurveyQuestion } from '@screens/dashboard/types';
import { useGetDeliveryCourseAndRiders } from '@hooks/useGetDeliveryCourseAndRiders';
import { useAppDispatch, useAppSelector } from '@redux/store';
import {
  selectCompleteTrainingCourseSurvey,
  selectSavedCourse,
} from '@screens/dashboard/selectors';
import { deliveriesSlice } from '@screens/dashboard/slice';
import { ID_SUFFIX } from '@utils/keys';
import { TrainingSurvey } from '@api/types';
import { addSuffix, removeSuffix } from '@utils/text';
import { useSubmitSavedDelivery } from '@hooks/useSubmitSavedDelivery';
import { selectSavedCoursesByDeliveryId } from '@screens/dashboard/selectors';
import { CustomIcon } from '@components/atoms/CustomIcon';
import alert from '@utils/alert';
import { useSaveSurvey } from '@api/useSaveSurvey';

type TrainingSurveyForm = { questions: { [questionId: string]: { [optionId: string]: string } } };

export const TrainingSurveyScreen = ({ navigation }) => {
  // variables
  const buttonRef = useRef();
  const [showDownArrow, setShowDownArrow] = useState(false);
  const { course, riderIds, delivery } = useGetDeliveryCourseAndRiders();
  const { setOptions, pop } = useAppNavigation();
  const savedSurvey = useAppSelector(state => selectSavedCoursesByDeliveryId(state, delivery?.id));

  const { mutate } = useSaveSurvey({
    onSuccess: data => {},
  });
  const { submit, isLoading } = useSubmitSavedDelivery('Survey questions updated successfully!', {
    onSuccess: () => {
      pop();
    },
  });

  const dispatch = useAppDispatch();
  let savedCourse = useAppSelector(state => selectSavedCourse(state, delivery.id, course.id));
  const completedSurveyCourse = useAppSelector(state =>
    selectCompleteTrainingCourseSurvey(state, delivery?.id),
  );

  if (savedCourse && completedSurveyCourse[0]) {
    if (savedCourse.id === completedSurveyCourse[0].id) {
      savedCourse = undefined;
    }
  }

  const { control, getValues, handleSubmit, setValue, trigger } = useForm<TrainingSurveyForm>({
    mode: 'all',
  });

  // hooks
  useEffect(() => {
    if (course) {
      setOptions({
        title: `${course.courseLabelShortName} Training Survey`,
        headerStyle: {
          backgroundColor: course.courseColour,
        },
      });
    }
    onScrollEnd();
  }, []);

  useEffect(() => {
    if (savedCourse) {
      let questions: TrainingSurveyForm['questions'] = {};

      savedCourse.training_survey.forEach(survey => {
        const options = survey.option.reduce(
          (prev, cur) => ({ ...prev, [addSuffix(cur.id)]: cur.total }),
          {},
        );

        questions = { ...questions, [addSuffix(survey?.question_id?.toString())]: options };
      });

      setValue('questions', questions, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  }, []);

  React.useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <CustomIcon
          name="arrow-left"
          color="black"
          onPress={() => {
            const savedCorses = myStore
              .getState()
              .deliveries.savedDeliveries[delivery.id]?.courses?.filter(
                courses => courses.id === course.id,
              );
            if (
              savedCorses &&
              savedCorses.length > 0 &&
              savedCorses[0].training_survey &&
              savedCorses[0].training_survey.length
            ) {
              alert('Training Survey', 'You have unsaved data, would you like to save it now?', [
                {
                  text: 'Ok',
                  onPress: () => {
                    handleSubmit(onPressSave)();
                  },
                },
                {
                  text: 'Cancel',
                  onPress: () => pop(),
                  style: 'cancel',
                },
              ]);
            }
          }}
        />
      ),
    });
  }, [navigation]);

  const onChangeOptionValue = () => {
    // trigger('questions');
    const updatedQuestions: TrainingSurvey[] = [];

    for (const [questionId, options] of Object.entries(getValues('questions') || {})) {
      const questionStructure: TrainingSurvey = {
        question_id: parseInt(removeSuffix(questionId)),
        option: [],
      };

      for (const [optionId, total] of Object.entries(options || {})) {
        if (parseInt(total) > 0) {
          questionStructure.option.push({
            id: parseInt(optionId.replace(ID_SUFFIX, '')),
            total: parseInt(total),
          });
        }
      }

      if (questionStructure.option?.length) {
        updatedQuestions.push(questionStructure);
      }
    }

    dispatch(
      deliveriesSlice.actions.setSaveDeliveryCourse({
        surveyQuestions: updatedQuestions,
        courseId: course.id,
        deliveryId: delivery.id,
      }),
    );
  };
  const onPressSave = () => {
    let questions: TrainingSurveyForm['questions'] = {};

    const savedCorses = myStore
      .getState()
      .deliveries.savedDeliveries[delivery.id].courses.filter(courses => courses.id === course.id);

    savedCorses[0].training_survey.forEach(survey => {
      const options = survey.option.reduce(
        (prev, cur) => ({ ...prev, [addSuffix(cur.id)]: cur.total }),
        {},
      );

      questions = { ...questions, [addSuffix(survey?.question_id?.toString())]: options };
    });

    const questionValues = [];
    for (const totalValue of Object.values(questions)) {
      let totalPoints = 0;
      for (const key in totalValue) {
        // eslint-disable-next-line no-prototype-builtins
        if (totalValue.hasOwnProperty(key)) {
          totalPoints += Number(totalValue[key]);
        }
      }
      questionValues.push(totalPoints);
    }

    const equalQuestionValue = questionValues.every(val => val === questionValues[0]);

    if (course.surveyQuestions.length !== Object.keys(questions).length) {
      showMessage({
        type: 'danger',
        message: 'Please answer all questions.',
        duration: 5000,
      });
    } else if (!equalQuestionValue) {
      showMessage({
        type: 'danger',
        message:
          'The total points of one survey questions does not match with other survey questions, so please correct it.',
        duration: 10000,
      });
    } else if (riderIds.length !== questionValues[0]) {
      showMessage({
        type: 'danger',
        message:
          'You have ' +
          questionValues[0] +
          ' survey results and ' +
          riderIds.length +
          ' riders, so please correct it.',
        duration: 10000,
      });
    } else {
      mutate({ survey: savedSurvey });
      submit();
    }
  };

  // returns
  const renderItem = useCallback(
    ({ item }: ListRenderItemInfo<SurveyQuestion>) => (
      <QuestionSectionContainer>
        <TitleContainer backgroundColor={course.courseColour}>
          <CustomText size={14} font="bodyBold">
            {item.questionText}
          </CustomText>
        </TitleContainer>
        <SpacerColumn size={1} />
        {item.responseOptions.map(respone => {
          return (
            <PointCounter
              name={`questions.${addSuffix(item.surveyQuestionId)}.${addSuffix(respone.optionId)}`}
              key={respone.optionId}
              text={respone.optionLabel}
              control={control}
              borderColor={course.courseColour}
              defaultValue={'0'}
              onChange={onChangeOptionValue}
              // rules={{
              //   validate: () => {
              //     const uniqueTotals: number[] = [];
              //     const totalIds: string[] = [];
              //     let currentValue = 0;

              //     for (const [id, value] of Object.entries(getValues('questions'))) {
              //       const totalPoints =
              //         Object.values(value || [])?.reduce(
              //           (prev: number, cur: string) => prev + parseInt(cur),
              //           0,
              //         ) || 0;

              //       if (id === addSuffix(item.surveyQuestionId)) {
              //         currentValue = totalPoints;
              //       } else {
              //         uniqueTotals.push(totalPoints);
              //         totalIds.push(id);
              //       }
              //     }

              //     if (currentValue === 0) {
              //       return 'Please answer this question';
              //     }

              //     // let result: string | boolean = true;
              //     // uniqueTotals.filter((value, index, array) => {
              //     //   console.log(value, index, array, array.indexOf(value) === index);

              //     //   if (
              //     //     array.indexOf(value) === index &&
              //     //     totalIds[index] === addSuffix(item.surveyQuestionId)
              //     //   ) {
              //     //     result = ;
              //     //     return false;
              //     //   }

              //     //   return true;
              //     // });

              //     if (!uniqueTotals.includes(currentValue)) {
              //       return `The total of this survey question total points doesn't match with other question's total points, please correct it.`;
              //     }
              //   },
              // }}
            />
          );
        })}
      </QuestionSectionContainer>
    ),
    [],
  );

  const onScrollEnd = () => {
    const position = buttonRef.current?.getBoundingClientRect();
    if (position && position.y + 90 > window.innerHeight) {
      setShowDownArrow(true);
    } else {
      setShowDownArrow(false);
    }
  };

  return (
    <Container>
      <FlatList
        data={course.surveyQuestions}
        renderItem={renderItem}
        ItemSeparatorComponent={() => <SpacerColumn size={4} />}
        contentContainerStyle={{ padding: layout.padding_x2, flex: 1 }}
        onScroll={onScrollEnd}
        ListFooterComponent={() => (
          <>
            <SpacerColumn size={1} />
            <div
              ref={buttonRef}
              style={{ width: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}>
              <SmallButton
                title="Save Survey"
                onPress={() => {
                  handleSubmit(onPressSave)();
                }}
                isLoading={isLoading}
              />
            </div>
            <SpacerColumn size={2} />
          </>
        )}
      />
      {showDownArrow && (
        <CustomText textAlign="right" font="bodyBold">
          Swipe down
          <CustomIcon
            name="chevron-down"
            color={'text'}
            size={20}
            style={{
              marginLeft: '5px',
              borderRadius: '50%',
              marginRight: '15px',
            }}
          />
        </CustomText>
      )}
    </Container>
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));

const QuestionSectionContainer = styled.View(() => ({}));

const TitleContainer = styled.View<{ backgroundColor: string }>(
  ({ theme: { layout }, backgroundColor }) => ({
    backgroundColor,
    padding: layout.padding_x2,
    borderRadius: 10,
  }),
);
