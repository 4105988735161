// libraries
import React, { useMemo, useReducer, useState } from 'react';
import styled from 'styled-components/native';
import { FlatList, StyleSheet, View, Text } from 'react-native';
import Checkbox from 'expo-checkbox';

// components
import { CustomPressable, CustomText, DivRow, SpacerColumn, SpacerRow } from '@components/atoms';
import { CustomModal } from './CustomModal';

// misc
import { theme } from '@styles/themes';
import { genericStyles } from '@styles/genericStyles';
import { CHECKLIST_ICONS } from '@utils/deliveries';

// types
export type ChecklistKeysProps = {
  buttonName?: string;
};

export const ChecklistKeys: React.FC<ChecklistKeysProps> = ({ buttonName }) => {
  // variables
  const [visibleDescription, setVisibleDescription] = useState('');
  const [isChecklistKeysModalVisible, toggleChecklistKeysModal] = useReducer(value => !value, localStorage.dontShowChecklistKeyPopup === undefined ? true : false);
  const [dontShowPopup, setDontShowPopup] = useState(
    localStorage.dontShowChecklistKeyPopup === undefined ? false : true,
  );

  // functions
  const toggleDescription = () => setVisibleDescription('');

  // renders
  const SelectedIcon = useMemo(() => {
    if (visibleDescription) return CHECKLIST_ICONS.find(i => i.description === visibleDescription).Icon;
    return () => null;
  }, [visibleDescription]);

  const changeDontShowPopupValue = newValue => {
    if (newValue) {
      localStorage.setItem('dontShowChecklistKeyPopup', 'true');
    } else {
      localStorage.removeItem('dontShowChecklistKeyPopup');
    }
    setDontShowPopup(newValue);
  };

  return (
    <DivRow>
      <CustomPressable onPress={toggleChecklistKeysModal}>
        <KeyText style={{ color: 'black', fontWeight: 'bold' }}>{buttonName ? buttonName : 'Key'}</KeyText>
      </CustomPressable>

      <CustomModal
        isVisible={!!visibleDescription}
        onClose={toggleDescription}
        containerStyle={styles.modalContainer}>
        <View style={genericStyles.rowWithCenter}>
          <SelectedIcon width={20} />
          <SpacerRow size={0.5} />
          <CustomText>{visibleDescription}</CustomText>
        </View>
      </CustomModal>

      <CustomModal
        isVisible={!!visibleDescription}
        onClose={toggleDescription}
        containerStyle={styles.modalContainer}>
        <View style={genericStyles.rowWithCenter}>
          <SelectedIcon width={20} />
          <SpacerRow size={0.5} />
          <CustomText>{visibleDescription}</CustomText>
        </View>
      </CustomModal>

      <CustomModal
        isVisible={isChecklistKeysModalVisible}
        onClose={toggleChecklistKeysModal}
        // renderHeader={() => <div>Training Checklist Key:</div>}
        // headerContainerStyle={{ left: '5' }}
        containerStyle={styles.modalContainer}>
        <FlatList
          data={CHECKLIST_ICONS}
          renderItem={({ item: icon }) => (
            <View key={icon.description} style={genericStyles.rowWithCenter}>
              {icon.Icon({ width: 20 })}
              <SpacerRow size={0.75} />
              <CustomText size={18}>{icon.description}</CustomText>
            </View>
          )}
          keyExtractor={item => item.description}
          ItemSeparatorComponent={() => <SpacerColumn size={0.5} />}
        />
        <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
          <Checkbox disabled={false} value={dontShowPopup} onValueChange={changeDontShowPopupValue} />
          <Text style={{ marginLeft: 10 }}>Don't show this popup again.</Text>
        </View>
      </CustomModal>
    </DivRow>
  );
};

const KeyText = styled(CustomText)(({ theme: { colors, layout } }) => ({
  backgroundColor: colors.primary,
  borderRadius: 4,
  padding: layout.padding_x0_5,
  marginRight: layout.padding_x1,
}));

const styles = StyleSheet.create({
  description: {
    color: theme.colors.white,
    fontSize: 12,
  },
  modalContainer: {
    padding: 20,
    paddingTop: 0,
    height: 'auto',
  },
});
