// libraries
import React from 'react';
import styled from 'styled-components/native';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

// components
import {
  BigButton,
  CustomIcon,
  CustomPressable,
  CustomText,
  Input,
  SpacerColumn,
  Heading,
} from '@components/atoms';

// misc
import { AuthoriseFormType } from './types';
import { LoginRequest, useLogin } from '@api/useLogin';
import { useFormError } from '@hooks/useFormError';
import { useVerifyCode } from '@api/useVerifyCode';
import { AuthStackParamList } from '@utils/navigation';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { AuthPinMode } from './AuthPinScreen';

export const AuthoriseScreen = () => {
  // variables
  const { control, handleSubmit, setError, reset } = useForm<AuthoriseFormType>({
    mode: 'all',
  });
  const { navigate } = useNavigation<NativeStackNavigationProp<AuthStackParamList>>();
  const { params } = useRoute<RouteProp<AuthStackParamList, 'authorise'>>();

  const { t } = useTranslation();
  const { isLoading, error, mutate } = useVerifyCode({
    onSuccess: () => {
      showMessage({
        message: 'You have been succesfully loggedin!',
        type: 'success',
        duration: 3000,
      });
      navigate('authPin', { type: AuthPinMode.SET });
      reset();
    },
  });

  const { mutate: resendCode, isLoading: isResendCodeLoading } = useLogin({
    onSuccess: () => {
      showMessage({
        message: 'Code resent successfully!',
        type: 'success',
        duration: 3000,
      });
    },
  });

  useFormError<AuthoriseFormType, LoginRequest>(error, setError, reset);

  // functions
  const onPressSubmit = ({ code }: AuthoriseFormType) => {
    mutate({ code });
  };

  // returns
  return (
    <Container>
      <Heading title={t('screens.authorise.heading')} />

      <Content>
        <CustomText size={14} font="bodyMedium" textAlign="center">
          Please check your phone for a text message with a code and insert it below to log into the
          App
        </CustomText>
        <SpacerColumn size={4} />

        <Input<AuthoriseFormType>
          control={control}
          name="code"
          title={t('common.code')}
          placeHolder="******"
          rules={{ required: true }}
        />

        <SpacerColumn size={2.5} />
        <CustomPressable onPress={() => resendCode(params)} disabled={isResendCodeLoading}>
          <CustomText color="primary">{t('screens.authorise.notReceived')}</CustomText>
        </CustomPressable>
        <SpacerColumn size={2.5} />

        <BigButton
          text={t('common.login')}
          onPress={handleSubmit(onPressSubmit)}
          isLoading={isLoading}
        />
        <SpacerColumn size={4} />
      </Content>
      <Footer>
        <CustomIcon type="feather" name="help-circle" color="boulder" size={34} />
      </Footer>
    </Container>
  );
};

const Container = styled.View(({ theme: { colors } }) => ({
  backgroundColor: colors.primaryBackground,
  flex: 1,
}));

const Content = styled.View(({ theme: { layout } }) => ({
  padding: layout.padding_x4,
  flex: 1,
}));

const Footer = styled.View(({ theme: { colors } }) => ({
  height: 70,
  width: '100%',
  backgroundColor: colors.secondary,
  justifyContent: 'center',
  alignItems: 'center',
}));
